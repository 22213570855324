import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    towns: [],
    editTownData: {},
    createError: null,
    editError: null,
    getError: null,
    loading: false,
    townCreated: false,
    townEdited: false,
};


const getTownsStart = ( state, action ) => {
    return updateObject( state, { loading: true,  townCreated: false, getError: null } );
};

const getTownsSuccess = ( state, action ) => {
    return updateObject( state, { towns: action.towns, loading: false, getError: null });
};

const getTownsFail = ( state, action ) => {
    return updateObject( state, { getError: action.error, loading: false } );
};

const createTownStarts = ( state, action ) => {
    return updateObject( state, { loading: true, townCreated: false, createError: null } );
};

const createTownSuccess = ( state, action ) => {
    return updateObject( state, { loading: false, townCreated: true, createError: null } );
};

const createTownFails = ( state, action ) => {
    return updateObject( state, { createError: action.error, loading: false, townCreated: false } );
};

const getTownStarts = ( state, action ) => {
    return updateObject( state, { loading: true, townEdited: false, editError: null  } );
};

const getTownSuccess = ( state, action ) => {
    return updateObject( state, { loading: false, townEdited: false, editError: null, editTownData: action.townData } );
};

const getTownFails = ( state, action ) => {
    return updateObject( state, { getError: action.error, loading: false } );
};

const editTownSuccess = ( state, action ) => {
    return updateObject( state, { loading: false, townEdited: true, editError: null, } );
};

const editTownFails = ( state, action ) => {
    return updateObject( state, { editError: action.error, loading: false, townEdited: false } );
};



const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SETUP_CONSTANTS.GET_TOWNS_START: return getTownsStart(state, action);
        case actionTypes.SETUP_CONSTANTS.GET_TOWNS_SUCCESS: return getTownsSuccess(state, action);
        case actionTypes.SETUP_CONSTANTS.GET_TOWNS_FAIL: return getTownsFail(state, action);
        case actionTypes.SETUP_CONSTANTS.CREATE_TOWN_STARTS: return createTownStarts(state, action);
        case actionTypes.SETUP_CONSTANTS.CREATE_TOWN_SUCCESS: return createTownSuccess(state, action);
        case actionTypes.SETUP_CONSTANTS.CREATE_TOWN_FAILS: return createTownFails(state, action);
        case actionTypes.SETUP_CONSTANTS.GET_TOWN_STARTS: return getTownStarts(state, action);
        case actionTypes.SETUP_CONSTANTS.GET_TOWN_SUCCESS: return getTownSuccess(state, action);
        case actionTypes.SETUP_CONSTANTS.GET_TOWN_FAILS: return getTownFails(state, action);
        case actionTypes.SETUP_CONSTANTS.EDIT_TOWN_SUCCESS: return editTownSuccess(state, action);
        case actionTypes.SETUP_CONSTANTS.EDIT_TOWN_FAILS: return editTownFails(state, action);

        default: return state;
    }
};

export default reducer;