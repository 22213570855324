/** @format */

import { put, delay } from "redux-saga/effects";
import api from "../../middlewares/axiosConfig";
import { toastr } from "react-redux-toastr";
import {
  payCategorySucess,
  payCategoryFail,
} from "../actions/paymentCategoryActions";

export function* PaymentCategory() {
  debugger;
  try {
    const response = yield api.get("Payment/GetAllPaymentCategories");
    console.log(response);
    let payment = response.data;
    yield put(payCategorySucess(payment));
  } catch (error) {
    toastr.error("Error", "An error occured", {
      onCloseButtonClick: true,
    });
    yield put(payCategoryFail(error));
  }
}
