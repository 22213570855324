import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    farms: [],
    editFarmData: {},
    farmCreated: false,
    farmEdited: false,
    getError: null,
    editError: null,
    createError: null,
    loading: false
};


const getFarmsStart = ( state, action ) => {
    return updateObject( state, { loading: true, farmCreated: false, getError: null } );
};

const getFarmsSuccess = ( state, action ) => {
    return updateObject( state, { farms: action.farms, loading: false, getError: null });
};

const getFarmsFail = ( state, action ) => {
    return updateObject( state, { getError: action.error, loading: false } );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FARMS_CONSTANTS.GET_FARMS_START: return getFarmsStart(state, action);
        case actionTypes.FARMS_CONSTANTS.GET_FARMS_SUCCESS: return getFarmsSuccess(state, action);
        case actionTypes.FARMS_CONSTANTS.GET_FARMS_FAIL: return getFarmsFail(state, action);
        default: return state;
    }
};

export default reducer;