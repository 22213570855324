import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

import { Page } from "../../../components/Admin";
import {
  Header,
  Overview,
  FinancialStats,
  EarningsSegmentation,
  TopReferrals,
  MostProfitableProducts,
  CustomerActivity,
  LatestOrders,
  FarmerDetails,
} from "./components";
import FarmAcivites from "../MyFarmland/MyFarmActivities";
import FarmActivitiesChart from "./components/FarmActivitiesChart/FarmActivitiesChart";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   padding: theme.spacing(3),
  // },
  // container: {
  //   marginBottom: "0px",
  //   "& > *": {
  //     height: "100%",
  //   },
  // },
  root: {
    padding: theme.spacing(3),
    backgroundColor: '#f1f1f1e6',
  },
  container: {
    '& > *': {
      height: '100%'
    },
    display: "flex",
    marginTop: "2rem",
    flexDirection: "row",
  }
}));

const DashboardAnalytics = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Farmer Dashboard">
      <Header />
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={12}>
          <Overview />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <FarmerDetails />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <FarmActivitiesChart />
        </Grid>
      </Grid>

    </Page>
  );
};

export default DashboardAnalytics;
