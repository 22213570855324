/** @format */

import { ADMIN_CONSTANTS } from "../actions/actionTypes";

const initialState = {
  user: {
    token: "",
    adminId: 0,
    accountTypeId: 0,
    email: "",
    fullname: "",
    cooperativeSocietyName: "",
    roleId: 0,
  },
  detailedUsers: [],
  loggedout: false,
};

const adminReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case ADMIN_CONSTANTS.SET_AUTH:
      return { ...state, user: { ...action.payload }, loggedout: false };

    case ADMIN_CONSTANTS.SET_DETAILED_USERS:
      return { ...state, detailedUsers: [...action.payload] };

    case ADMIN_CONSTANTS.DELETE_AUTH:
      return {
        ...state,
        user: {
          token: "",
          adminId: 0,
          accountTypeId: 0,
          email: "",
          fullname: "",
          cooperativeSocietyName: "",
          roleId: 0,
        },
        loggedout: true,
      };

    default:
      return state;
  }
};

export default adminReducer;
