import * as actionTypes from './actionTypes';


export const createAccount = (farmerData) => {
    return{
        type: actionTypes.CREATE_ACCOUNT_CONSTANTS.CREATE_ACCOUNT,
        farmerData: farmerData
    }
};

export const createAccountStarts = () => {
    return {
        type: actionTypes.CREATE_ACCOUNT_CONSTANTS.CREATE_ACCOUNT_STARTS
    };
};

export const createAccountSuccess = () => {
    return {
        type: actionTypes.CREATE_ACCOUNT_CONSTANTS.CREATE_ACCOUNT_SUCCESS,
    };
};

// export const createAccountSuccess = (famerData) => {
//     return {
//         type: actionTypes.CREATE_ACCOUNT_CONSTANTS.CREATE_ACCOUNT_SUCCESS,
//         farmerData: farmerData
//     };
// };

export const createAccountFails = (error) => {
    return {
        type: actionTypes.CREATE_ACCOUNT_CONSTANTS.CREATE_ACCOUNT_FAILS,
        error: error
    };
};

export const createAccountFails2 = (error) => {
    return {
        type: actionTypes.CREATE_ACCOUNT_CONSTANTS.CREATE_ACCOUNT_FAILS2,
        error: error
    };
};