/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

import React, { useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Input,
  colors,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  Typography,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/LockOutlined";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";

import axios from "../../../../utils/axios";
import useRouter from "../../../../utils/useRouter";
import {
  PricingModal,
  NotificationsPopover,
} from "../../../../components/Admin";
import { logout } from "../../../../redux/actions";
import { setAdminLogout } from "../../../../redux/actions/adminActions";
import { superAdminLogout } from "../../../../redux/actions/superAdminAction";
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
  },
  fontSizen: {
    fontSize: "75%",
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: "#fff",
  },
  searchInput: {
    flexGrow: 1,
    color: "#fff",
    "& input::placeholder": {
      opacity: 1,
      color: "#d3d3d3",
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900],
    },
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
    color: "#fff",
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  title: {
    fontWeight: 500,
    fontSize: "1.5rem",
    color: "#fff",
    marginLeft: ".5rem",
  },
  appBar: {
    background: colors.green[800],
  },
  aLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
  },
}));

const TopBar = (props) => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  const { history } = useRouter();
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const notificationsRef = useRef(null);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);

  useEffect(() => {
    let mounted = true;

    // const fetchNotifications = () => {
    //   axios.get('/api/account/notifications').then(response => {
    //     if (mounted) {
    //       setNotifications(response.data.notifications);
    //     }
    //   });
    // };

    //fetchNotifications();

    return () => {
      mounted = false;
    };
  }, []);

  const handleLogout = () => {
    // debugger;
    dispatch(superAdminLogout());
    window.location.href = "/";
    // history.push('/');
    // history.push('/');
  };

  const handlePricingOpen = () => {
    setPricingModalOpen(true);
  };

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true);
      }
    } else {
      setOpenSearchPopover(false);
    }
  };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };

  const popularSearches = [
    "Devias React Dashboard",
    "Devias",
    "Admin Pannel",
    "Project",
    "Pages",
  ];

  const toLandingPage = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    let homeLogo = document.querySelector("#homeLogo");
    let homeLogo2 = document.querySelector("#homeLogo2");
    // console.log(homeLogo);
    homeLogo && homeLogo.addEventListener("click", toLandingPage);
    homeLogo2 && homeLogo2.addEventListener("click", toLandingPage);

    return () => {
      homeLogo && homeLogo.removeEventListener("click", toLandingPage);
      homeLogo2 && homeLogo2.removeEventListener("click", toLandingPage);
    };
  }, []);

  return (
    <AppBar {...rest} className={clsx(classes.root, className, classes.appBar)}>
      <Toolbar>
        {/* <RouterLink to="/" className={classes.aLink} style={{textDecoration: 'none'}}> */}
        <img
          alt="Logo"
          src="/images/logos/eagricIcon.png"
          width="30"
          id="homeLogo"
          style={{ cursor: "pointer" }}
        />
        <Typography
          variant="h6"
          className={classes.title}
          id="homeLogo2"
          style={{ cursor: "pointer" }}
        >
          {" "}
          eAgric-360{" "}
        </Typography>
        {/* </RouterLink> */}
        <div className={classes.flexGrow} />
        {/* <Hidden smDown>
          <div className={classes.search} ref={searchRef}>
            <SearchIcon className={classes.searchIcon} />
            <Input className={classes.searchInput} disableUnderline onChange={handleSearchChange} placeholder="Search people &amp; places" value={searchValue} />
          </div>
          <Popper anchorEl={searchRef.current} className={classes.searchPopper} open={openSearchPopover} transition>
            <ClickAwayListener onClickAway={handleSearchPopverClose}>
              <Paper className={classes.searchPopperContent} elevation={3}> 
                <List>
                  {popularSearches.map(search => (
                    <ListItem button key={search} onClick={handleSearchPopverClose}>
                      <ListItemIcon><SearchIcon /></ListItemIcon>
                      <ListItemText primary={search} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </Hidden> */}
        {/* <Hidden mdDown> */}
        {/* <IconButton className={classes.notificationsButton} onClick={handleNotificationsOpen} ref={notificationsRef}>
            <Badge badgeContent={notifications.length} classes={{ badge: classes.notificationsBadge }} variant="dot">
              <NotificationsIcon style={{color: '#fff'}}/>
            </Badge>
          </IconButton> */}
        <Button
          className={classes.logoutButton}
          color="inherit"
          onClick={handleLogout}
          variant="outlined"
        >
          <InputIcon className={classes.logoutIcon} />
          <span className={classes.fontSizen}>Sign out</span>
        </Button>
        {/* </Hidden> */}
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
