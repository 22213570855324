/** @format */

import { put } from "redux-saga/effects";
import api from "../../middlewares/axiosConfig";
import { toastr } from "react-redux-toastr";

import * as actions from "../actions";

export function* getFarmersSaga() {
  try {
    yield put(actions.getFarmersStart());
    const response = yield api.get("farmer");
    let farmers = response.data;
    yield put(actions.getFarmersSuccess(farmers));
  } catch (error) {
    toastr.error("Error", "An error occured while trying to get farmers", {
      onCloseButtonClick: true,
    });
    yield put(actions.getFarmersFail(error));
  }
}

export function* createFarmerSaga(data) {
  try {
    yield put(actions.createFarmerStarts());
    let response = yield api.post(`Farmer/register`, data.farmerData);
    toastr.success(`Success`, response.data.message, {
      closeButton: true,
    });
    yield put(actions.createFarmerSuccess());
    yield put(actions.getFarmers());
  } catch (error) {
    if (error.status === 400) {
      toastr.error("Error", error.data.message, {
        onCloseButtonClick: true,
      });
    } else {
      toastr.error("Error", "An error occured while registering farmer", {
        onCloseButtonClick: true,
      });
    }

    yield put(actions.createFarmerFails(error));
  }
}

export function* editFarmerSaga(data) {
  try {
    yield put(actions.getFarmerStarts());
    let response = yield api.get(`Farmer/${data.farmerId}`);
    yield put(actions.getFarmerSuccess(response.data));
  } catch (error) {
    toastr.error("Error", "An error occured while getting data", {
      onCloseButtonClick: true,
    });
    yield put(actions.getFarmerFails(error));
  }
}

export function* editFarmerStartsSaga(data) {
  try {
    let response = yield api.put(
      `Farmer/${data.farmerData.individualFarmerId}`,
      data.farmerData
    );
    yield put(actions.editFarmerSuccess());
    toastr.success(`Success`, response.data.message, {
      closeButton: true,
    });
    yield put(actions.getFarmers());
  } catch (error) {
    toastr.error("Error", "An error occured while saving farmer", {
      onCloseButtonClick: true,
    });
    yield put(actions.editFarmerFails(error));
  }
}

export function* deleteFarmerSaga(farmerData) {
  try {
    let response = yield api.delete(`Farmer/${farmerData.farmerId}`);
    toastr.success(`Success`, response.data.message, {
      closeButton: true,
    });
    yield put(actions.getFarmers());
  } catch (error) {
    toastr.error("Error", "An error occured deleting farmer data", {
      onCloseButtonClick: true,
    });
  }
}

export function* getFarmersPassportCapturedSaga() {
  try {
    yield put(actions.getFarmersPassportCapturedStart());
    const response = yield api.get("Farmer/GetAllFarmersByStatusId/2");
    let farmers = response.data;
    yield put(actions.getFarmersPassportCapturedSuccess(farmers));
  } catch (error) {
    toastr.error("Error", "An error occured while trying to get farmers", {
      onCloseButtonClick: true,
    });
    yield put(actions.getFarmersPassportCapturedFail(error));
  }
}

export function* getFarmersBiometricCapturedSaga() {
  try {
    yield put(actions.getFarmersBiometricsCapturedStart());
    const response = yield api.get("Farmer/GetAllFarmersByStatusId/3");
    let farmers = response.data;
    yield put(actions.getFarmersBiometricsCapturedSuccess(farmers));
  } catch (error) {
    toastr.error("Error", "An error occured while trying to get farmers", {
      onCloseButtonClick: true,
    });
    yield put(actions.getFarmersBiometricsCapturedFail(error));
  }
}
