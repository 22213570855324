/** @format */

import { put, delay } from "redux-saga/effects";
import api from "../../middlewares/axiosConfig";
import { toastr } from "react-redux-toastr";
import * as actions from "../actions";
import {
  getFarmersByCooperativeIdSuccess,
  getFarmersByCooperativeFail,
} from "../actions/paymentAction";

export function* getPaymentStart(action) {
  const { id } = action.payload;
  try {
    const response = yield api.get(
      `Payment/GetPaymentHistoryFailedAndPending?payeeId=${id}`
    );
    console.log(response);
    let paymentItems = response.data;
    yield put(actions.paymentActionsSuccess(paymentItems));
  } catch (error) {
    toastr.error("Error", "An error occured Uba", {
      onCloseButtonClick: true,
    });
    yield put(actions.paymentActionsFail(error));
  }
}

export function* getFarmerPaymentStart(action) {
  const { coopId } = action.payload;
  try {
    const response = yield api.get(
      `Payment/GetAllFarmerPaymentHistory/${coopId}`
    );
    console.log(response.data);
    let farmerPayment = response.data;
    yield put(getFarmersByCooperativeIdSuccess(farmerPayment));
  } catch (error) {
    toastr.error("Error", "An error occured", {
      onCloseButtonClick: true,
    });
    yield put(getFarmersByCooperativeFail(error));
  }
}
