/** @format */

import { PAYMENT_CONSTANTS } from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  payment: [],
  loading: false,
  error: null,
  response: [],
  paymentCat: [],
};

const paymentReducerStart = (state, action) => {
  return updateObject(state, { loading: true, error: null });
};

const paymentReducerSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    payment: action.payment,
    error: null,
  });
};

const paymentReducerFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getFarmerpaymentStart = (state, action) => {
  return updateObject(state, {
    loaing: true,
    error: false,
  });
};
const getFarmerpaymentSuccess = (state, action) => {
  return updateObject(state, {
    loaing: false,
    error: false,
    response: action.payload,
  });
};
const getFarmerpaymentFail = (state, action) => {
  return updateObject(state, { loaing: false, error: action.error });
};

const paymentCat = (state, action) => {
  return updateObject(state, { loading: true, error: false });
};

const paymentCatSucc = (state, action) => {
  return updateObject(state, {
    loading: false,
    paymentCat: action.payload,
    error: false,
  });
};

const paymentCatFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    // case PAYMENT_CONSTANTS.GET_PAYMENTS_START:
    //   return paymentReducerStart(state, action);
    // case PAYMENT_CONSTANTS.GET_PAYMENTS_SUCCESS:
    //   return paymentReducerSuccess(state, action);
    // case PAYMENT_CONSTANTS.GET_PAYMENTS_FAIL:
    //   return paymentReducerFail(state, action);
    case PAYMENT_CONSTANTS.GET_ALL_FARMERS_FAIL:
      return getFarmerpaymentStart(state, action);
    case PAYMENT_CONSTANTS.GET_ALL_FARMERS_SUCCESS:
      return getFarmerpaymentSuccess(state, action);
    case PAYMENT_CONSTANTS.GET_ALL_FARMERS_FAIL:
      return getFarmerpaymentFail(state, action);
    case PAYMENT_CONSTANTS.GET_ALL_PAYMENTCAT:
      return paymentCat(state, action);
    case PAYMENT_CONSTANTS.GET_ALL_PAYMENTCAT_SUCCESS:
      return paymentCatSucc(state, action);
    case PAYMENT_CONSTANTS.GET_ALL_PAYMENTCAT_FAIL:
      return paymentCatFail(state, action);
    default: // need this for default case
      return state;
  }
};

export default reducer;
