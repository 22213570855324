import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility';



const initialState ={
    cooperative:[],
    cooperativeCreated: null,
    getError: null,
    createError: null,
    loading: null
}

const getAllCooperativeSocietyStart = (state, action) =>{
    return updateObject( state, {loading: true, cooperativeCreated:false, getError: null });
};

const getAllCooperativeSocietyFails =(state, action) =>{
    return updateObject( state, {getError: action.error, loading:false})
}

const getAllCooperativeSocietySuccess =(state, action) =>{
    return updateObject( state, { cooperative: action.cooperative, loading:false, getError: null})
}

const reducer = (state= initialState, action) =>{
    switch (action.type){
        case actionTypes.PARTNERS_CONSTANTS.GET_ALL_COOPERATIVE_SOCIETY_START: return getAllCooperativeSocietyStart(state, action)
        case actionTypes.PARTNERS_CONSTANTS.GET_ALL_COOPERATIVE_SOCIETY_FAILS: return getAllCooperativeSocietyFails (state, action) 
        case actionTypes.PARTNERS_CONSTANTS.GET_ALL_COOPERATIVE_SOCIETY_SUCCESS: return getAllCooperativeSocietySuccess(state, action)
        default: return state;
    }
        
}

export default reducer;