import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    partitionedFarms: [],
    editPartitionedFarmData: {},
    createError: null,
    editError: null,
    getError: null,
    loading: false,
    farmPartitionCreated: false,
    farmPartitionEdited: false,
    unpartitionedFarms: [],
    getUnpartitionedError: null,
};


const getPartitionedFarmsStart = ( state, action ) => {
    return updateObject( state, { loading: true,  farmPartitionedCreated: false, getError: null });
}

const getPartitionedFarmsSuccess = ( state, action ) => {
    return updateObject( state, { partitionedFarms: action.farmsData, loading: false, getError: null });
};

const getPartitionedFarmsFail = ( state, action ) => {
    return updateObject( state, { getError: action.error, loading: false } );
};

const getUnPartitionedFarmsStart = ( state, action ) => {
    return updateObject( state, { loading: true,  farmPartitionedCreated: false, getUnpartitionedError: null });
}

const getUnPartitionedFarmsSuccess = ( state, action ) => {
    return updateObject( state, { unpartitionedFarms: action.farmsData, loading: false,  getUnpartitionedError: null });
};

const getUnPartitionedFarmsFail = ( state, action ) => {
    return updateObject( state, {  getUnpartitionedError: action.error, loading: false } );
};

const createFarmPartitionStarts = ( state, action ) => {
    return updateObject( state, { loading: true, farmPartitionCreated: false, createError: null } );
};

const createFarmPartitionSuccess = ( state, action ) => {
    return updateObject( state, { loading: false, farmPartitionCreated: true, createError: null } );
};

const createFarmPartitionFails = ( state, action ) => {
    return updateObject( state, { createError: action.error, loading: false, farmPartitionCreated: false } );
};

const getPartitionedFarmStarts = ( state, action ) => {
    return updateObject( state, { loading: true, farmPartitionEdited: false, editError: null  } );
};

const getPartitionedFarmSuccess = ( state, action ) => {
    return updateObject( state, { loading: false, farmPartitionEdited: false, editError: null, editPartitionedFarmData: action.farmData } );
};

const getPartitionedFarmFails = ( state, action ) => {
    return updateObject( state, { getError: action.error, loading: false } );
};

const editPartitionedFarmSuccess = ( state, action ) => {
    return updateObject( state, { loading: false, farmPartitionEdited: true, editError: null, } );
};

const editPartitionedFarmFails = ( state, action ) => {
    return updateObject( state, { editError: action.error, loading: false, farmPartitionEdited: false } );
};



const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.MANAGEFARMLAND_CONSTANTS.GET_PARTITIONED_FARMS_START: return getPartitionedFarmsStart(state, action);
        case actionTypes.MANAGEFARMLAND_CONSTANTS.GET_PARTITIONED_FARMS_SUCCESS: return getPartitionedFarmsSuccess(state, action);
        case actionTypes.MANAGEFARMLAND_CONSTANTS.GET_PARTITIONED_FARMS_FAIL: return getPartitionedFarmsFail(state, action);
        case actionTypes.MANAGEFARMLAND_CONSTANTS.GET_UNPARTITIONED_FARMS_START: return getUnPartitionedFarmsStart(state, action);
        case actionTypes.MANAGEFARMLAND_CONSTANTS.GET_UNPARTITIONED_FARMS_SUCCESS: return getUnPartitionedFarmsSuccess(state, action);
        case actionTypes.MANAGEFARMLAND_CONSTANTS.GET_UNPARTITIONED_FARMS_FAIL: return getUnPartitionedFarmsFail(state, action);
        case actionTypes.MANAGEFARMLAND_CONSTANTS.CREATE_FARM_PARTITION_STARTS: return createFarmPartitionStarts(state, action);
        case actionTypes.MANAGEFARMLAND_CONSTANTS.CREATE_FARM_PARTITION_SUCCESS: return createFarmPartitionSuccess(state, action);
        case actionTypes.MANAGEFARMLAND_CONSTANTS.CREATE_FARM_PARTITION_FAILS: return createFarmPartitionFails(state, action);
        case actionTypes.MANAGEFARMLAND_CONSTANTS.GET_PARTITIONED_FARM_STARTS: return getPartitionedFarmStarts(state, action);
        case actionTypes.MANAGEFARMLAND_CONSTANTS.GET_PARTITIONED_FARM_SUCCESS: return getPartitionedFarmSuccess(state, action);
        case actionTypes.MANAGEFARMLAND_CONSTANTS.GET_PARTITIONED_FARM_FAILS: return getPartitionedFarmFails(state, action);
        case actionTypes.MANAGEFARMLAND_CONSTANTS.EDIT_PARTITIONED_FARM_SUCCESS: return editPartitionedFarmSuccess(state, action);
        case actionTypes.MANAGEFARMLAND_CONSTANTS.EDIT_PARTITIONED_FARM_FAILS: return editPartitionedFarmFails(state, action);

        default: return state;
    }
};

export default reducer;