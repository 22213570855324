import { put } from 'redux-saga/effects';
import api from '../../middlewares/axiosConfig';
import {toastr} from 'react-redux-toastr';


import * as actions from '../actions';

export function* getRolesSaga() {
    try{
        yield put(actions.getRolesStarts());
        const response = yield api.get('Role/GetAllRoles');
        let roles = response.data;
        let rolesDataTable = []
        roles.map(val => {
          let row = ['', `${val.roleName}`, `${val.roleId}`];
          rolesDataTable.push(row);
        });
        yield put(actions.getRolesSuccess(roles, rolesDataTable));
    }catch(error){
        toastr.error("Error", "An error occured while trying to get roles", {
            onCloseButtonClick: true
        });
        yield put(actions.getRolesFails(error));
    }
}

export function* createRoleSaga(data) {
    try{
        yield put(actions.createRoleStarts());
        let response = yield api.post(`Role/CreateRole`, data.role);
        toastr.success( `Success`, response.data.message, {
            closeButton: true,
        });
        yield put(actions.createRoleSuccess());
        yield put(actions.getRoles());
    }catch(error){
        toastr.error("Error", "An error occured while creating role", {
            onCloseButtonClick: true
        });
        yield put(actions.createRoleFails(error));
    }
}

export function* editRoleSaga(data) {
    try{
        yield put(actions.editRoleStarts());
        let response = yield api.get(`Role/GetRoleById/${data.roleId}`);
        yield put(actions.editRoleSuccess(response.data));
    }catch(error){
        toastr.error("Error", "An error occured while editing role", {
            onCloseButtonClick: true
        });
        yield put(actions.editRoleFails(error));
    }
}

export function* editRoleSavedSaga(data) {
    try{
        yield put(actions.editRoleStarts());
        let response = yield api.patch(`Role/UpdateRole/${data.role.roleId}`, data.role);
        toastr.success( `Success`, response.data.message, {
            closeButton: true,
        });
        yield put(actions.editRoleSavedSuccess());
        yield put(actions.getRoles());
    }catch(error){
        toastr.error("Error", "An error occured while editing role", {
            onCloseButtonClick: true
        });
        yield put(actions.editRoleFails(error));
    }
}

export function* deleteRoleSaga(roleData) {
    try{
        let response = yield api.delete(`Role/DeleteRole/${roleData.roleId}`);
        toastr.success( `Success`, response.data.message, {
            closeButton: true,
        });
        yield put(actions.getRoles());
    }catch(error){
        toastr.error("Error", "An error occured deleting role", {
            onCloseButtonClick: true
        });
        yield put(actions.editRoleFails(error));
    }
}