import * as actionTypes from './actionTypes';

export const getTowns = () => {
    return {
        type: actionTypes.SETUP_CONSTANTS.GET_TOWNS
    };
}

export const getTownsStart = () => {
    return {
        type: actionTypes.SETUP_CONSTANTS.GET_TOWNS_START
    };
}

export const getTownsSuccess = (townsData) => {
    return {
        type: actionTypes.SETUP_CONSTANTS.GET_TOWNS_SUCCESS,
        towns: townsData,
    };
}

export const getTownsFail = (error) => {
    return {
        type: actionTypes.SETUP_CONSTANTS.GET_TOWNS_FAIL,
        error: error
    };
}

export const createTown = (data) => {
    return {
        type: actionTypes.SETUP_CONSTANTS.CREATE_TOWN,
        town: data
    };
}

export const createTownStarts = () => {
    return {
        type: actionTypes.SETUP_CONSTANTS.CREATE_TOWN_STARTS
    };
}

export const createTownSuccess = () => {
    return {
        type: actionTypes.SETUP_CONSTANTS.CREATE_TOWN_SUCCESS
    };
}

export const createTownFails = (error) => {
    return {
        type: actionTypes.SETUP_CONSTANTS.CREATE_TOWN_FAILS,
        error: error
    };
}

export const editTown = (data) => {
    return {
        type: actionTypes.SETUP_CONSTANTS.EDIT_TOWN,
        townId: data
    };
}

export const getTownStarts = () => {
    return {
        type: actionTypes.SETUP_CONSTANTS.GET_TOWN_STARTS
    };
}

export const getTownSuccess = (data) => {
    return {
        type: actionTypes.SETUP_CONSTANTS.GET_TOWN_SUCCESS,
        townData: data
    };
}

export const getTownFails = (error) => {
    return {
        type: actionTypes.SETUP_CONSTANTS.GET_TOWN_FAILS,
        error: error
    };
}

export const editTownStarts = (data) => {
    return {
        type: actionTypes.SETUP_CONSTANTS.EDIT_TOWN_STARTS,
        townData: data
    };
  }

export const editTownSuccess = () => {
    return {
        type: actionTypes.SETUP_CONSTANTS.EDIT_TOWN_SUCCESS,
    };
}

export const editTownFails = (error) => {
    return {
        type: actionTypes.SETUP_CONSTANTS.EDIT_TOWN_FAILS,
        error: error
    };
}

export const deleteTown = (data) => {
    return{
        type: actionTypes.SETUP_CONSTANTS.DELETE_TOWN,
        townId: data
    }
}