/** @format */

import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import api from "../../../../../middlewares/axiosConfig";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/styles";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 10,
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    padding: "0 !important",
    marginTop: "7px",
    paddingBottom: "20px !important",
  },
  titleStyle: {
    fontWeight: 600,
    lineHeight: "30px",
    fontSize: "16px",
    color: "#0e0e0e",
  },
  avatarStyle: {
    border: "1px solid #c5ccc5",
    borderRadius: "5px",
    color: "#4a4c49",
  },
  margin: {
    margin: theme.spacing(0),
  },
}));

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const AllocatedStatus = () => {
  const classes = useStyles();
  const [selectedCoop, setSelectedCoop] = useState(1);
  const [alloctaUNAllo, setAlloctaUNAllo] = useState([]);
  const [data, setData] = useState([]);

  useEffect(async () => {
    let response = await api.get(`PortalAdmin/GetAllCooperativeSociety`);
    setAlloctaUNAllo(response.data);
  }, []);

  // useEffect(() => {
  //   if(alloctaUNAllo.length > 0 && !setSelectedCoop)
  //     setSelectedCoop(alloctaUNAllo[0].cooperativeSocietyId)
  // }, [alloctaUNAllo])

  const AllocatedUnallocatedPieChart = async () => {
    let res = await api.get(
      `Dashboard/SuperAdminGetAllocatedAndUnallocatedFarmers/${selectedCoop}`
    );
    setData([
      { name: "Allocated Farms", value: res.data.allocatedFarms },
      { name: "Unallocated Farms", value: res.data.unAllocatedFarms },
    ]);
  };

  useEffect(() => {
    selectedCoop && AllocatedUnallocatedPieChart();
  }, [selectedCoop]);

  const handleChange = (event) => {
    setSelectedCoop(event.target.value);
  };

  const selectCooperativ = (
    <FormControl className={classes.margin}>
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={selectedCoop}
        onChange={handleChange}
        input={<BootstrapInput />}
      >
        {alloctaUNAllo.map((item, index) => (
          <option key={index} value={item.cooperativeSocietyId}>
            {item.cooperativeSocietyName}
          </option>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <Card className={clsx(classes.root)}>
      <CardHeader
        title="Allocated and Unallocated Status"
        avatar={<HowToRegIcon />}
        classes={{
          title: classes.titleStyle,
          avatar: classes.avatarStyle,
        }}
        action={selectCooperativ}
      />

      <Divider />
      <CardContent className={classes.content}>
        <ResponsiveContainer width="100%" aspect={2}>
          <PieChart width={400} height={400}>
            <CartesianGrid strokeDasharray="3 3" />
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              <option>select cooperative society</option>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default AllocatedStatus;
