/** @format */

import React, { Fragment, useEffect, useState } from "react";
import api from "../../../../../middlewares/axiosConfig";
import { actions, toastr } from "react-redux-toastr";
import PropTypes from "prop-types";
import clsx from "clsx";
// import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid, colors } from "@material-ui/core";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import BorderAllIcon from "@material-ui/icons/BorderAll";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import LandscapeIcon from "@material-ui/icons/Landscape";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Label } from "../../../../../components/Admin";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  item: {
    padding: theme.spacing(3),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      "&:not(:last-of-type)": {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&:not(:last-of-type)": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  valueContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    marginLeft: theme.spacing(1),
  },
}));

const Overview = (props) => {
  const { className, ...rest } = props;
  const [individualFarmer, setIndividualFarmer] = useState(0);
  const FarmerData = useSelector((state) => state.loginReducer.farmerData);
  const [individualFarmerId, setIndividualFarmerId] = useState(0);
  const [totalFarmOperation, setTotalFarmOperation] = useState(0);
  // const [ farmerLand, setFarmerLand] = useState(0);

  const [individualFarmers, setIndividualFarmers] = useState(0);
  const classes = useStyles();

  //{Total farm cell size  is displayed on load}
  useEffect(async () => {
    try {
      const { data } = await api.get(
        `FarmerMgt/NumberOfAllocatedFarmLands/ ${FarmerData.individualFarmerId}`
      );
      setIndividualFarmerId(data);
    } catch (error) {
      toastr.error("Error", "Sorry Could Not Load Get All Farmland Allocation");
    }
  }, []);

  //{allocated farm cells is diplayed on load}
  useEffect(async () => {
    try {
      const { data } = await api.get(
        `FarmerMgt/TotalFarmCellSize/${FarmerData.individualFarmerId}`
      );
      setIndividualFarmer(data);
    } catch (error) {
      toastr.error("Error", "Sorry Could Not Load Get All Total Farmland");
    }
  }, []);

  //{total farmland is displayed upon page load}
  useEffect(async () => {
    try {
      const { data } = await api.get(
        `FarmerMgt/TotalFarmLand/ ${FarmerData.individualFarmerId}`
      );
      setIndividualFarmers(data);
    } catch (error) {
      toastr.error("Error", "Sorry Could Not Load Total Farm Land");
    }
  }, []);

  useEffect(async () => {
    try {
      const { data } = await api.get(
        `FarmerMgt/TotalNumberOfFarmingActivities/ ${FarmerData.individualFarmerId}`
      );
      setTotalFarmOperation(data);
    } catch (error) {
      toastr.error("Error", "Sorry Could Not Load Get All Farmland Allocation");
    }
  }, []);

  const data = {
    farmer: "1,051",
    verifiedFarmer: "2",
    farms: "3",
    allocatedFarms: "0",
    totalFarm: "9",
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justify="space-between">
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Link to={"farmer/my-farmland"}>
            <Typography component="h2" gutterBottom variant="overline">
              Allocated Farm Cell
            </Typography>

            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {individualFarmerId}
                <EqualizerIcon
                  style={{
                    color: green[500],
                    fontSize: 30,
                    marginLeft: 10,
                    marginBottom: 10,
                  }}
                />
              </Typography>
            </div>
          </Link>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Link to={"farmer/my-farmland"}>
            <Typography component="h2" gutterBottom variant="overline">
              {" "}
              Total Farm Cell Size
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3">{individualFarmer}</Typography>
              <Typography
                variant="h6"
                // gutterBottom
                component="h3"
                // style={{ marginLeft: "1rem" }}
              >
                (hec)
                <BorderAllIcon
                  style={{
                    color: green[500],
                    fontSize: 25,
                    // marginRight: 10,
                    marginBottom: 10,
                  }}
                />
              </Typography>
            </div>
          </Link>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Link to={"/farmer/my-farming-activities"}>
            <Typography component="h2" gutterBottom variant="overline">
              Total Farmlands
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {individualFarmers}
                <LandscapeIcon
                  style={{ color: green[500], fontSize: 30, marginLeft: 10 }}
                />
              </Typography>
            </div>
          </Link>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Link to={"/farmer/my-farming-activities"}>
            <Typography component="h2" gutterBottom variant="overline">
              Total Farm Operation
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {totalFarmOperation}
                <AspectRatioIcon
                  style={{ color: green[500], fontSize: 30, marginLeft: 10 }}
                />
              </Typography>
            </div>
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

Overview.propTypes = {
  className: PropTypes.string,
};

export default Overview;
