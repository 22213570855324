export const appendScript = () => {
  const JQuery = document.createElement("script");
  const BootstrapMin = document.createElement("script");
  const SwiperMin = document.createElement("script");
  const Magnificpop = document.createElement("script");
  const JQThemeTools = document.createElement("script");
  const JQThemeRevolution = document.createElement("script");
  const JQAppear = document.createElement("script");
  const JQCountTo = document.createElement("script");
  const Isotope = document.createElement("script");
  const NiceSelect = document.createElement("script");
  const Range = document.createElement("script");
  const RevolutionActions = document.createElement("script");
  const RevolutionKenBurn = document.createElement("script");
  const RevolutionLayerAnim = document.createElement("script");
  const RevolutionMigration = document.createElement("script");
  const RevolutionParallax = document.createElement("script");
  const RevolutionSlideAnims = document.createElement("script");
  const RevolutionVideo = document.createElement("script");
  const Custom = document.createElement("script");

  JQuery.src = "/assets/js/jquery.js";
  BootstrapMin.src = "/assets/js/bootstrap.min.js";
  SwiperMin.src = "/assets/js/swiper.min.js";
  Magnificpop.src = "/assets/js/magnific-popup.min.js";
  JQThemeTools.src = "/assets/js/jquery.themepunch.tools.min.js";
  JQThemeRevolution.src = "/assets/js/jquery.themepunch.revolution.min.js";
  JQAppear.src = "/assets/js/jquery.appear.js";
  JQCountTo.src = "/assets/js/jquery.countTo.js";
  Isotope.src = "/assets/js/isotope.min.js";
  NiceSelect.src = "/assets/js/nice-select.min.js";
  Range.src = "/assets/js/range.js";
  RevolutionActions.src = "/assets/js/revolution.extension.actions.min.js";
  RevolutionKenBurn.src = "/assets/js/revolution.extension.kenburn.min.js";
  RevolutionLayerAnim.src = "/assets/js/revolution.extension.layeranimation.min.js";
  RevolutionMigration.src = "/assets/js/revolution.extension.migration.min.js";
  RevolutionParallax.src = "/assets/js/revolution.extension.parallax.min.js";
  RevolutionSlideAnims.src = "/assets/js/revolution.extension.slideanims.min.js";
  RevolutionVideo.src = "/assets/js/revolution.extension.video.min.js";
  Custom.src = "/assets/js/custom.js";

  JQuery.defer = true;
  BootstrapMin.defer = true;
  SwiperMin.defer = true;
  Magnificpop.defer = true;
  JQThemeTools.defer = true;
  JQThemeRevolution.defer = true;
  JQAppear.defer = true;
  JQCountTo.defer = true;
  Isotope.defer = true;
  NiceSelect.defer = true;
  Range.defer = true;
  RevolutionActions.defer = true;
  RevolutionKenBurn.defer = true;
  RevolutionLayerAnim.defer = true;
  RevolutionMigration.defer = true;
  RevolutionParallax.defer = true;
  RevolutionSlideAnims.defer = true;
  RevolutionVideo.defer = true;
  Custom.defer = true;

  JQuery.async = false;
  BootstrapMin.async = false;
  SwiperMin.async = false;
  Magnificpop.async = false;
  JQThemeTools.async = false;
  JQThemeRevolution.async = false;
  JQAppear.async = false;
  JQCountTo.async = false;
  Isotope.async = false;
  NiceSelect.async = false;
  Range.async = false;
  RevolutionActions.async = false;
  RevolutionKenBurn.async = false;
  RevolutionLayerAnim.async = false;
  RevolutionMigration.async = false;
  RevolutionParallax.async = false;
  RevolutionSlideAnims.async = false;
  RevolutionVideo.async = false;
  Custom.async = false;

  JQuery.setAttribute('isToRemove', 'true');
  BootstrapMin.setAttribute('isToRemove', 'true');
  SwiperMin.setAttribute('isToRemove', 'true');
  Magnificpop.setAttribute('isToRemove', 'true');
  JQThemeTools.setAttribute('isToRemove', 'true');
  JQThemeRevolution.setAttribute('isToRemove', 'true');
  JQAppear.setAttribute('isToRemove', 'true');
  JQCountTo.setAttribute('isToRemove', 'true');
  Isotope.setAttribute('isToRemove', 'true');
  NiceSelect.setAttribute('isToRemove', 'true');
  Range.setAttribute('isToRemove', 'true');
  RevolutionActions.setAttribute('isToRemove', 'true');
  RevolutionKenBurn.setAttribute('isToRemove', 'true');
  RevolutionLayerAnim.setAttribute('isToRemove', 'true');
  RevolutionMigration.setAttribute('isToRemove', 'true');
  RevolutionParallax.setAttribute('isToRemove', 'true');
  RevolutionSlideAnims.setAttribute('isToRemove', 'true');
  RevolutionVideo.setAttribute('isToRemove', 'true');
  Custom.setAttribute('isToRemove', 'true');

  document.querySelector("#scripts").appendChild(JQuery);
  document.querySelector("#scripts").appendChild(SwiperMin);
  document.querySelector("#scripts").appendChild(Magnificpop);
  document.querySelector("#scripts").appendChild(BootstrapMin);
  document.querySelector("#scripts").appendChild(JQThemeTools);
  document.querySelector("#scripts").appendChild(JQThemeRevolution);
  document.querySelector("#scripts").appendChild(JQAppear);
  document.querySelector("#scripts").appendChild(JQCountTo);
  document.querySelector("#scripts").appendChild(Isotope);
  document.querySelector("#scripts").appendChild(NiceSelect);
  document.querySelector("#scripts").appendChild(Range);
  document.querySelector("#scripts").appendChild(RevolutionActions);
  document.querySelector("#scripts").appendChild(RevolutionKenBurn);
  document.querySelector("#scripts").appendChild(RevolutionLayerAnim);
  document.querySelector("#scripts").appendChild(RevolutionMigration);
  document.querySelector("#scripts").appendChild(RevolutionParallax);
  document.querySelector("#scripts").appendChild(RevolutionSlideAnims);
  document.querySelector("#scripts").appendChild(RevolutionVideo);
  document.querySelector("#scripts").appendChild(Custom);
}

export const removeScript = () => {
  const scriptsToRemove = document.querySelectorAll("script[isToRemove='true']");
  
  for (let i = 0; i < scriptsToRemove.length; i++) {
    const script = scriptsToRemove[i];

    script.parentNode.removeChild(script);
    
  }
}