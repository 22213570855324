import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import api from '../../../../../middlewares/axiosConfig';
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/styles';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';


const useStyles = makeStyles(theme => ({
    root: {
      height: '100%'
    },
    content: {
      padding: '0 !important',
      marginTop: '7px',
      paddingBottom: '20px !important'
    },
    titleStyle: {
        fontWeight: 600,
        lineHeight: '30px',
        fontSize: '16px',
        color: '#0e0e0e'
    },
    avatarStyle: {
        border: '1px solid #c5ccc5',
        // fontSize: '30px',
        borderRadius: '5px',
        color: '#4a4c49'
    },
    margin: {
      margin: theme.spacing(0),
    },

  }));

  
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

const TotalFarmland = () => {
    const classes = useStyles();
    const [data, setData] = useState([
      { name: 'Group A', value: 400 },
      { name: 'Group B', value: 300 },
      { name: 'Group C', value: 300 },
      { name: 'Group D', value: 200 },
    ]);

    const TotalFarmLandPieChart = async () =>{
      let res = await api.get(`Dashboard/FarmStatus`)
      setData([
        {name: "Fallowed Farms", value: res.data.numberOfFallowedFarms},
        {name: "Production Farms",  value: res.data.numberOfProductionFarms},
        {name: "Abandoned Farms", value: res.data.numberOfAbandonedFarms}
      ]);
    }
  
    
      useEffect(()=>{
        TotalFarmLandPieChart();
      },[])

    return(
        <Card className={clsx(classes.root)} className="shadow">
            <CardHeader title="Fallowed, Production and Abandoned Farms" avatar={<DynamicFeedIcon />} classes={{title: classes.titleStyle, avatar: classes.avatarStyle}} />
            <Divider />
            <CardContent className={classes.content} >
                <ResponsiveContainer width="100%"  aspect={2}>
                    <PieChart width={400} height={400}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
}

export default TotalFarmland;