/**
 * /* eslint-disable react/no-multi-comp
 *
 * @format
 */

/* eslint-disable react/display-name */
// import React from 'react';
// import { colors } from '@material-ui/core';
import BarChartIcon from "@material-ui/icons/BarChart";
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
// import ChatIcon from '@material-ui/icons/ChatOutlined';
// import CodeIcon from '@material-ui/icons/Code';
// import DashboardIcon from '@material-ui/icons/DashboardOutlined';
// import ErrorIcon from '@material-ui/icons/ErrorOutline';
// import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeIcon from "@material-ui/icons/HomeOutlined";
// import ListAltIcon from '@material-ui/icons/ListAlt';
// import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
// import MailIcon from '@material-ui/icons/MailOutlined';
// import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import LandscapeIcon from "@material-ui/icons/Landscape";
// import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
// import SettingsIcon from '@material-ui/icons/SettingsOutlined';
// import ViewModuleIcon from '@material-ui/icons/ViewModule';

// import { Label } from '../../../../components/Admin';

export default [
  {
    title: "Partner",
    pages: [
      {
        title: "Home",
        href: "/partners",
        icon: HomeIcon,
      },
      {
        title: "User Access",
        href: "/partners/userAccess",
        icon: PeopleIcon,
        children: [
          {
            title: "User Account",
            href: "/partners/userAccess/partnerAccount",
          },
        ],
      },
      {
        title: "Manage Farmland",
        href: "/partners/manageFarmland",
        icon: LandscapeIcon,
        children: [
          {
            title: "Register Farm",
            href: "/partners/manageFarmland/registerFarm",
          },
          {
            title: "Allocate Farm",
            href: "/partners/manageFarmland/allocateFarm",
          },
          {
            title: "Farm Activities",
            href: "/partners/manageFarmland/farmActivities",
          },
        ],
      },
      {
        title: "Manage Farmer",
        href: "/partners/manageFarmer",
        icon: ReceiptIcon,
        children: [
          {
            title: "Register Farmer",
            href: "/partners/manageFarmer/registerFarmer",
          },
          {
            title: "Verified Farmer",
            href: "/partners/manageFarmer/verifiedFarmer",
          },
        ],
      },
    ],
  },
];
