import React, { Fragment, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Drawer, Divider, Paper, Avatar, Typography } from "@material-ui/core";
import { Hidden } from "@material-ui/core";

import useRouter from "../../../../utils/useRouter";
import { Navigation } from "../../../../components/Admin";
import navigationConfig from "./navigationConfig";
import { toastr } from "react-redux-toastr";

import imagePlaceHolder from "../../../../assets/images/userPlaceHolder.jpg";
import api from "../../../../middlewares/axiosConfig";
import { setBlockData } from "../../../../components/Admin/RichEditor/utils";
import { data } from "dom7";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    height: "calc(100vh - 64px)",
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 120,
    height: 120,
  },
  photoSectionWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overFlowX: "hidden",

    "& > *": {
      margin: theme.spacing(1.5),
    },
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
  imgFluid: {
    maxWidth: "50%",
    height: "auto",
    paddingTop: theme.spacing(2),
    borderRadius: "6.25rem",
    [theme.breakpoints.between("xs", "sm")]: {
      maxWidth: "25%",
    },
  },
  passport: {
    maxWidth: "50%",
    height: "auto",
    borderRadius: "50%",
    justifyContent: "center",
  },
}));

const NavBar = (props) => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();
  // const session = useSelector((state) => state.session);
  const FarmerData = useSelector((state) => state.loginReducer.farmerData);
  const [spinner, setSpinner] = useState(false);
  const [farmerData, setFarmerData] = useState();

  useEffect(async () => {
    const data = await api.get(
      `FarmerMgt/FarmerDetails/${FarmerData.individualFarmerId}`
    );
    if (FarmerData.imageUrl === "No Image Available") {
      setSpinner(false);
      setFarmerData(imagePlaceHolder);
    } else {
      setSpinner(false);
      setFarmerData(`${FarmerData.imageUrl}`);
    }

    
  }, [farmerData]);

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);
  //{upon loggin the passport is displayed}
  const navbarContent = (
    <div className={classes.section}>
      <div className={classes.profile}>
        {FarmerData.imageUrl === "No Image Available" ? (
          <img
            src={imagePlaceHolder}
            alt=""
            className={`${classes.imgFluid}`}
          />
        ) : (
          <img
            src={FarmerData.imageUrl}
            alt=""
            className={`${classes.passport}`}
          />
        )}
        <Typography className={classes.name} variant="h4">
          {/* {session.user.first_name} {session.user.last_name} */}
          {FarmerData.firstName} {FarmerData.lastName}
        </Typography>
        {/* <Typography variant="body2">{session.user.bio}</Typography> */}
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navigationConfig.map((list) => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />
        ))}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
