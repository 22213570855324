/** @format */

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  //using this state for storing farmer, patner and service provider data
  farmerData: {
    emailAddress: null,
    firstName: "",
    identificationNumber: "string",
    individualFarmerId: null,
    lastName: "",
    middleName: "",
    phoneNumber: "",
    statusId: null,
    token: null,
    email: "",
    RCNumber: null,
    corporateName: null,
    corporateSocietyName: [],
    partnerCooperatives: "",
    currentlySelectedCooperative: null,
    partnerId: null,
    partnerDetailId: null,
  },
  error: null,
  loading: false,
  loginRedirectPath: "/farmer",
};

const loginStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const loginSuccess = (state, action) => {
  return {
    ...state,
    farmerData: { ...state.farmerData, ...action.farmerData },
  };
};

const loginFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const setLoginRedirectPath = (state, action) => {
  return updateObject(state, {
    loginRedirectPath: action.path,
  });
};

const Logout = (state, action) => {
  return {
    ...state,
    farmerData: {
      ...initialState.farmerData,
    },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_CONSTANTS.LOGIN_START:
      return loginStart(state, action);
    case actionTypes.LOGIN_CONSTANTS.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.LOGIN_CONSTANTS.LOGIN_FAIL:
      return loginFail(state, action);
    case actionTypes.LOGIN_CONSTANTS.LOGOUT:
      return Logout(state, action);
    case actionTypes.LOGIN_CONSTANTS.SET_LOGIN_REDIRECT_PATH:
      return setLoginRedirectPath(state, action);
    default:
      return state;
  }
};

export default reducer;
