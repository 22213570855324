/** @format */

import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: false,
  failed: false,
  cooperativeData: null,

  adding: false,
  added: false,
  addFailed: false,

  editing: false,
  edited: false,
  editFailed: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.GET_COOPERATIVES_START:
      return { ...state, loading: true, failed: false };
    case actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.GET_COOPERATIVES_SUCCESS:
      return {
        ...state,
        loading: false,
        cooperativeData: action.payload,
        failed: false,
      };
    case actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.GET_COOPERATIVES_FAIL:
      return { ...state, loading: false, cooperativeData: null, failed: true };

    // ADD
    case actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.ADD_COOPERATIVE_START:
      return { ...state, adding: true, added: false };
    case actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.ADD_COOPERATIVE_SUCCESS:
      return { ...state, adding: false, added: true, addFailed: false };
    case actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.ADD_COOPERATIVE_FAIL:
      return { ...state, adding: false, added: false, addFailed: true };

    // EDIT
    case actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.EDIT_COOPERATIVE_START:
      return { ...state, editing: true, edited: false };
    case actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.EDIT_COOPERATIVE_SUCCESS:
      return { ...state, editing: false, edited: true, editFailed: false };
    case actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.EDIT_COOPERATIVE_FAIL:
      return { ...state, editing: false, edited: false, editFailed: true };
    case actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.EDIT_RESET:
      return { ...state, editing: false, edited: false, editFailed: false };

    default:
      return state;
  }
};

export default reducer;
