import { put } from 'redux-saga/effects';
import api from '../../middlewares/axiosConfig';
import { toastr } from 'react-redux-toastr';


import * as actions from '../actions';

export function* getTownsSaga() {
    try {
        yield put(actions.getTownsStart());
        const response = yield api.get('SetUp/GetTowns');
        let towns = response.data;
        yield put(actions.getTownsSuccess(towns));
    } catch (error) {
        toastr.error("Error", "An error occured while trying to get towns", {
            onCloseButtonClick: true
        });
        yield put(actions.getTownsFail(error));
    }
}

export function* createTownSaga(data) {
    try {
        yield put(actions.createTownStarts());
        let response = yield api.post(`SetUp/CreateTown`, data.town);
        toastr.success(`Success`, response.data.message, {
            closeButton: true,
        });
        yield put(actions.createTownSuccess());
        yield put(actions.getTowns());
    } catch (error) {
        toastr.error("Error", "An error occured while creating town", {
            onCloseButtonClick: true
        });
        yield put(actions.createTownFails(error));
    }
}

export function* editTownSaga(data) {
    try {
        yield put(actions.getTownStarts());
        let response = yield api.get(`SetUp/GetTownById/${data.townId}`);
        yield put(actions.getTownSuccess(response.data));
    } catch (error) {
        toastr.error("Error", "An error occured while getting town", {
            onCloseButtonClick: true
        });
        yield put(actions.getTownFails(error));
    }
}

export function* editTownStartsSaga(data) {
    try {
        let response = yield api.patch(`SetUp/UpdateTown/${data.townData.townId}`, data.townData);
        yield put(actions.editTownSuccess());
        toastr.success(`Success`, response.data.message, {
            closeButton: true,
        });
        yield put(actions.getTowns());
    } catch (error) {
        toastr.error("Error", "An error occured while editing town", {
            onCloseButtonClick: true
        });
        yield put(actions.editTownFails(error));
    }
}

export function* deleteTownSaga(townData) {
    try {
        let response = yield api.delete(`SetUp/DeleteTown/${townData.townId}`);
        toastr.success(`Success`, response.data.message, {
            closeButton: true,
        });
        yield put(actions.getTowns());
    } catch (error) {
        toastr.error("Error", "An error occured deleting town", {
            onCloseButtonClick: true
        });
    }
}