/** @format */

import React, { Suspense, useState, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import Cookies from "js-cookie";
import { toastr } from "react-redux-toastr";

import { NavBar, TopBar, ChatBar } from "./components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { setAdminAuth } from "../../redux/actions/adminActions";
import { setAdminLogout } from "../../redux/actions/adminActions";
import jwt_decode from "jwt-decode";
import siteLogo from "../../assets/images/preloader.gif";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    "& *:focus": {
      outline: "none",
    },
  },
  topBar: {
    zIndex: 10,
    position: "relative",
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: "0 0 auto",
  },
  content: {
    overflowY: "auto",
    flex: "1 1 auto",
    height: "calc(100vh - 64px)",
  },
}));

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [paintBrowser, setPaintBrowser] = useState(false);

  const adminState = useSelector((state) => state.adminReducer.user);

  useEffect(() => {
    document.body.style.overflowY = "hidden";

    function checkUser() {
      Cookies.remove("farmerData");
      Cookies.remove("partnerData");
      Cookies.remove("serviceProviderData");

      if (adminState.token === "") {
        let adminData = Cookies.getJSON("adminData");
        // console.log(adminData);

        if (adminData) {
          setPaintBrowser(true);
          dispatch(setAdminAuth(adminData));
          return;
        }

        // history.push('/');
        window.location.href = "/";
        toastr.error("UNAUTHORIZED", "Please login to continue", {
          closeButton: true,
        });
      }
    }

    checkUser();

    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  useEffect(() => {
    const token = Cookies.getJSON("adminData");
    // console.log(token);
    if (token) {
      let decodedToken = jwt_decode(token.token);
      // console.log(decodedToken);
      if (Date.now() >= decodedToken.exp * 1000) {
        // let expirationTime = new Date(decodedToken.exp * 1000);
        // let expirationDate = expirationTime.toString();

        // console.log(expirationDate);
        // console.log(expirationTime);

        // Cookies.remove('farmerData');
        Cookies.remove("adminData");
        Cookies.remove("farmerData");
        Cookies.remove("partnerData");
        Cookies.remove("serviceProviderData");
        dispatch(setAdminLogout());
        // dispatch(actions.logout());
        window.location.href = "/";
      }
    }
  }, []);

  // useEffect(() => {
  //   console.log(adminState);
  // }, [adminState]);

  const { route } = props;

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const loader = (
    <div className="preloader_wrapper">
      <div className="preloader_inner">
        <img src={siteLogo} alt="image" />
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {paintBrowser
              ? renderRoutes(route.routes, { isLocation: "admin" })
              : loader}
          </Suspense>
        </main>
      </div>
      {/* <ChatBar /> */}
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
