import * as actionTypes from './actionTypes';


export const getAllCooperativeSociety =()=>{
    return{
        type: actionTypes.PARTNERS_CONSTANTS.GET_ALL_COOPERATIVE_SOCIETY
    }
}

export const getAllCooperativeSocietyStart =() =>{
    return{
        type: actionTypes.PARTNERS_CONSTANTS.GET_ALL_COOPERATIVE_SOCIETY_START
    }
}

export const getAllCooperativeSocietyFails = (error) =>{
    return{
        type: actionTypes.PARTNERS_CONSTANTS.GET_ALL_COOPERATIVE_SOCIETY_FAILS,
        error: error
    }
}

export const getAllCooperativeSocietySuccess = (cooperativeData) =>{
    return{
        type: actionTypes.PARTNERS_CONSTANTS.GET_ALL_COOPERATIVE_SOCIETY_SUCCESS,
        cooperative: cooperativeData
    }
}


















// import { PARTNER_DASHBOARD_CONSTANT } from "./actionTypes";


// export const partnerDashBoard = () => {
//     return ({
//         type: PARTNER_DASHBOARD_CONSTANT.GET_ALL_COOPERATIVES
//     })
// }

// export const partnerDashBoardSuccess = (partnerData) => {
//     return ({
//         type: PARTNER_DASHBOARD_CONSTANT.GET_ALL_COOPERATIVES_SUCCESS,
//         payload: partnerData
//     })
// }

// export const partnerDashBoardFail = (error) => {
//     return ({
//         type: PARTNER_DASHBOARD_CONSTANT.GET_ALL_COOPERATIVES_FAIL,
//         payload: error
//     })
// }

