/** @format */

import React, { Suspense, useEffect, useState, useCallback } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { LinearProgress } from "@material-ui/core";
import Cookies from "js-cookie";

import siteLogo from "../../assets/images/preloader.gif";
import Header from "./components/header";
import Footer from "./components/footer";
import { appendScript, removeScript } from "../../utils/addScripts";
import { useDispatch } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { toastr } from "react-redux-toastr";

import validators from "../../middlewares/validators";
import api from "../../middlewares/axiosConfig";
import { createBrowserHistory } from "history";

import { setAdminAuth } from "../../redux/actions/adminActions";
import Admin33 from "../../assets/images/Admin33.jpg";
import { useHistory } from "react-router-dom";
import superAdminLogin from "../../views/SuperAdmin/SuperAdminLogin";

const useStyles = makeStyles((theme) => ({
  image: {
    // backgroundImage: 'url(/assets/images/adminSvg.svg)',
    backgroundImage: `url(${Admin33})`,

    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#1FA12E",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#1FA12E",
    color: "#fff",
  },
  DialogTitle: {
    "& h2": {
      fontSize: "1.5rem",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const HomeLayout = (props) => {
  const { route } = props;
  const history = useHistory();
  // let history = createBrowserHistory({ forceRefresh: true });

  useEffect(() => {
    appendScript();

    return () => {
      removeScript();
    };
  }, []);

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    emailError: false,
    emailErrorMsg: "",
    passwordError: false,
    passwordErrorMsg: "",
    buttonState: false,
    buttonText: "SIGN IN",
  });

  const [formValues, setFormValues] = useState({
    EmailAddress: "",
    Password: "",
  });

  function handleClose() {
    setOpen(false);
  }

  function openAdminLogin() {
    setOpen(true);
  }

  function setIsRequiredError(
    value,
    stateError,
    stateErrorMsg,
    len = 1,
    errorMsg = "Field is required",
    max = 0,
    select = false
  ) {
    if (!validators.isRequired(value, len, max, select)) {
      setFormState({
        ...formState,
        [stateError]: true,
        [stateErrorMsg]: errorMsg,
      });
      return false;
    }

    setFormState({ ...formState, [stateError]: false, [stateErrorMsg]: "" });
    return true;
  }

  function emailHandler(e) {
    let emailValue = document.getElementById("email").value.trim();

    if (e) {
      e.preventDefault();
      setFormValues({ ...formValues, EmailAddress: emailValue });
    }

    return setIsRequiredError(emailValue, "emailError", "emailErrorMsg");
  }

  function passwordHandler(e) {
    let passwordValue = document.getElementById("password").value.trim();

    if (e) {
      e.preventDefault();
      setFormValues({ ...formValues, Password: passwordValue });
    }

    return setIsRequiredError(
      passwordValue,
      "passwordError",
      "passwordErrorMsg"
    );
  }

  function submit() {
    Cookies.remove("partnerData");
    Cookies.remove("farmerData");
    Cookies.remove("adminData");
    Cookies.remove("serviceProviderData");

    if (!emailHandler() || !passwordHandler()) return;

    setFormState({ ...formState, buttonState: true, buttonText: "...LOADING" });
    let data = { ...formValues };

    api
      .post("admin/AdminLogin", data)
      .then((data) => {
        dispatch(setAdminAuth(data.data));
        setOpen(false);
        toastr.success(`Success`, `Successfully logged in`, {
          closeButton: true,
        });

        setFormState({
          ...formState,
          buttonState: false,
          buttonText: "SIGN IN",
        });
        history.push("/admin");
        // window.location('/admin')
        // KentFrank@mail.com
      })
      .catch((error) => {
        toastr.error(`Error`, error.data.message, {
          closeButton: true,
        });
        setFormState({
          ...formState,
          buttonState: false,
          buttonText: "SIGN IN",
        });
      });
  }

  const superAdminHandler = useCallback(() => {
    window.location.href = "/superAdmin_login";
    // history.push("/superAdmin_login");
  }, []);

  return (
    <>
      <div className="preloader_wrapper">
        <div className="preloader_inner">
          <img src={siteLogo} alt="loader" />
          {/* <img src={Admin33} alt="Admin Sign in Logo" /> */}
        </div>
      </div>
      <div className="clv_main_wrapper index_v2">
        <Header />
        <>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes, { isLocation: "home" })}
          </Suspense>
        </>
        <Footer openAdminLogin={openAdminLogin} />

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={open}
          className={classes.Dialog}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle
            id="customized-dialog-title"
            className={classes.DialogTitle}
          >
            Admin Login
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container component="main" className={classes.root}>
              <CssBaseline />
              <Grid item xs={false} sm={4} md={7} className={classes.image} />
              <Grid
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={6}
                square
              >
                <div className={classes.paper}>
                  <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <div className={classes.form} noValidate>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      error={formState.emailError}
                      helperText={formState.emailErrorMsg}
                      value={formValues.EmailAddress}
                      onChange={emailHandler}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      error={formState.passwordError}
                      helperText={formState.passwordErrorMsg}
                      value={formValues.Password}
                      onChange={passwordHandler}
                    />
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      className={classes.submit}
                      disabled={formState.buttonState}
                      onClick={submit}
                    >
                      {formState.buttonText}
                    </Button>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button onClick={superAdminHandler}>SuperAdmin</Button>
                    </div>
                    {/* <Grid container>
                      <Grid item xs>
                        <Link href="#" variant="body2">
                          Forgot password?
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href="#" variant="body2">
                          {"Don't have an account? Sign Up"}
                        </Link>
                      </Grid>
                    </Grid>
                    <Box mt={5}>
                      <Copyright />
                    </Box> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </>
  );
};

HomeLayout.propTypes = {
  route: PropTypes.object,
};

export default HomeLayout;
