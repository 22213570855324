/** @format */

import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid, Button, ButtonGroup } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import CalendarTodayIcon from "@material-ui/icons/CalendarTodayOutlined";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {},
  dates: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Header = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const FarmerData = useSelector((state) => state.loginReducer.farmerData);
  const date = new Date();
  const hour = date.getHours();

  return (
    //{greeting the user using his/her system time with the user first name}
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography component="h1" gutterBottom variant="h3">
        {hour >= 12
          ? hour >= 16
            ? "Good Evening"
            : "Good Afternoon"
          : "Good Morning"}{" "}
        {""}
        {FarmerData.firstName} {""} {FarmerData.lastName} !
      </Typography>
      {/* <Typography variant="subtitle1">Welcome to Your Dashboard</Typography> */}
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

Header.defaultProps = {};

export default Header;
