/** @format */

import { put } from "redux-saga/effects";
import api from "../../middlewares/axiosConfig";
import { toastr } from "react-redux-toastr";
import * as actions from "../actions";

export function* getCooperatives() {
  try {
    yield put(actions.getCooperativesStart());
    let response = yield api.get(`PortalAdmin/GetAllCooperativeSociety`);
    const output = [];
    response.data.map((val) => {
      let row = [
        "",
        // `${val.cooperativeSocietyId || ""}`,
        `${val.cooperativeSocietyName || ""}`,
        `${val.cacNumber || ""}`,
        `${val.email || ""}`,
        `${val.address || ""}`,
        [val.cooperativeSocietyId],
      ];
      output.push(row);
    });
    yield put(actions.getCooperativesSuccess(output));
  } catch (error) {
    toastr.error("Error", "An error occured while getting data", {
      onCloseButtonClick: true,
    });
    yield put(actions.getCooperativesFail(error));
  }
}

export function* addCooperatives(data) {
  try {
    yield put(actions.addCooperativesStart());
    let response = yield api.post(
      `PortalAdmin/CreateCooperativeSociety`,
      data?.cooperative
    );
    toastr.success(`Success`, response.data.message, {
      closeButton: true,
    });
    yield put(actions.addCooperativesSuccess());
  } catch (error) {
    toastr.error("Error", "An error occured while getting data", {
      onCloseButtonClick: true,
    });
    yield put(actions.addCooperativesFail(error));
  }
}

export function* editCooperatives(data) {
  console.log(data, "inhere");
  try {
    yield put(actions.editCooperativeStart());
    let response = yield api.put(
      `PortalAdmin/UpdateCooperativeSocity/${data?.cooperativesData.cooperativeSocietyId}`,
      data?.cooperativesData
    );
    yield put(actions.editCooperativeSuccess());
  } catch (error) {
    toastr.error("Error", "An error occured while getting data", {
      onCloseButtonClick: true,
    });
    yield put(actions.editCooperativeFail(error));
  }
}
