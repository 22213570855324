/** @format */

import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Button,
  Grid,
  TextField,
  Divider,
  Typography,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
import SetupImg from "../../../assets/images/agric/signUp.jpg";
import { green } from "@material-ui/core/colors";

import validators from "../../../middlewares/validators";
import * as actions from "../../../redux/actions";
import api from "../../../middlewares/axiosConfig";
import { toastr } from "react-redux-toastr";
import farmImage from "./cre-regAccount/imaFarm.avif";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      margin: "2px",
    },
    "& .MuiGrid-item": {
      padding: "4px",
    },
  },
  subText: {
    fontSize: "10px",
  },
  image: {
    backgroundImage: "url(" + farmImage + ")",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  avatar: {
    marginTop: theme.spacing(1),
    backgroundColor: "#1FA12E",
  },
  form: {
    // display: "flex",
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(0),
  },

  successButton: {
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#1FA12E",
    color: "#fff",
  },
  DialogTitle: {
    "& h2": {
      fontSize: "1.6rem",
      textAlign: "center",
      fontWeight: 700,
      margin: "5px 0 5px 0",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const CreateFarmersForm = (props) => {
  const initialFormFieldValues = {
    firstName: "",
    lastName: "",
    middleName: "",
    emailAddress: "",
    phoneNumber: "",
    identificationNumber: "",
    password: "",
    confirmPassword: "",
  };

  const initialFormState = {
    firstNameError: false,
    firstNameErrorMsg: "",
    lastNameError: false,
    lastNameErrorMsg: "",
    middleNameError: false,
    middleNameErrorMsg: "",
    emailAddressError: false,
    emailAddressErrorMsg: "",
    phoneNumberError: false,
    phoneNumberErrorMsg: "",
    idNumberError: false,
    idNumberErrorMsg: "",
    passwordError: false,
    passwordErrorMsg: "",
    confirmPasswordError: false,
    confirmPasswordErrorMsg: "",
    isPasswordVisible: false,
    buttonState: false,
    buttonText: "Create Account",
  };

  const classes = useStyles();
  const dispatch = useDispatch();
  const { OptionCreClose, onClose } = props;
  const [formState, setFormState] = useState({ ...initialFormState });
  const [formValues, setFormValues] = useState({ ...initialFormFieldValues });
  const accountCreated = useSelector(
    (state) => state.createAccountReducer.accountCreated
  );
  let accountCreateFailed = useSelector(
    (state) => state.createAccountReducer.createError
  );
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  let createfailed = false;

  useEffect(() => {
    // accountCreated;
    resetForm();
  }, [accountCreated]);

  useEffect(() => {
    accountCreateFailed &&
      setFormState({
        ...formState,
        buttonState: false,
        buttonText: "Create Account",
      });
    dispatch(actions.createAccountFails2());
  }, [accountCreateFailed]);

  useEffect(() => {
    resetForm();
  }, []);

  const setIsRequiredError = (
    value,
    stateError,
    stateErrorMsg,
    len = 1,
    errorMsg = "Field is required",
    max = 0,
    select = false
  ) => {
    if (!validators.isRequired(value, len, max, select)) {
      setFormState({
        ...formState,
        [stateError]: true,
        [stateErrorMsg]: errorMsg,
      });
      return false;
    }
    setFormState({ ...formState, [stateError]: false, [stateErrorMsg]: "" });
    return true;
  };

  const firstNameHandler = (e) => {
    e.preventDefault();
    if (e) {
      let firstNameValue = e.target.value;
      setFormValues({ ...formValues, firstName: firstNameValue });

      return setIsRequiredError(
        firstNameValue,
        "firstNameError",
        "firstNameErrorMsg"
      );
    } else {
      let firstNameValue = document.getElementById("firstName").value;
      return setIsRequiredError(
        firstNameValue,
        "firstNameError",
        "firstNameErrorMsg"
      );
    }
  };

  const lastNameHandler = (e) => {
    e.preventDefault();
    if (e) {
      let lastNameValue = e.target.value;

      setFormValues({ ...formValues, lastName: lastNameValue });

      return setIsRequiredError(
        lastNameValue,
        "lastNameError",
        "lastNameErrorMsg"
      );
    } else {
      let lastNameValue = document.getElementById("lastName").value;
      return setIsRequiredError(
        lastNameValue,
        "lastNameError",
        "lastNameErrorMsg"
      );
    }
  };

  const middleNameHandler = (e) => {
    if (e) {
      let middleNameValue = e.target.value;
      e.preventDefault();
      setFormValues({ ...formValues, middleName: middleNameValue });

      return setIsRequiredError(
        middleNameValue,
        "middleNameError",
        "middleNameErrorMsg"
      );
    } else {
      let middleNameValue = document.getElementById("middleName").value;
      return setIsRequiredError(
        middleNameValue,
        "middleNameError",
        "middleNameErrorMsg"
      );
    }
  };

  const emailAddressHandler = (e) => {
    let emailAddressValue = e.target.value.trim();
    if (e) {
      e.preventDefault();
      setFormValues({ ...formValues, emailAddress: emailAddressValue });
    }

    if (emailAddressValue === "") {
      setFormState({
        ...formState,
        emailAddressError: false,
        emailAddressErrorMsg: "",
      });
      return true;
    }

    if (!validators.isValidEmail(emailAddressValue)) {
      setFormState({
        ...formState,
        emailAddressError: true,
        emailAddressErrorMsg:
          "Please enter a valid email or clear your selection",
      });
      return false;
    }

    setFormState({
      ...formState,
      emailAddressError: false,
      emailAddressErrorMsg: "",
    });
    return true;
  };

  const phoneNumberHandler = (e) => {
    if (e) {
      let phoneNumberValue = e.target.value.trim();
      e.preventDefault();
      if (phoneNumberValue.toString().length > 11) {
        document.getElementById("phoneNumber").value = phoneNumberValue
          .toString()
          .slice(0, 11);
        return;
      } else {
        setFormValues({ ...formValues, phoneNumber: phoneNumberValue });
        return setIsRequiredError(
          phoneNumberValue,
          "phoneNumberError",
          "phoneNumberErrorMsg",
          11,
          "Field is required and should be 11 in number",
          11
        );
      }
    } else {
      let phoneNumberValue = document
        .getElementById("phoneNumber")
        .value.trim();
      if (phoneNumberValue.toString().length > 11) {
        phoneNumberValue = phoneNumberValue.slice(0, 11);
        return setIsRequiredError(
          phoneNumberValue,
          "phoneNumberError",
          "phoneNumberErrorMsg",
          11,
          "Field is required and should be 11 in number",
          11
        );
      }
      return setIsRequiredError(
        phoneNumberValue.toString().slice(0, 11),
        "phoneNumberError",
        "phoneNumberErrorMsg",
        11,
        "Field is required and should be 11 in number",
        11
      );
    }
  };

  const identificationNumberHandler = (e) => {
    if (e) {
      let idNumberValue = e.target.value.trim();
      e.preventDefault();
      if (idNumberValue.length > 9) {
        document.getElementById("idN").value = idNumberValue.slice(0, 9);
        return;
      } else {
        setFormValues({ ...formValues, identificationNumber: idNumberValue });
        return setIsRequiredError(
          idNumberValue,
          "idNumberError",
          "identificationNumberErrorMsg",
          9,
          "Field is required and should be 9 in number",
          9
        );
      }
    } else {
      let idNumberValue = document.getElementById("idN").value.trim();
      if (idNumberValue.length > 9) {
        document.getElementById("idN").value = idNumberValue.slice(0, 9);
        return setIsRequiredError(
          idNumberValue.slice(0, 9),
          "idNumberError",
          "idNumberErrorMsg",
          9,
          "Field is required and should be 9 in number",
          9
        );
      }
      return setIsRequiredError(
        idNumberValue,
        "idNumberError",
        "idNumberErrorMsg",
        9,
        "Field is required",
        9
      );
    }
  };

  const inputPasswordHandler = (event) => {
    if (event) event.preventDefault();
    let isStrongPassword = validators.isStrongPassword(
      passwordRef.current.value
    );

    setFormValues({ ...formValues, password: passwordRef.current.value });

    if (!isStrongPassword) {
      setFormState({
        ...formState,
        passwordError: true,
        passwordErrorMsg:
          "Password must have at least one capital letter, small letter, a number and more than 5 letters",
      });
      return false;
    }

    setFormState({ ...formState, passwordError: false, passwordErrorMsg: "" });
    return true;
  };

  const inputConfirmPasswordHandler = (event) => {
    if (event) event.preventDefault();

    let isStrongPassword = validators.isStrongPassword(
      confirmPasswordRef.current.value
    );
    setFormValues({
      ...formValues,
      confirmPassword: confirmPasswordRef.current.value,
    });

    // if (!isStrongPassword) {
    //     setFormState({ ...formState, confirmPasswordError: true, confirmPasswordErrorMsg: "Password must have at least one capital letter, small letter, a number and more than 5 letters"  });
    //     return false;
    // }

    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
      setFormState({
        ...formState,
        confirmPasswordError: true,
        confirmPasswordErrorMsg: "Passwords must match",
      });
      return false;
    }

    setFormState({
      ...formState,
      confirmPasswordError: false,
      confirmPasswordErrorMsg: "",
    });
    return true;
  };

  const handleClickShowPassword = () => {
    setFormState({
      ...formState,
      isPasswordVisible: !formState.isPasswordVisible,
    });
  };

  function resetForm() {
    setFormValues({ ...initialFormFieldValues });
    setFormState({ ...initialFormState });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // if ( !firstNameHandler() || !lastNameHandler() || !phoneNumberHandler() || !identificationNumberHandler() || !inputPasswordHandler() || !inputConfirmPasswordHandler() ){
    //   return;
    // }
    if (
      formState.firstNameError ||
      formState.lastNameError ||
      formState.phoneNumberError ||
      formState.identificationNumberError
      // formState.password ||
      // formState.confirmPassword
    ) {
      return;
    }
    setFormState({ ...formState, buttonState: true, buttonText: "...LOADING" });

    let data = { ...formValues, accountStatusId: 0 };

    dispatch(actions.createAccount(data));
    onClose();
  };

  return (
    <Fragment>
      <div>
        <DialogTitle
          id="customized-dialog-title"
          className={classes.DialogTitle}
        >
          {`Create Farmer Account`}
          <br />
          <span className={classes.subText}>
            <i>(with Identity Number)</i>
          </span>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={5} className={classes.image} />

            <Grid
              container
              direction="row"
              item
              xs={12}
              md={6}
              component={Paper}
              elevation={6}
              spacing={2}
              className={classes.form}
              justify="center"
              alignItems="center"
              // square
            >
              {/* <div className={classes.paper}> */}

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
              </Grid>
              {/* <div className={classes.form}> */}
              {/* <form noValidate autoComplete="off"> */}
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  // fullWidth={true}
                  label="* FirstName"
                  inputProps={{ form: { autocomplete: "off" } }}
                  id="firstName"
                  margin="normal"
                  error={formState.firstNameError}
                  onChange={firstNameHandler}
                  helperText={formState.firstNameErrorMsg}
                  value={formValues.firstName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  // fullWidth={true}
                  label="* LastName"
                  id="lastName"
                  margin="normal"
                  inputProps={{ form: { autocomplete: "off" } }}
                  error={formState.lastNameError}
                  onChange={lastNameHandler}
                  helperText={formState.lastNameErrorMsg}
                  value={formValues.lastName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  // fullWidth={true}
                  label=" MiddleName"
                  id="middleName"
                  margin="normal"
                  inputProps={{ form: { autocomplete: "off" } }}
                  error={formState.middleNameError}
                  onChange={middleNameHandler}
                  helperText={formState.middleNameErrorMsg}
                  value={formValues.middleName}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  // fullWidth={true}
                  value={formValues.identificationNumber}
                  margin="normal"
                  inputProps={{ form: { autocomplete: "off" } }}
                  id="idN"
                  onChange={identificationNumberHandler}
                  error={formState.idNumberError}
                  helperText={formState.idNumberErrorMsg}
                  label="* Identification Number"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  label=" Email"
                  // fullWidth={true}
                  id="emailAddress"
                  margin="normal"
                  inputProps={{ form: { autocomplete: "off" } }}
                  error={formState.emailAddressError}
                  onChange={emailAddressHandler}
                  helperText={formState.emailAddressErrorMsg}
                  value={formValues.emailAddress}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  type="number"
                  // fullWidth={true}
                  label="* Phone Number"
                  margin="normal"
                  inputProps={{ form: { autocomplete: "off" } }}
                  id="phoneNumber"
                  error={formState.phoneNumberError}
                  onChange={phoneNumberHandler}
                  helperText={formState.phoneNumberErrorMsg}
                  value={formValues.phoneNumber}
                />
              </Grid>
              {/* <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={formState.passwordError}
                  margin="normal"
                  value={formValues.password}
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    * Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={formState.isPasswordVisible ? "text" : "password"}
                    fullWidth={true}
                    inputRef={passwordRef} // value={states.password}
                    onChange={inputPasswordHandler}
                    inputProps={{
                      autoComplete: "new-password",
                      form: { autocomplete: "off" },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword} // onMouseDown={handleMouseDownPassword}
                          edge="end"
                          className={classes.iconButton}
                        >
                          {formState.isPasswordVisible ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                  <FormHelperText id="component-error-text">
                    {formState.passwordErrorMsg}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={formState.confirmPasswordError}
                  margin="normal"
                  value={formValues.confirmPassword}
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    * Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-confirm-password"
                    type={formState.isPasswordVisible ? "text" : "password"}
                    fullWidth={true}
                    inputRef={confirmPasswordRef} // value={states.password}
                    onChange={inputConfirmPasswordHandler}
                    value={formValues.confirmPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword} // onMouseDown={handleMouseDownPassword}
                          edge="end"
                          className={classes.iconButton}
                        >
                          {formState.isPasswordVisible ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                  <FormHelperText id="component-error-text">
                    {formState.confirmPasswordErrorMsg}
                  </FormHelperText>
                </FormControl>
              </Grid> */}
              {/* </form> */}
              {/* </div> */}
              {/* </div> */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            disabled={formState.buttonState}
            variant="contained"
            onClick={handleSubmit}
            className={classes.successButton}
          >
            {formState.buttonText}
          </Button>
        </DialogActions>
      </div>
    </Fragment>
  );
};
export default CreateFarmersForm;
