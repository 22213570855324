/** @format */

import { put, delay } from "redux-saga/effects";
import { createBrowserHistory } from "history";

import api from "../../middlewares/axiosConfig";
import Cookies from "js-cookie";
import { toastr } from "react-redux-toastr";

import * as actions from "../actions";

let history = createBrowserHistory({ forceRefresh: true });

export function* loginFarmerSaga(action) {
  yield put(actions.loginStart());
  const authData = {
    emailORPhoneNumber: action.email,
    password: action.password,
  };

  try {
    //let response = yield api.post('FarmerAccounts/LoginFarmer', authData);
    let response = yield api.post("FarmerMgt/LoginFarmer", authData);
    let data = response.data;

    const adminData = yield Cookies.getJSON("adminData");
    const partnerData = yield Cookies.getJSON("partnerData");
    const serviceProviderData = yield Cookies.getJSON("serviceProviderData");
    adminData && Cookies.remove("adminData");
    partnerData && Cookies.remove("partnerData");
    serviceProviderData && Cookies.remove("serviceProviderData");
    toastr.success("Success", `welcome back ${data.firstName}`, {
      onCloseButtonClick: true,
    });
    Cookies.set("farmerData", data, { expires: 7 });
    yield put(actions.loginSuccess(data));
    history.push("/farmer");

    // yield localStorage.setItem('eAgricToken', response.data.idToken);
    // yield localStorage.setItem('eAgricUserId', response.data.localId);
    // yield put(actions.loginSuccess(response.data.idToken, response.data.localId));
    // yield put(actions.checkTokenTimeout(response.data.expiresIn))
  } catch (error) {
    // yield put(push('/admin/'));
    toastr.error("Error", "email or password is incorrect", {
      onCloseButtonClick: true,
    });
    yield put(actions.loginFail(error));
    // document.getElementsByClassName("success_close")[0].click();
  }
}

export function* superAdminLogin(action) {
  yield put(actions.loginStart());
  const authData = {
    email: action.email,
    password: action.password,
  };

  try {
    let response = yield api.post("PortalAdmin/login", authData);
    let data = response.data;
    const adminData = yield Cookies.getJSON("adminData");
    const partnerData = yield Cookies.getJSON("partnerData");
    const serviceProviderData = yield Cookies.getJSON("serviceProviderData");
    const farmerData = yield Cookies.getJSON("farmerData");
    farmerData && Cookies.remove("farmerData");
    adminData && Cookies.remove("adminData");
    partnerData && Cookies.remove("partnerData");
    serviceProviderData && Cookies.remove("serviceProviderData");
    Cookies.set("superAdminData", data, { expires: 7 });
    toastr.success(
      "Success",
      `welcome back ${data.firstName} ${""} ${data.lastName}`,
      {
        onCloseButtonClick: true,
      }
    );
    yield put(actions.loginSuccess(data));
    history.push("/super_admin");
    // window.location.href = "/super_admin";
  } catch (error) {
    toastr.error("Error", "email or password is incorrect", {
      onCloseButtonClick: true,
    });
    yield put(actions.loginFail(error));
  }
}

export function* loginPartnerSaga(action) {
  yield put(actions.loginStart());

  const authData = {
    email: action.email,
    password: action.password,
  };
  const adminData = yield Cookies.getJSON("adminData");
  const farmerData = yield Cookies.getJSON("farmerData");
  const serviceProviderData = yield Cookies.getJSON("serviceProviderData");
  adminData && Cookies.remove("adminData");
  farmerData && Cookies.remove("farmerData");
  serviceProviderData && Cookies.remove("serviceProviderData");
  try {
    let response = yield api.post("Partners/LoginPartner", authData);
    let data = response.data;
    const adminData = yield Cookies.getJSON("adminData");
    const farmerData = yield Cookies.getJSON("farmerData");
    const serviceProviderData = yield Cookies.getJSON("serviceProviderData");
    adminData && Cookies.remove("adminData");
    farmerData && Cookies.remove("farmerData");
    serviceProviderData && Cookies.remove("serviceProviderData");

    toastr.success(
      "Success",
      `welcome back ${data.firstName ? data.firstName : data.corporateName}`,
      {
        onCloseButtonClick: true,
      }
    );
    Cookies.set("partnerData", data, { expires: 7 });

    // let partner = yield api.get(
    //   `Partners/GetAllPartnerCooperativebyPartnerId/${data.partnerDetailId}`
    // );
    // let names = partner.data;
    // console.log("Partners", names);
    // yield put(actions.loginSuccess({ ...data, partnerCooperatives: names }));
    yield put(actions.loginSuccess(data));
    history.push("/partners");

    // yield localStorage.setItem('eAgricToken', response.data.idToken);
    // yield localStorage.setItem('eAgricUserId', response.data.localId);
    // yield put(actions.loginSuccess(response.data.idToken, response.data.localId));
    // yield put(actions.checkTokenTimeout(response.data.expiresIn))
  } catch (error) {
    // yield put(push('/admin/'));

    toastr.error("Error", "email or password is incorrect", {
      onCloseButtonClick: true,
    });
    yield put(actions.loginFail(error));
    // document.getElementsByClassName("success_close")[0].click();
  }
}

export function* loginServiceProviderSaga(action) {
  yield put(actions.loginStart());

  const authData = {
    email: action.email,
    password: action.password,
  };
  const adminData = yield Cookies.getJSON("adminData");
  const farmerData = yield Cookies.getJSON("farmerData");
  const serviceProviderData = yield Cookies.getJSON("serviceProviderData");
  adminData && Cookies.remove("adminData");
  farmerData && Cookies.remove("farmerData");
  serviceProviderData && Cookies.remove("serviceProviderData");
  try {
    let response = yield api.post(
      "ServiceProviders/LoginServiceProvider",
      authData
    );
    let data = response.data;
    const adminData = yield Cookies.getJSON("adminData");
    const farmerData = yield Cookies.getJSON("farmerData");
    const partnerData = yield Cookies.getJSON("partnerData");
    adminData && Cookies.remove("adminData");
    farmerData && Cookies.remove("farmerData");
    partnerData && Cookies.remove("partnerData");

    toastr.success(
      "Success",
      `welcome back ${data.firstName ? data.firstName : data.corporateName}`,
      {
        onCloseButtonClick: true,
      }
    );
    Cookies.set("serviceProviderData", data, { expires: 7 });
    yield put(actions.loginSuccess(data));
    history.push("/service-providers");

    // yield localStorage.setItem('eAgricToken', response.data.idToken);
    // yield localStorage.setItem('eAgricUserId', response.data.localId);
    // yield put(actions.loginSuccess(response.data.idToken, response.data.localId));
    // yield put(actions.checkTokenTimeout(response.data.expiresIn))
  } catch (error) {
    // yield put(push('/admin/'));
    toastr.error("Error", "email or password is incorrect", {
      onCloseButtonClick: true,
    });
    yield put(actions.loginFail(error));
    // document.getElementsByClassName("success_close")[0].click();
  }
}

export function* loggedinCheckStateSaga() {
  // const farmerData = yield Cookies.getJSON('farmerData');
  // const adminData = yield Cookies.getJSON('adminData');
  // if (adminData.token) {
  //     history.push('/admin');
  //     return;
  // }else if(farmerData.token){
  //     history.push('/farmer');
  //     return;
  // }else{
  //     yield put(actions.logout());
  //     return;
  // }
  // console.log(farmerToken);
  // const expirationDate = yield new Date(localStorage.getItem('eAgricTokenExpirationDate'));
  // if(expirationDate > new Date()) {
  //     const userId = yield localStorage.getItem('eAgricUserId');
  //     yield put(actions.loginSuccess(token, userId));
  //     yield put(actions.checkTokenTimeout((expirationDate.getTime() - new Date().getTime())/1000))
  // }else{
  //     yield put(actions.logout());
  // }
}

export function* checkTokenTimeoutSaga(action) {
  yield delay(action.expirationTime * 1200);
  yield put(actions.logout());
}

export function* logoutSaga(action) {
  Cookies.remove("farmerData");
  Cookies.remove("partnerData");
  Cookies.remove("adminData");
  Cookies.remove("serviceProviderData");
  Cookies.remove("superAdminData");
  history.push("/");
  // yield localStorage.removeItem('eAgricToken');
  // yield localStorage.removeItem('eAgricTokenExpirationDate');
  // yield localStorage.removeItem('eAgricUserId');
  yield put(actions.logoutSuccess());
}
