/** @format */

import { PAYMENT_CONSTANTS } from "./actionTypes";

export const payCategory = () => {
  return {
    type: PAYMENT_CONSTANTS.GET_ALL_PAYMENTCAT,
  };
};
export const payCategorySucess = (data) => {
  return {
    type: PAYMENT_CONSTANTS.GET_ALL_PAYMENTCAT_SUCCESS,
    payload: data,
  };
};
export const payCategoryFail = (error) => {
  return {
    type: PAYMENT_CONSTANTS.GET_ALL_PAYMENTCAT_FAIL,
    error: error,
  };
};
