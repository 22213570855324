/**
 * /* eslint-disable react/no-multi-comp
 *
 * @format
 */

/* eslint-disable react/display-name */
// import React from 'react';
// import { colors } from '@material-ui/core';
import BarChartIcon from "@material-ui/icons/BarChart";
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
// import ChatIcon from '@material-ui/icons/ChatOutlined';
// import CodeIcon from '@material-ui/icons/Code';
// import DashboardIcon from '@material-ui/icons/DashboardOutlined';
// import ErrorIcon from '@material-ui/icons/ErrorOutline';
// import FolderIcon from '@material-ui/icons/FolderOutlined';
import HomeIcon from "@material-ui/icons/HomeOutlined";
// import ListAltIcon from '@material-ui/icons/ListAlt';
// import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
// import MailIcon from '@material-ui/icons/MailOutlined';
// import PresentToAllIcon from "@material-ui/icons/PresentToAll";
// import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import PersonIcon from "@material-ui/icons/PersonOutlined";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import CreditCardIcon from "@mui/icons-material/CreditCard";

// import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AccessibleIcon from "@material-ui/icons/Accessible";

// import { Label } from '../../../../components/Admin';
// import {label} from '../../../../components/Farmer';

export default [
  {
    title: "Pages",
    pages: [
      {
        title: "Home",
        href: "/farmer",
        icon: HomeIcon,
      },
      {
        title: "User Access",
        href: "/farmer/user-Access",
        icon: PersonIcon,
        children: [
          {
            title: "My Profile",
            href: "/farmer/my-Profile",
          },
          {
            title: "Change Password",
            href: "/farmer/change-Password",
          },
        ],
      },
      {
        title: "Payments",
        href: "/farmer/payments",
        icon: CreditCardIcon,
        children: [
          {
            title: "Member Dues",
            href: "/farmer/member-dues",
          },
        ],
      },
      {
        title: "My Farmland",
        href: "/farmer/my-farmland",
        icon: BarChartIcon,
        children: [
          {
            title: "My Allocation",
            href: "/farmer/my-farmland",
          },

          {
            title: "Farming Activities",
            href: "/farmer/my-farming-activities",
          },
          {
            title: "Crop Plan",
            href: "/farmer/crop-plan",
          },
        ],
      },
      {
        title: "My Request",
        href: "/farmer/labour-mangement",
        icon: TransferWithinAStationIcon,
        children: [
          {
            title: "Allocation",
            href: "/farmer/request-farmland",
          },
          // {
          //   title: "Track & Monitor Field Worker",
          //   href: "/farmer/track-&-monitor-field-worker",
          // },
          {
            title: "Tools",
            href: "/farmer/tools-request",
          },
          {
            title: "Labour",
            href: "/farmer/labour-request",
          },
          {
            title: "Services",
            href: "/farmer/service-request",
          },
        ],
      },

      // {
      //   title: "Crop Management",
      //   href: "/farmer/crop-management",
      //   icon: ViewModuleIcon,
      //   children: [
      //     {
      //       title: "Pre Planting",
      //       href: "/farmer/pre-planting",
      //     },
      //     {
      //       title: "Planting",
      //       href: "/farmer/planting",
      //       // icon: AccountBalanceIcon,
      //       children: [
      //         {
      //           title: "My Crop",
      //           href: "/farmer/my-crop",
      //         },
      //       ],
      //     },
      //     {
      //       title: "Crop Plan",
      //       href: "/farmer/crop-plan",
      //     },
      //     {
      //       title: "Track & Measure Field Activities",
      //       href: "/farmer/track-&-measure-field-activites",
      //     },
      //   ],
      // },

      // {
      //   title: "Accounting",
      //   href: "/farmer/accounting",
      //   icon: AccountBalanceIcon,
      //   children: [
      //     {
      //       title: "Forecast & Measure Profit",
      //       href: "/farmer/forecast-&-measure-profit",
      //     },
      //     {
      //       title: "Management Risk Portfolio",
      //       href: "/farmer/management-risk-portfolio",
      //     },
      //   ],
      // },
    ],
  },
];
