/** @format */

import React, { Fragment, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Drawer, Divider, Paper, Avatar, Typography } from "@material-ui/core";
import { Hidden } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import useRouter from "../../../../utils/useRouter";
import { Navigation } from "../../../../components/Admin";
import navigationConfig from "./navigationConfig";
// import api from "middlewares/axiosConfig";
// import api from "../../../../middlewares/axiosConfig";
import api from "../../../../middlewares/axiosConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    height: "calc(100vh - 64px)",
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
    "& *:focus": {
      outline: "none",
    },
  },
}));

const NavBar = (props) => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();
  const session = useSelector((state) => state.adminReducer.user);
  const superAdminState = useSelector((state) => state.loginReducer.farmerData);
  const farmerData = useSelector((state) => state.loginReducer.farmerData);
  console.log(superAdminState.roleId);

  const [pages, setPages] = useState({ title: "", pages: [] });

  let homePage = {
    title: "Home",
    href: "/super_admin",
    icon: HomeIcon,
  };

  useEffect(async () => {
    if (superAdminState.roleId === undefined) {
      return null;
    } else {
      let pages = await api.get(
        `SuperAdmin/GetSuperAdminSideBar/${superAdminState.roleId}`
      );
      let pagesReturned = pages.data.map((p) => {
        return {
          title: p.menus.menu,
          href: p.menus.menu.replace(/\s/g, ""),

          children: p.menuPages.map((pa) => {
            return {
              title: pa.pageName,
              href: pa.pageDescription,
            };
          }),
        };
      });
      pagesReturned.unshift(homePage);
      setPages({ title: "Pages", pages: [{ pages: pagesReturned }] });
    }
  }, [superAdminState.roleId]);

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  const navbarContent = (
    <div className={classes.content}>
      <div className={classes.profile}>
        <Avatar
          alt="Person"
          className={classes.avatar}
          src="/images/userPlaceHolder.jpg"
        />
        <Typography className={classes.name} variant="h4">
          {farmerData.firstName} {farmerData.lastName}
        </Typography>
        <Typography variant="body2">{farmerData.role}</Typography>
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {pages.pages.map((list, i) => (
          <Navigation
            component="div"
            key={i}
            pages={list.pages}
            title={list.title}
          />
        ))}
        {/* {navigationConfig.map((list) => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />
        ))} */}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
