/** @format */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Card, CardHeader, CardContent, Divider } from "@material-ui/core";
import clsx from "clsx";
import InputBase from "@material-ui/core/InputBase";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import { makeStyles, withStyles } from "@material-ui/styles";
import api from "../../../../../middlewares/axiosConfig";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      marginBottom: "16px",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    marginBottom: "16px",
  },

  content: {
    padding: "0 !important",
    marginTop: "7px",
    paddingBottom: "20px !important",
  },
  titleStyle: {
    fontWeight: 600,
    lineHeight: "30px",
    fontSize: "16px",
    color: "#0e0e0e",
  },
  avatarStyle: {
    border: "1px solid #c5ccc5",
    borderRadius: "5px",
    color: "#4a4c49",
  },
  margin: {
    margin: theme.spacing(3),
  },
}));

const FarmActivitiesChart = () => {
  const classes = useStyles();
  const [farmActivities, SetFarmActivities] = useState([]);
  const FarmerData = useSelector((state) => state.loginReducer.farmerData);

  useEffect(async () => {
    let res = await api.get(
      `FarmerMgt/FarmerFarmingActivitiesStatistics/${FarmerData.individualFarmerId}`
    );

    let data = res.data;
    SetFarmActivities(data);
  }, []);

  return (
    <Card
      className={clsx(classes.root)}
      className="shadow"
      style={{ marginBottom: "16px" }}
    >
      <CardHeader
        title="Farming Activities"
        avatar={<AspectRatioIcon />}
        classes={{ title: classes.titleStyle, avatar: classes.avatarStyle }}
      />
      <Divider />
      <CardContent className={classes.content}>
        <ResponsiveContainer width="100%" aspect={2}>
          <ComposedChart
            width={500}
            height={400}
            data={farmActivities}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="3 3" />
            <XAxis dataKey="farmLand" name="Farm Land" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="numberOfPrePlanting"
              fill="#008000"
              name="Pre-Planting"
            />
            <Bar dataKey="numberOfPlanting" fill="#00FFFF" name="Planting" />
            <Bar
              dataKey="numberOfPostPlanting"
              fill="#32CD32"
              name="Post Planting"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default FarmActivitiesChart;
