/** @format */

import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  // IconButton,
  Box,
  // Typography,
  CircularProgress,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
// import Paper from "@mui/material/Paper";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import Typography from "@material-ui/core/Typography";

// import DialogContentText from "@mui/material/DialogContentText";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
// import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import LoadingOverlay from "react-loading-overlay";
import api from "../../../../middlewares/axiosConfig";
import { toastr } from "react-redux-toastr";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import CssBaseline from "@material-ui/core/CssBaseline";

import { green } from "@material-ui/core/colors";
import CustomizedDialogs from "./successPayment";

const useStyles = makeStyles((theme) => ({
  //   image: {
  //     backgroundImage: "url(" + SetupImg + ")",
  //     backgroundRepeat: "no-repeat",
  //     backgroundColor:
  //       theme.palette.type === "light"
  //         ? theme.palette.grey[50]
  //         : theme.palette.grey[900],
  //     backgroundSize: "cover",
  //     backgroundPosition: "center",
  //   },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  paper: {
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  images: {
    height: "50%",
    padding: "3px",
    margin: theme.spacing(2),
  },
  textFormat: {
    height: "50%",
    padding: "3px",
    margin: theme.spacing(2),
  },
  avatar: {
    marginTop: theme.spacing(1),
    backgroundColor: "#1FA12E",
  },
  form: {
    // display: "flex",
    width: "80%", // Fix IE 11 issue.
    marginTop: theme.spacing(0),
  },

  successButton: {
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  btn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: green[700],
    },
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#1FA12E",
    color: "#fff",
  },
  DialogTitle: {
    "& h2": {
      fontSize: "1.6rem",
      textAlign: "center",
      fontWeight: 700,
      margin: "5px 0 5px 0",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const PaymentDetails = (props) => {
  const classes = useStyles();

  const { closePaymentDialog, payInfo } = props;
  const [loader, setLoader] = useState(false);
  const [opens, setOpens] = useState(false);

  const handleOpen = () => {
    console.log("obi");
    setOpens(true);
  };
  const handleClose = () => {
    setOpens(false);
  };
  useEffect(() => {
    const script = document.createElement("script");

    // script.src = "https://webpay-ui.k8.isw.la/inline-checkout.js";     // Test URL
    // script.src = "https://newwebpay.qa.interswitchng.com/inline-checkout.js";

    script.src = "https://newwebpay.interswitchng.com/inline-checkout.js"; // Live Url
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const currency = function (number) {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const paymentCallback = async (resp) => {
    const acceptedCodes = ["00", "10", "11"];
    if (acceptedCodes.includes(resp.resp)) {
      try {
        await api.post(`/Payment/ConfirmTransaction`, resp).then(
          (res) => {
            toastr.success("Success", res.data.status);
            closePaymentDialog();
            handleOpen();
          },
          (err) => {
            toastr.warning("Warning", err.data.status);
          }
        );
      } catch (e) {
        toastr.error("Error", e.message);
      }
    }
  };

  const callPayNow = () => {
    // eslint-disable-next-line no-restricted-globals
    const redirectUrl = location.href;

    const payNow = {
      merchant_code: payInfo?.data?.merchantCode,
      pay_item_id: payInfo?.data?.paymentItemId,
      txn_ref: payInfo?.data?.transactionRef,
      amount: (payInfo?.data?.amount * 100).toString(),
      currency: 566,
      site_redirect_url: redirectUrl,
      mode: "LIVE",
      split_accounts: `[
        {
          alias: "HOPE_CONCEPT",
          amount: ${
            (0.7 * (payInfo?.data?.amount - payInfo?.data?.interswitchCharge) +
              payInfo?.data?.interswitchCharge) *
            100
          },
          description: "Hope Concept",
          isPrimary: true,
        },
        {
          alias: "SIDMACH_E-Agric",
          amount: ${
            0.3 *
            (payInfo?.data?.amount - payInfo?.data?.interswitchCharge) *
            100
          },
          description: "Hope Concept e-Agric",
        },
      ]`,
      onComplete: paymentCallback,
    };
    closePaymentDialog();
    window.webpayCheckout(payNow);
  };

  return (
    <Fragment>
      <LoadingOverlay active={loader} spinner text="Fetching Data">
        <div className={classes.paper}>
          <DialogTitle
            id="customized-dialog-title"
            className={classes.DialogTitle}
          >
            {`Payment Details`}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => closePaymentDialog()}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container component="main" className={classes.root}>
              <Paper>
                {loader ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignSelf: "center",
                        alignItems: "center",
                        backgroundImage:
                          "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/pat-back.svg')",
                        backgroundPosition: "center",
                        backgroundRepeat: "repeat",
                        backgroundSize: "7%",
                      }}
                    >
                      <div className="container-fluid">
                        <div className="row d-flex justify-content-center align-items-center">
                          <div className="col-12 d-flex py-2 flex-column justify-content-center align-items-center">
                            <div>
                              <img
                                src={
                                  "https://paymentgateway.interswitchgroup.com/paymentgateway/public/images/isw_paynow_btn.png"
                                }
                                alt=""
                                className="img-fluid"
                                max-width="200%"
                                height="auto"
                              />
                            </div>
                            <div className="d-flex justify-content-center align-items-center flex-column">
                              <p>
                                <strong>
                                  Payer Name:{" "}
                                  {payInfo?.firstName +
                                    " " +
                                    payInfo?.middleName +
                                    " " +
                                    payInfo?.lastName}
                                </strong>{" "}
                              </p>
                              <p>
                                <strong>
                                  Payer Email: {payInfo?.emailAddress}
                                </strong>{" "}
                              </p>
                              <p>
                                <strong>
                                  Payer Phone: {payInfo?.phoneNumber}
                                </strong>{" "}
                              </p>
                              <p>
                                <strong>
                                  Total Amount To Pay:
                                  {currency(payInfo?.data?.amount)}
                                </strong>
                              </p>
                              <p>
                                <strong>
                                  Transaction Reference:{" "}
                                  {payInfo?.data?.transactionRef}
                                </strong>
                              </p>
                            </div>
                            <div
                              className="text-center"
                              style={{ color: "#993333" }}
                            >
                              <p>
                                <strong>
                                  Please confirm your information before
                                  proceeding to payment and also note your
                                  Transaction Reference.
                                </strong>
                              </p>
                              <p>
                                <strong>
                                  The transaction Reference is necessary for
                                  future correspondence
                                </strong>
                              </p>
                            </div>
                            <div>
                              <button
                                className="btn text-white"
                                style={{ background: "#4199f6" }}
                                id="interswitchPay"
                                onClick={callPayNow}
                              >
                                Pay Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Paper>
            </Grid>
          </DialogContent>
        </div>
      </LoadingOverlay>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={opens}
        className={classes.Dialog}
        fullWidth={true}
        maxWidth="sm"
      >
        <CustomizedDialogs
          opens={opens}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
      </Dialog>
    </Fragment>
  );
};
export default PaymentDetails;
