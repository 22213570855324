import { put, delay } from 'redux-saga/effects';
import api from '../../middlewares/axiosConfig';
import {toastr} from 'react-redux-toastr'
import Cookies from 'js-cookie';

import * as actions from '../actions';

export function* getFarmsSaga() { 
    try{
        yield put(actions.getFarmsStart());
        const response = yield api.get('farm/getallfarm');
        let farms = response.data;
        yield put(actions.getFarmsSuccess(farms));
    }catch(error){
        toastr.error("Error", "An error occured while trying to get farms", {
            onCloseButtonClick: true
        });
        yield put(actions.getFarmsFail(error));
    }
}


export function* createFarmSaga(data) {
    try{
        yield put(actions.createTownStarts());
        let response = yield api.post(`SetUp/CreateTown`, data.town);
        toastr.success( `Success`, response.data.message, {
            closeButton: true,
        });
        yield put(actions.createTownSuccess());
        yield put(actions.getTowns());
    }catch(error){
        toastr.error("Error", "An error occured while creating town", {
            onCloseButtonClick: true
        });
        yield put(actions.createTownFails(error));
    }
}

export function* editFarmSaga(data) {
    try{
        yield put(actions.getTownStarts());
        let response = yield api.get(`SetUp/GetTownById/${data.townId}`);
        yield put(actions.getTownSuccess(response.data));
    }catch(error){
        toastr.error("Error", "An error occured while getting town", {
            onCloseButtonClick: true
        });
        yield put(actions.getTownFails(error));
    }
}

export function* editFarmStartsSaga(data) {
    try{
        let response = yield api.patch(`SetUp/UpdateTown/${data.townData.townId}`, data.townData);
        yield put(actions.editTownSuccess());
        toastr.success( `Success`, response.data.message, {
            closeButton: true,
        });
        yield put(actions.getTowns());
    }catch(error){
        toastr.error("Error", "An error occured while editing town", {
            onCloseButtonClick: true
        });
        yield put(actions.editTownFails(error));
    }
}

export function* deleteFarmSaga(farmData) {
    try{
        let response = yield api.delete(`Farm/${farmData.farmId}`);
        if(response.data.isSuccess){
            toastr.success( `Success`, response.data.message, {
                closeButton: true,
            });
        }else{
            toastr.warning( `Error`, response.data.message, {
                closeButton: true,
            });
        }
        yield put(actions.getFarms());
    }catch(error){
        toastr.error("Error", "An error occured deleting farm", {
            onCloseButtonClick: true
        });
    }
}