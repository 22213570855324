/** @format */

import { SUPER_ADMIN } from "../actions/actionTypes";

const initialState = {
  superAdmin: {
    email: "",
    firstName: "",
    id: 0,
    lastName: "",
    role: "",
    roleId: 0,
    token: "",
  },
  detailedUsers: [],
  loggedout: false,
};

const superAdminReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case SUPER_ADMIN.SET_AUTH:
      return { ...state, superAdmin: { ...action.payload }, loggedout: false };
    case SUPER_ADMIN.SET_DETAILED_USERS:
      return { ...state, detailedUsers: [...action.payload] };
    case SUPER_ADMIN.DELETE_AUTH:
      return {
        ...state,
        superAdmin: {
          email: "",
          firstName: "",
          id: 0,
          lastName: "",
          role: "",
          roleId: 0,
          token: "",
        },
        loggedout: true,
      };
    default:
      return state;
  }
};

export default superAdminReducer;
