/** @format */

export const states = [
  { key: "abia", color: "#389483" },
  { key: "adamawa", color: "#29384F" },
  { key: "akwaIbom", color: "#92f10d" },
  //     "anambra",
  // "bauchi",
  // "bayelsa",
  // "benue",
  // "borno",
  // "crossRiver",
  // "delta",
  // "ebonyi",
  { key: "edo", color: "#8492ce" },
  // "ekiti",
  // "enugu",
  // "fct",
  // "gombe",
  // "imo",
  // "jigawa",
  // "kaduna",
  // "kano",
  // "katsina",
  // "kebbi",
  // "kogi",
  // "kwara",
  // "lagos",
  // "nassarawa",
  // "niger",
  { key: "ogun", color: "#FEC009" },
  // "ondo",
  // "osun",
  // "oyo",
  // "plateau",
  // "rivers",
  // "sokoto",
  // "taraba",
  // "yobe",
  // "zamfara"
];
