/** @format */

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { green } from "@material-ui/core/colors";
import { toastr } from "react-redux-toastr";
import api from "../../../../middlewares/axiosConfig";
import validators from "../../../../middlewares/validators";
import { useSelector } from "react-redux";
import { data } from "jquery";

// form that validates all field inputs

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    width: "100%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    marginTop: "1rem",
    minWidth: "300px",
  },
  header: {
    "& h2": {
      fontSize: "1.5rem",
    },
  },
  headerDiv: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "2rem",
  },
  headerButton: {
    outline: "none",
    "&:focus": {
      outline: "none",
    },
    background: "#2E7D32",
    color: "#fff",
    "&:hover": {
      background: "#fff",
      color: "#2E7D32",
      outline: "#2E7D32 solid 1px",
    },
  },
  Dialog: {
    "& *:focus": {
      outline: "none",
    },
  },
  gridContainer: {
    "& > div": {
      padding: ".2rem",
      width: "70rem",
    },
  },

  DialogTitle: {
    "& h2": {
      fontSize: "1.5rem",
    },
  },
  successButton: {
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  headerText: {
    marginLeft: "6%",
  },
  none: {
    display: "none",
  },
  block: {
    display: "block",
  },
  image: {
    width: "100%",
  },
}));

const initialStates = {
  farmingOperationIdError: false,
  farmingOperationIdErrorMsg: "",
  operationNameError: false,
  operationNameErrorMsg: "",

  seasonDetailsIdError: false,
  seasonDetailsIdErrorMsg: "",
  seasonNameError: false,
  seasonNameErrorMsg: "",

  procedureNameError: false,
  procedureNameErrorMsg: "",
  farmingProcedureIdError: false,
  farmingProcedureIdErrorMsg: "",

  soilTypeIdError: false,
  soilTypeIdErrorMsg: "",
  soilNameError: false,
  soilNameErrorMsg: "",

  farmCellIdError: false,
  farmCellIdErrorMsg: "",
  farmCellNameError: false,
  farmCellNameErrorMsg: "",

  farmingProcedureStatusIdError: false,
  farmingProcedureStatusIdErrorMsg: "",
  statusNameError: false,
  statusNameErrorMsg: "",

  plantGrowthStageIdError: false,
  plantGrowthStageIdErrorMsg: "",
  growthStageNameError: false,
  growthStageNameErrorMsg: "",
  remarkError: false,
  remarkErrorMsg: "",
  formName: "Add Activity",
  buttonText: "Save",
};

const initialFormValues = {
  individualFarmerId: 0,
  farmingOperationId: 0,
  operationName: "",
  seasonName: "",
  farmerName: "",
  farmCellName: "",
  seasonDetailsId: 0,
  farmingProcedureId: 0,
  procedureName: "",
  statusName: "",
  // soilTypeId: 0,
  farmCellId: 0,
  plantGrowthStageId: 0,
  growthStageName: "",
  farmingActivityStageId: 0,
  remark: "",
};

function FarmForm(props) {
  const classes = useStyles();
  const [formStates, setFormStates] = useState({ ...initialStates });
  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const [seasonDetailsId, setSeasonDetailsId] = useState(0);
  const [seasonName, setSeasonName] = useState([]);

  const [farmingOperationId, setFarmingOperationId] = useState(0);
  const [operationName, setOperationName] = useState([]);

  const [farmingProcedureId, setFarmingProcedureId] = useState(0);
  const [procedureName, setProcedureName] = useState([]);
  const [farmingProcedureStatusId, setFarmingProcedureStatusId] = useState(0);
  const [statusName, setStatusName] = useState([]);
  // const [soilTypeId, setSoilTypeId] = useState(0);
  const [soilName, setSoilName] = useState([]);
  const [farmCellId, setFarmCellId] = useState(0);
  const [farmCellName, setFarmCellName] = useState([]);
  const [plantGrowthStageId, setPlantGrowthStageId] = useState(0);
  const [growthStageName, setGrowthStageName] = useState([]);
  const { individualFarmerId } = useSelector(
    (state) => state.loginReducer.farmerData
  );
  const [farmingActivityStageId, setFarmingActivityStageId] = useState(0);

  // api that displays the farming operations
  useEffect(async () => {
    let activities = await api.get(
      `FarmingActivityStages/GetAllFarmingOperations`
    );
    setOperationName(activities.data);
  }, []);

  useEffect(() => {
    formValues.farmCellId = props.farmCellIds;
  }, [props.farmCellIds]);

  //api that displays farmcell for a farmer on login
  useEffect(async () => {
    let category = await api.get(
      `FarmerMgt/GetFarmCellsAllocated/${individualFarmerId}`
    );
    setFarmCellName(category.data);
  }, []);

  useEffect(() => {
    farmCellName.forEach((farmCell) => {
      if (+farmCell.farmCellId === +formValues.farmCellId) {
        setSoilName(farmCell.soilName);
      }
    });
  }, [formValues.farmCellId]);

  //api that displays the seasonDetails
  useEffect(async () => {
    let farmSeason = await api.get(`farmingactivitystages/getcurrentseason`);
    setSeasonName([
      farmSeason.data.seasonDetailsId,
      farmSeason.data.seasonName,
    ]);
  }, []);

  // api that displays the procedures
  useEffect(async () => {
    if (farmingOperationId !== 0) {
      let farmingAct = await api.get(
        `FarmingActivityStages/GetFarmingProceduresByFarmingOperationId/${farmingOperationId}`
      );
      setProcedureName(farmingAct.data);
    }
  }, [farmingOperationId]);

  useEffect(async () => {
    if (formValues.farmingOperationId !== 0) {
      let farmingAct = await api.get(
        `FarmingActivityStages/GetFarmingProceduresByFarmingOperationId/${formValues.farmingOperationId}`
      );
      setProcedureName(farmingAct.data);
    }
  }, [formValues.farmingOperationId]);

  //api that displays the list of statuses
  useEffect(async () => {
    let farmStatus = await api.get(
      `FarmingActivityStages/GetAllFarmingProcedureStatuses`
    );
    setStatusName(farmStatus.data);
  }, []);

  //api that displays all soiltypes
  // useEffect(async () => {
  //     let category = await api.get(`FarmingActivityStages/GetAllSoilType`);
  //     setSoilName(category.data);
  // }, []);

  // api that displays the list of crop growth stages
  useEffect(async () => {
    let status = await api.get(`farmingactivitystages/getallplantgrowthstage`);
    setGrowthStageName(status.data);
  }, []);

  const setIsRequiredError = (
    value,
    stateError,
    stateErrorMsg,
    len = 1,
    errorMsg = "Field is required",
    max = 0,
    select = false
  ) => {
    if (!validators.isRequired(value, len, max, select)) {
      setFormStates({
        ...formStates,
        [stateError]: true,
        [stateErrorMsg]: errorMsg,
      });
      return false;
    }

    setFormStates({
      ...initialStates,
      [stateError]: false,
      [stateErrorMsg]: "",
    });
    return true;
  };

  function farmCellIdHandler(e) {
    if (e) {
      // debugger;
      e.preventDefault();
      setFormValues({
        ...formValues,
        setFarmCellId: e.target.value,
      });
      return setIsRequiredError(
        Number.parseInt(e.target.value, 10),
        "farmCellIdError",
        "farmCellIdErrorMsg",
        undefined,
        undefined,
        undefined,
        true
      );
    } else {
      let farmCellIdValue = parseInt(formValues.farmCellId);
      return setIsRequiredError(
        Number.parseInt(farmCellIdValue, 10),
        "farmCellIdError",
        "farmCellIdErrorMsg",
        undefined,
        undefined,
        undefined,
        true
      );
    }
  }

  // SOIL TYPE BUTTON FUNCTION
  function soilTypeIdHandler(e) {
    if (e) {
      // debugger;
      let soilTypeIdValue = document.getElementById("soilTypeId").value;
      e.preventDefault();
      // setSoilTypeId(e.target.value);
      setFormValues({
        ...formValues,
        soilTypeId: Number.parseInt(soilTypeIdValue, 10),
      });
      return setIsRequiredError(
        Number.parseInt(soilTypeIdValue, 10),
        "soilTypeIdError",
        "soilTypeIdErrorMsg",
        undefined,
        undefined,
        undefined,
        true
      );
    } else {
      let soilTypeIdValue = parseInt(
        document.getElementById("soilTypeId").value.trim()
      );
      return setIsRequiredError(
        Number.parseInt(soilTypeIdValue, 10),
        "soilTypeIdError",
        "soilTypeIdErrorMsg",
        undefined,
        undefined,
        undefined,
        true
      );
    }
  }

  // season handler validation
  function seasonHandler(e) {
    if (e) {
      let seasonDetailsIdValue =
        document.getElementById("seasonDetailsId").value;
      e.preventDefault();
      // setSeasonDetailsId(e.target.value);
      setFormValues({
        ...formValues,
        seasonDetailsId: Number.parseInt(seasonDetailsIdValue, 10),
      });
      return setIsRequiredError(
        Number.parseInt(seasonDetailsIdValue, 10),
        "seasonDetailsIdError",
        "seasonDetailsIdErrorMsg",
        undefined,
        undefined,
        undefined,
        true
      );
    } else {
      let seasonDetailsIdValue = parseInt(
        document.getElementById("seasonDetailsId").value.trim()
      );
      return setIsRequiredError(
        Number.parseInt(seasonDetailsIdValue, 10),
        "seasonDetailsIdError",
        "seasonDetailsIdErrorMsg",
        undefined,
        undefined,
        undefined,
        true
      );
    }
  }

  //operations handler validation
  function operationsHandler(e) {
    if (e) {
      let farmingOperationIdValue =
        document.getElementById("farmingOperationId").value;
      e.preventDefault();
      setFarmingOperationId(e.target.value);
      setFormValues({
        ...formValues,
        farmingOperationId: Number.parseInt(farmingOperationIdValue, 10),
      });
      return setIsRequiredError(
        Number.parseInt(farmingOperationIdValue, 10),
        "farmingOperationIdIdError",
        "farmingOperationIdIdErrorMsg",
        undefined,
        undefined,
        undefined,
        true
      );
    } else {
      let farmingOperationIdValue = parseInt(
        document.getElementById("farmingOperationId").value.trim()
      );
      return setIsRequiredError(
        Number.parseInt(farmingOperationIdValue, 10),
        "farmingOperationIdIdError",
        "farmingOperationIdIdErrorMsg",
        undefined,
        undefined,
        undefined,
        true
      );
    }
  }

  //procedure handler validation
  function procedureHandler(e) {
    if (e) {
      let farmingProcedureIdValue =
        document.getElementById("farmingProcedureId").value;
      e.preventDefault();
      setFormValues({
        ...formValues,
        farmingProcedureId: Number.parseInt(farmingProcedureIdValue, 10),
      });
      setFarmingProcedureId(e.target.value);
      setFormValues({
        ...formValues,
        farmingProcedureId: Number.parseInt(farmingProcedureIdValue, 10),
      });
      return setIsRequiredError(
        Number.parseInt(farmingProcedureIdValue, 10),
        "farmingProcedureIdError",
        "farmingProcedureIdErrorMsg",
        undefined,
        undefined,
        undefined,
        true
      );
    } else {
      let farmingProcedureIdValue = parseInt(
        document.getElementById("farmingProcedureId").value.trim()
      );
      return setIsRequiredError(
        Number.parseInt(farmingProcedureIdValue, 10),
        "farmingProcedureIdError",
        "farmingProcedureIdErrorMsg",
        undefined,
        undefined,
        undefined,
        true
      );
    }
  }

  //farming operation status  validation
  function farmingProcedureStatusIdHandler(e) {
    if (e) {
      let farmingProcedureStatusIdValue = document.getElementById(
        "farmingProcedureStatusId"
      ).value;
      e.preventDefault();
      setFarmingProcedureStatusId(e.target.value);
      setFormValues({
        ...formValues,
        farmingProcedureStatusId: Number.parseInt(
          farmingProcedureStatusIdValue,
          10
        ),
      });
      return setIsRequiredError(
        Number.parseInt(farmingProcedureStatusIdValue, 10),
        "farmingProcedureStatusIdError",
        "farmingProcedureStatusIdErrorMsg",
        undefined,
        undefined,
        undefined,
        true
      );
    } else {
      let farmingProcedureStatusIdValue = parseInt(
        document.getElementById("farmingProcedureStatusId").value.trim()
      );
      return setIsRequiredError(
        Number.parseInt(farmingProcedureStatusIdValue, 10),
        "farmingProcedureStatusIdError",
        "farmingProcedureStatusIdErrorMsg",
        undefined,
        undefined,
        undefined,
        true
      );
    }
  }

  const remarkHandler = (e) => {
    if (e) {
      let remarkValue = document.getElementById("remark").value;
      e.preventDefault();
      setFormValues({ ...formValues, remark: remarkValue });

      return setIsRequiredError(remarkValue, "remarkError", "remarkErrorMsg");
    } else {
      let remarkValue = document.getElementById("remark").value.trim();
      return setIsRequiredError(remarkValue, "remarkError", "remarkErrorMsg");
    }
  };
  //plant growth stage validation
  function plantGrowthStageIdHandler(e) {
    if (e) {
      let plantGrowthStageIdValue =
        document.getElementById("plantGrowthStageId").value;
      e.preventDefault();
      setPlantGrowthStageId(e.target.value);
      setFormValues({
        ...formValues,
        plantGrowthStageId: Number.parseInt(plantGrowthStageIdValue, 10),
      });
      return setIsRequiredError(
        Number.parseInt(plantGrowthStageIdValue, 10),
        "plantGrowthStageIdError",
        "plantGrowthStageIdErrorMsg",
        undefined,
        undefined,
        undefined,
        true
      );
    } else {
      let plantGrowthStageIdValue = parseInt(
        document.getElementById("plantGrowthStageId").value.trim()
      );
      return setIsRequiredError(
        Number.parseInt(plantGrowthStageIdValue, 10),
        "plantGrowthStageIdError",
        "plantGrowthStageIdErrorMsg",
        undefined,
        undefined,
        undefined,
        true
      );
    }
  }

  function closeForm() {
    resetForm();
    props.handleClose();
  }
  //api for add activities
  async function createActivities(data) {
    try {
      let response = await api.post(
        "FarmingActivityStages/CreateNewFarmingActivityStage",
        data
      );
      if (response.data.isSuccess) {
        toastr.success("Success", response.data.message);
        setFormStates({
          ...formStates,
          buttonState: false,
          buttonText: "Save",
        });

        closeForm();
        resetForm();
        props.viewFarmDetails();
      } else {
        toastr.warning("Warniing", response.data.message, {
          closeButton: true,
          timeOut: 4000,
        });
      }
    } catch (e) {
      toastr.error("Error", "an error occured");
      if (e.response !== undefined) {
        toastr.error("Error", e.response.data.message, {
          closeButton: true,
          timeOut: 400,
        });
        setFormStates({
          ...formStates,
          buttonState: false,
          buttonText: "Save",
        });
      }
    }
  }

  useEffect(() => {
    let farmingActivityStageId = props.farmingActivityStageId;
    if (farmingActivityStageId > 0) {
      setFormValues({ ...formValues, formName: "View Activities" });
      getActivitiesFromDB(farmingActivityStageId);
    }
  }, [props.farmingActivityStageId]);

  // api call for the population of datas
  function getActivitiesFromDB(id) {
    // debugger;
    api
      .get("FarmingActivityStages/GetFarmingActivityStageById/" + id)
      .then((resp) => {
        let data = resp.data;
        setFormValues({
          ...formValues,
          seasonDetailsId: data.seasonName[1],
          remark: data.remark,
          plantGrowthStageId: data.plantGrowthStageId,
          soilTypeId: +data.soilTypeId,
          farmCellId: +data.farmCellId,
          farmingProcedureId: +data.farmingProcedureId,
          farmingOperationId: +data.farmingOperationId,
          farmingActivityStageId: +data.farmingActivityStageId,
          farmingProcedureStatusId: +data.farmingProcedureStatusId,
        });

        setFormStates({
          ...formStates,
          buttonText: "Save",
          formName: "Update Activities",
        });
      })
      .catch((err) => {
        toastr.error("Error", err.data, { showCloseButton: true });
        props.handleClose();
      });
  }

  // api call for edit
  function editActivity(newData) {
    api
      .patch(
        `FarmingActivityStages/UpdateFarmingActivityStage/${props.farmingActivityStageId}`,
        newData
      )
      .then((res) => {
        toastr.success(`Success`, `Successfully updated an Activity`, {
          closeButton: true,
        });

        closeForm();
        props.viewFarmDetails();
      })
      .catch((err) => {
        let message = err.data.message;

        toastr.error(`Error`, message, {
          closeButton: true,
          timeOut: 4000,
        });
        setFormStates({
          ...formStates,
          buttonState: false,
          buttonText: "Save",
        });
      });
  }

  //reseting the fields to be empty after submission
  function resetForm() {
    setFormValues({ ...initialFormValues });
    setFormStates({
      ...formStates,
      buttonState: false,
      formName: "Add Activity",
      buttonText: "Save",
    });
  }

  //save button functionality
  function sumbitActivities() {
    if (
      +formValues.farmingOperationId === 1 ||
      +formValues.farmingOperationId === 3
    ) {
      if (
        !farmCellIdHandler() ||
        !soilTypeIdHandler() ||
        !seasonHandler() ||
        !operationsHandler() ||
        !procedureHandler() ||
        !farmingProcedureStatusIdHandler()
      ) {
        return;
      }
    } else if (
      +formValues.farmingOperationId !== 1 ||
      +formValues.farmingOperationId !== 3
    ) {
      if (
        !farmCellIdHandler() ||
        !soilTypeIdHandler() ||
        !seasonHandler() ||
        !operationsHandler() ||
        !procedureHandler() ||
        !farmingProcedureStatusIdHandler() ||
        !plantGrowthStageIdHandler()
      ) {
        return;
      }
    }
    // debugger;

    setFormStates({
      ...formStates,
      buttonState: true,
      buttonText: "...loading",
    });

    if (props.farmingActivityStageId > 0) {
      let newData = {
        farmingActivityStageId: props.farmingActivityStageId,
        farmingProcedureStatusId: formValues.farmingProcedureStatusId,
        plantGrowthStageId: formValues.plantGrowthStageId,
        remark: formValues.remark,
      };
      editActivity(newData);
    } else {
      let data = {
        individualFarmerId: individualFarmerId,
        farmCellId: +formValues.farmCellId,
        soilTypeId: formValues.soilTypeId,
        farmingProcedureId: formValues.farmingProcedureId,
        farmingProcedureStatusId: formValues.farmingProcedureStatusId,
        seasonDetailsId: seasonName[0],
        remark: formValues.remark,
        plantGrowthStageId: formValues.plantGrowthStageId,
      };
      createActivities(data);
    }
  }

  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className={classes.Dialog}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.DialogTitle}
        >
          {props.formMode === "create" ? "Add Activity" : "Update Activity"}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeForm}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {props.formMode === "create" ? (
            <Grid container>
              <Grid item md={4} className={classes.headerText}>
                <TextField
                  variant="outlined"
                  label="* Season Details"
                  fullWidth={true}
                  id="seasonDetailsId"
                  error={formStates.seasonDetailsIdError}
                  onChange={seasonHandler}
                  helperText={formStates.seasonDetailsIdErrorMsg}
                  value={seasonName[1]}
                ></TextField>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item md={4} className={classes.headerText}>
                <TextField
                  disabled
                  variant="outlined"
                  label="* Season Details"
                  fullWidth={true}
                  id="seasonDetailsId"
                  error={formStates.seasonDetailsIdError}
                  onChange={seasonHandler}
                  helperText={formStates.seasonDetailsIdErrorMsg}
                  value={seasonName[1]}
                ></TextField>
              </Grid>
            </Grid>
          )}

          {props.formMode === "create" ? (
            <div className="mt-5 mx-5">
              {+formValues.farmingOperationId === 1 ||
              +formValues.farmingOperationId === 3 ? (
                <Grid container className={classes.gridContainer}>
                  <Grid item xs={props.haspicture ? 8 : 12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          label="* Farm Cells"
                          fullWidth={true}
                          id="farmCellId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.farmCellIdError}
                          onChange={farmCellIdHandler}
                          helperText={formStates.farmCellIdErrorMsg}
                          value={formValues.farmCellId}
                        >
                          <option value="0"> Select</option>
                          {farmCellName.map((item, index) => (
                            <option key={index} value={item.farmCellId}>
                              {item.farmCellName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          label="* Soil Type"
                          fullWidth={true}
                          id="soilTypeId"
                          // select
                          // SelectProps={{
                          //     native: true,
                          // }}
                          error={formStates.soilTypeIdError}
                          onChange={farmCellIdHandler}
                          helperText={formStates.soilTypeIdErrorMsg}
                          value={soilName}
                        >
                          {/* {farmCellName.map(
                                                        (item, index) => (
                                                            <option
                                                                key={index}
                                                                value={
                                                                    item.soilTypeId
                                                                }
                                                            >
                                                                {item.soilName}
                                                            </option>
                                                        )
                                                    )} */}
                        </TextField>
                      </Grid>

                      <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          variant="outlined"
                          label="* Opearations"
                          fullWidth={true}
                          id="farmingOperationId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.farmingOperationIdError}
                          onChange={operationsHandler}
                          helperText={formStates.farmingOperationIdErrorMsg}
                          value={formValues.farmingOperationId}
                        >
                          <option value="0">Select</option>
                          {operationName.map((item, index) => (
                            <option key={index} value={item.farmingOperationId}>
                              {item.operationName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      {formValues.farmingOperationId != 0 &&
                      formValues.farmingOperationId != "" ? ( //  a logic to disable to procedure field untill the operation field is clicked /selected
                        <Grid item xs={12} sm={8} md={6}>
                          <TextField
                            variant="outlined"
                            label="* Procedures"
                            fullWidth={true}
                            id="farmingProcedureId"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            error={formStates.farmingProcedureIdError}
                            onChange={procedureHandler}
                            helperText={formStates.farmingProcedureIdErrorMsg}
                            value={farmingProcedureId}
                          >
                            <option value="0">Select</option>
                            {procedureName.map((item, index) => (
                              <option
                                key={index}
                                value={item.farmingProcedureId}
                              >
                                {item.procedureName}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      ) : (
                        //logic to display all fields including growth stage field on click of planting and post planting with farmingoperation id 2 and 3
                        <Grid item xs={12} sm={8} md={6}>
                          <TextField
                            disabled
                            variant="outlined"
                            label="*Procedures"
                            fullWidth={true}
                            id="farmingProcedureId"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            error={formStates.farmingProcedureIdError}
                            onChange={procedureHandler}
                            helperText={formStates.farmingProcedureIdErrorMsg}
                            value={formValues.farmingProcedureId}
                          >
                            <option value="0">Select</option>
                            {procedureName.map((item, index) => (
                              <option
                                key={index}
                                value={item.farmingProcedureId}
                              >
                                {item.procedureName}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          variant="outlined"
                          label="* Status"
                          fullWidth={true}
                          id="farmingProcedureStatusId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.farmingProcedureStatusIdError}
                          onChange={farmingProcedureStatusIdHandler}
                          helperText={
                            formStates.farmingProcedureStatusIdErrorMsg
                          }
                          value={formValues.farmingProcedureStatusId}
                        >
                          <option value="0">Select</option>
                          {statusName.map((item, index) => (
                            <option
                              key={index}
                              value={item.farmingProcedureStatusId}
                            >
                              {item.statusName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          variant="outlined"
                          multiline
                          fullWidth={true}
                          label="* Remark"
                          id="remark"
                          error={formStates.remarkError}
                          onChange={remarkHandler}
                          helperText={formStates.remarkErrorMsg}
                          value={formValues.remark}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid container className={classes.gridContainer}>
                  <Grid item xs={props.haspicture ? 8 : 12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          label="* Farm Cells"
                          fullWidth={true}
                          id="farmCellId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.farmCellIdError}
                          onChange={farmCellIdHandler}
                          helperText={formStates.farmCellIdErrorMsg}
                          value={formValues.farmCellId}
                        >
                          <option value="0"> Select</option>
                          {farmCellName.map((item, index) => (
                            <option key={index} value={item.farmCellId}>
                              {item.farmCellName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          label="* Soil Type"
                          fullWidth={true}
                          id="soilTypeId"
                          error={formStates.soilTypeIdError}
                          onChange={farmCellIdHandler}
                          helperText={formStates.soilTypeIdErrorMsg}
                          value={soilName}
                        ></TextField>
                      </Grid>

                      <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          variant="outlined"
                          label="* Opearations"
                          fullWidth={true}
                          id="farmingOperationId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.farmingOperationIdError}
                          onChange={operationsHandler}
                          helperText={formStates.farmingOperationIdErrorMsg}
                          value={formValues.farmingOperationId}
                        >
                          <option value="0">Select</option>
                          {operationName.map((item, index) => (
                            <option key={index} value={item.farmingOperationId}>
                              {item.operationName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      {formValues.farmingOperationId != 0 &&
                      formValues.farmingOperationId != "" ? ( //  a logic to disable to procedure field untill the operation field is clicked /selected
                        <Grid item xs={12} sm={8} md={6}>
                          <TextField
                            variant="outlined"
                            label="* Procedures"
                            fullWidth={true}
                            id="farmingProcedureId"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            error={formStates.farmingProcedureIdError}
                            onChange={procedureHandler}
                            helperText={formStates.farmingProcedureIdErrorMsg}
                            value={farmingProcedureId}
                          >
                            <option value="0">Select</option>
                            {procedureName.map((item, index) => (
                              <option
                                key={index}
                                value={item.farmingProcedureId}
                              >
                                {item.procedureName}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      ) : (
                        //logic to display all fields including growth stage field on click of planting and post planting with farmingoperation id 2 and 3
                        <Grid item xs={12} sm={8} md={6}>
                          <TextField
                            disabled
                            variant="outlined"
                            label="*Procedures"
                            fullWidth={true}
                            id="farmingProcedureId"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            error={formStates.farmingProcedureIdError}
                            onChange={procedureHandler}
                            helperText={formStates.farmingProcedureIdErrorMsg}
                            value={farmingProcedureId}
                          >
                            <option value="0">Select</option>
                            {procedureName.map((item, index) => (
                              <option
                                key={index}
                                value={item.farmingProcedureId}
                              >
                                {item.procedureName}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          variant="outlined"
                          label="* Status"
                          fullWidth={true}
                          id="farmingProcedureStatusId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.farmingProcedureStatusIdError}
                          onChange={farmingProcedureStatusIdHandler}
                          helperText={
                            formStates.farmingProcedureStatusIdErrorMsg
                          }
                          value={formValues.farmingProcedureStatusId}
                        >
                          <option value="0">Select</option>
                          {statusName.map((item, index) => (
                            <option
                              key={index}
                              value={item.farmingProcedureStatusId}
                            >
                              {item.statusName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          variant="outlined"
                          label="* Growth Stage"
                          fullWidth={true}
                          id="plantGrowthStageId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.plantGrowthStageIdError}
                          onChange={plantGrowthStageIdHandler}
                          helperText={formStates.plantGrowthStageIdErrorMsg}
                          value={formValues.plantGrowthStageId}
                        >
                          <option value="0">Select</option>
                          {growthStageName.map((item, index) => (
                            <option key={index} value={item.plantGrowthStageId}>
                              {item.growthStageName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          variant="outlined"
                          multiline
                          fullWidth={true}
                          label="* Remark"
                          id="remark"
                          error={formStates.remarkError}
                          onChange={remarkHandler}
                          helperText={formStates.remarkErrorMsg}
                          value={formValues.remark}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          ) : null}

          {props.formMode === "edit" ? (
            <div className="mt-5 mx-5">
              {+formValues.farmingOperationId === 1 ||
              +formValues.farmingOperationId === 3 ? (
                <Grid container className={classes.gridContainer}>
                  <Grid item xs={props.haspicture ? 8 : 12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          label="* Farm Cells"
                          fullWidth={true}
                          id="farmCellId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.farmCellIdError}
                          onChange={farmCellIdHandler}
                          helperText={formStates.farmCellIdErrorMsg}
                          value={formValues.farmCellId}
                        >
                          <option value="0"> Select</option>
                          {farmCellName.map((item, index) => (
                            <option key={index} value={item.farmCellId}>
                              {item.farmCellName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          label="* Soil Type"
                          fullWidth={true}
                          id="soilTypeId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.soilTypeIdError}
                          onChange={soilTypeIdHandler}
                          helperText={formStates.soilTypeIdErrorMsg}
                          value={formValues.soilTypeId}
                        >
                          <option value="0"> Select</option>
                          {farmCellName.map((item, index) => (
                            <option key={index} value={item.soilTypeId}>
                              {item.soilName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      {/* <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          label="* Season Details"
                          fullWidth={true}
                          id="seasonDetailsId"
                          select
                          SelectProps={{ native: true }}
                          error={formStates.seasonDetailsIdError}
                          onChange={seasonHandler}
                          helperText={formStates.seasonDetailsIdErrorMsg}
                          value={formValues.seasonDetailsId}
                        >
                          <option value="0">Select</option>
                          {seasonName.map((item, index) => (
                            <option key={index} value={item.seasonDetailsId}>
                              {item.seasonName}
                            </option>
                          ))}
                        </TextField>
                      </Grid> */}
                      <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          label="* Opearations"
                          fullWidth={true}
                          id="farmingOperationId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.farmingOperationIdError}
                          onChange={operationsHandler}
                          helperText={formStates.farmingOperationIdErrorMsg}
                          value={formValues.farmingOperationId}
                        >
                          <option value="0">Select</option>
                          {operationName.map((item, index) => (
                            <option key={index} value={item.farmingOperationId}>
                              {item.operationName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      {formValues.farmingOperationId != 0 &&
                      formValues.farmingOperationId != "" ? ( //  a logic to disable to procedure field untill the operation field is clicked /selected
                        <Grid item xs={12} sm={8} md={6}>
                          <TextField
                            disabled
                            variant="outlined"
                            label="* Procedures"
                            fullWidth={true}
                            id="farmingProcedureId"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            error={formStates.farmingProcedureIdError}
                            onChange={procedureHandler}
                            helperText={formStates.farmingProcedureIdErrorMsg}
                            value={formValues.farmingProcedureId}
                          >
                            <option value="0">Select</option>
                            {procedureName.map((item, index) => (
                              <option
                                key={index}
                                value={item.farmingProcedureId}
                              >
                                {item.procedureName}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      ) : (
                        //logic to display all fields including growth stage field on click of planting and post planting with farmingoperation id 2 and 3
                        <Grid item xs={12} sm={8} md={6}>
                          <TextField
                            disabled
                            variant="outlined"
                            label="*Procedures"
                            fullWidth={true}
                            id="farmingProcedureId"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            error={formStates.farmingProcedureIdError}
                            onChange={procedureHandler}
                            helperText={formStates.farmingProcedureIdErrorMsg}
                            value={formValues.farmingProcedureId}
                          >
                            <option value="0">Select</option>
                            {procedureName.map((item, index) => (
                              <option
                                key={index}
                                value={item.farmingProcedureId}
                              >
                                {item.procedureName}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          variant="outlined"
                          label="* Status"
                          fullWidth={true}
                          id="farmingProcedureStatusId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.farmingProcedureStatusIdError}
                          onChange={farmingProcedureStatusIdHandler}
                          helperText={
                            formStates.farmingProcedureStatusIdErrorMsg
                          }
                          value={formValues.farmingProcedureStatusId}
                        >
                          <option value="0">Select</option>
                          {statusName.map((item, index) => (
                            <option
                              key={index}
                              value={item.farmingProcedureStatusId}
                            >
                              {item.statusName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          variant="outlined"
                          multiline
                          fullWidth={true}
                          label="* Remark"
                          id="remark"
                          error={formStates.remarkError}
                          onChange={remarkHandler}
                          helperText={formStates.remarkErrorMsg}
                          value={formValues.remark}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid container className={classes.gridContainer}>
                  <Grid item xs={props.haspicture ? 8 : 12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          label="* Farm Cells"
                          fullWidth={true}
                          id="farmCellId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.farmCellIdError}
                          onChange={farmCellIdHandler}
                          helperText={formStates.farmCellIdErrorMsg}
                          value={formValues.farmCellId}
                        >
                          <option value="0"> Select</option>
                          {farmCellName.map((item, index) => (
                            <option key={index} value={item.farmCellId}>
                              {item.farmCellName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          label="* Soil Type"
                          fullWidth={true}
                          id="soilTypeId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.soilTypeIdError}
                          onChange={soilTypeIdHandler}
                          helperText={formStates.soilTypeIdErrorMsg}
                          value={formValues.soilTypeId}
                        >
                          <option value="0"> Select</option>
                          {farmCellName.map((item, index) => (
                            <option key={index} value={item.soilTypeId}>
                              {item.soilName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      {/* <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          disabled
                          variant="outlined"
                          label="* Season Details"
                          fullWidth={true}
                          id="seasonDetailsId"
                          select
                          SelectProps={{ native: true }}
                          error={formStates.seasonDetailsIdError}
                          onChange={seasonHandler}
                          helperText={formStates.seasonDetailsIdErrorMsg}
                          value={formValues.seasonDetailsId}
                        >
                          <option value="0">Select</option>
                          {seasonName.map((item, index) => (
                            <option key={index} value={item.seasonDetailsId}>
                              {item.seasonName}
                            </option>
                          ))}
                        </TextField>
                      </Grid> */}
                      <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          // disabled
                          variant="outlined"
                          label="* Opearations"
                          fullWidth={true}
                          id="farmingOperationId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.farmingOperationIdError}
                          onChange={operationsHandler}
                          helperText={formStates.farmingOperationIdErrorMsg}
                          value={formValues.farmingOperationId}
                        >
                          <option value="0">Select</option>
                          {operationName.map((item, index) => (
                            <option key={index} value={item.farmingOperationId}>
                              {item.operationName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      {formValues.farmingOperationId != 0 &&
                      formValues.farmingOperationId != "" ? ( //  a logic to disable to procedure field untill the operation field is clicked /selected
                        <Grid item xs={12} sm={8} md={6}>
                          <TextField
                            disabled
                            variant="outlined"
                            label="* Procedures"
                            fullWidth={true}
                            id="farmingProcedureId"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            error={formStates.farmingProcedureIdError}
                            onChange={procedureHandler}
                            helperText={formStates.farmingProcedureIdErrorMsg}
                            value={formValues.farmingProcedureId}
                          >
                            <option value="0">Select</option>
                            {procedureName.map((item, index) => (
                              <option
                                key={index}
                                value={item.farmingProcedureId}
                              >
                                {item.procedureName}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      ) : (
                        //logic to display all fields including growth stage field on click of planting and post planting with farmingoperation id 2 and 3
                        <Grid item xs={12} sm={8} md={6}>
                          <TextField
                            disabled
                            variant="outlined"
                            label="*Procedures"
                            fullWidth={true}
                            id="farmingProcedureId"
                            select
                            SelectProps={{
                              native: true,
                            }}
                            error={formStates.farmingProcedureIdError}
                            onChange={procedureHandler}
                            helperText={formStates.farmingProcedureIdErrorMsg}
                            value={formValues.farmingProcedureId}
                          >
                            <option value="0">Select</option>
                            {procedureName.map((item, index) => (
                              <option
                                key={index}
                                value={item.farmingProcedureId}
                              >
                                {item.procedureName}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          variant="outlined"
                          label="* Status"
                          fullWidth={true}
                          id="farmingProcedureStatusId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.farmingProcedureStatusIdError}
                          onChange={farmingProcedureStatusIdHandler}
                          helperText={
                            formStates.farmingProcedureStatusIdErrorMsg
                          }
                          value={formValues.farmingProcedureStatusId}
                        >
                          <option value="0">Select</option>
                          {statusName.map((item, index) => (
                            <option
                              key={index}
                              value={item.farmingProcedureStatusId}
                            >
                              {item.statusName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          // disabled
                          variant="outlined"
                          label="* Growth Stage"
                          fullWidth={true}
                          id="plantGrowthStageId"
                          select
                          SelectProps={{
                            native: true,
                          }}
                          error={formStates.plantGrowthStageIdError}
                          onChange={plantGrowthStageIdHandler}
                          helperText={formStates.plantGrowthStageIdErrorMsg}
                          value={formValues.plantGrowthStageId}
                        >
                          <option value="0">Select</option>
                          {growthStageName.map((item, index) => (
                            <option key={index} value={item.plantGrowthStageId}>
                              {item.growthStageName}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={8} md={6}>
                        <TextField
                          variant="outlined"
                          multiline
                          fullWidth={true}
                          label="* Remark"
                          id="remark"
                          error={formStates.remarkError}
                          onChange={remarkHandler}
                          helperText={formStates.remarkErrorMsg}
                          value={formValues.remark}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            disabled={formStates.buttonState}
            variant="contained"
            onClick={sumbitActivities}
            className={classes.successButton}
          >
            {formStates.buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default FarmForm;
