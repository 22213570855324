import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    roles: [],
    rolesDataTable: [],
    editRoleData: {},
    createError: null,
    editError: null,
    getError: null,
    loading: false,
    roleCreated: false,
    roleEdited: false,
};


const getRolesStarts = (state, action) => {
    return updateObject(state, { loading: true, roleCreated: false, getError: null });
};

const getRolesSuccess = (state, action) => {
    return updateObject(state, { roles: action.roles, rolesDataTable: action.rolesDataTable, loading: false, getError: null });
};

const getRolesFail = (state, action) => {
    return updateObject(state, { getError: action.error, loading: false });
};

const createRoleStarts = (state, action) => {
    return updateObject(state, { loading: true, roleCreated: false, createError: null });
};

const createRoleSuccess = (state, action) => {
    return updateObject(state, { loading: false, roleCreated: true, createError: null });
};

const createRoleFails = (state, action) => {
    return updateObject(state, { createError: action.error, loading: false, roleCreated: false });
};

const editRoleStarts = (state, action) => {
    return updateObject(state, { loading: true, roleEdited: false, editError: null });
};

const editRoleSuccess = (state, action) => {
    return updateObject(state, { loading: false, roleEdited: false, editError: null, editRoleData: action.roleData });
};

const editRoleFails = (state, action) => {
    return updateObject(state, { editError: action.error, loading: false, roleEdited: false });
};

const editRoleSavedSuccess = (state, action) => {
    return updateObject(state, { loading: false, roleEdited: true, editError: null, });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ROLES_CONSTANTS.GET_ROLES_STARTS: return getRolesStarts(state, action);
        case actionTypes.ROLES_CONSTANTS.GET_ROLES_SUCCESS: return getRolesSuccess(state, action);
        case actionTypes.ROLES_CONSTANTS.GET_ROLES_FAILS: return getRolesFail(state, action);
        case actionTypes.ROLES_CONSTANTS.CREATE_ROLE_STARTS: return createRoleStarts(state, action);
        case actionTypes.ROLES_CONSTANTS.CREATE_ROLE_SUCCESS: return createRoleSuccess(state, action);
        case actionTypes.ROLES_CONSTANTS.CREATE_ROLE_FAILS: return createRoleFails(state, action);
        case actionTypes.ROLES_CONSTANTS.EDIT_ROLE_STARTS: return editRoleStarts(state, action);
        case actionTypes.ROLES_CONSTANTS.EDIT_ROLE_SUCCESS: return editRoleSuccess(state, action);
        case actionTypes.ROLES_CONSTANTS.EDIT_ROLE_FAILS: return editRoleFails(state, action);
        case actionTypes.ROLES_CONSTANTS.EDIT_ROLE_SAVED_SUCCESS: return editRoleSavedSuccess(state, action);


        default: return state;
    }
};

export default reducer;