import { put, delay } from 'redux-saga/effects';
import api from '../../middlewares/axiosConfig';
import {toastr} from 'react-redux-toastr'
import Cookies from 'js-cookie';

import * as actions from '../actions';

export function* getStatesSaga() {
    try{
        yield put(actions.getStatesStarts());
        const response = yield api.get('SetUp/GetAllStates');
        let states = response.data;
        yield put(actions.getStatesSuccess(states));
    }catch(error){
        toastr.error("Error", "An error occured while trying to get states", {
            onCloseButtonClick: true
        });
        yield put(actions.getStatesFails(error));
    }
}