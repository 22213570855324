import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    createError: null,
    loading: false,
    accountCreated: false,
};

const createAccountSuccess = ( state, action ) => {
    return updateObject( state, { loading: false, accountCreated: true, createError: null } );
};

const createAccountFails = ( state, action ) => {
    return updateObject( state, { createError: action.error, loading: false, accountCreated: false } );
};

const resetError = ( state, action ) => {
    return updateObject(state, {createError: null});
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.CREATE_ACCOUNT_CONSTANTS.CREATE_ACCOUNT_SUCCESS: return createAccountSuccess(state, action);
        case actionTypes.CREATE_ACCOUNT_CONSTANTS.CREATE_ACCOUNT_FAILS: return createAccountFails(state, action);
        case actionTypes.CREATE_ACCOUNT_CONSTANTS.CREATE_ACCOUNT_FAILS2: return resetError(state, action);

        default: return state;
    }
};

export default reducer;
