/** @format */

import { ADMIN_CONSTANTS } from "./actionTypes";
import Cookies from "js-cookie";

export const setAdminAuth = (data) => (dispatch) => {
  // console.log(data);
  Cookies.set("adminData", data, { expires: 7 });

  dispatch({
    type: ADMIN_CONSTANTS.SET_AUTH,
    payload: data,
  });
};

export const setAdminLogout = () => (dispatch) => {
  Cookies.remove("adminData");
  dispatch({
    type: ADMIN_CONSTANTS.DELETE_AUTH,
    // payload: data
  });
};

export const setDetailedUsers = (data) => (dispatch) => {
  dispatch({
    type: ADMIN_CONSTANTS.SET_DETAILED_USERS,
    payload: data,
  });
};

export const setDetailedPartner = (data) => (dispatch) => {
  dispatch({
    type: ADMIN_CONSTANTS.SET_DETAILED_Partner,
    payload: data,
  });
};
