import React, { useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import "./styles.css";
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import clsx from 'clsx';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { makeStyles, withStyles } from '@material-ui/styles';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import moment from 'moment';

import api from '../../../../../middlewares/axiosConfig';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    '&.recharts-default-legend': {
      fontSize: '11px'
    }
  },
  content: {
    padding: '0 !important',
    marginTop: '7px',
    paddingBottom: '20px !important'
  },
  titleStyle: {
    fontWeight: 600,
    lineHeight: '30px',
    fontSize: '16px',
    color: '#0e0e0e'
  },
  avatarStyle: {
    border: '1px solid #c5ccc5',
    // fontSize: '30px',
    borderRadius: '5px',
    color: '#4a4c49'
  },
  margin: {
    margin: theme.spacing(0),
  },
}));


const FarmerStatus = () => {

  const classes = useStyles();
  const [statusData, setStatusData] = useState([
    {
      year: moment().year() - 4,
      registeredFarmers: 200,
      verifiedFarmers: 150,
      farmerWithAccount: 100
    },
    {
      year: moment().year() - 3,
      registeredFarmers: 200,
      verifiedFarmers: 150,
      farmerWithAccount: 100
    },
    {
      year: moment().year() - 2,
      registeredFarmers: 200,
      verifiedFarmers: 150,
      farmerWithAccount: 100
    },
    {
      year: moment().year() - 1,
      registeredFarmers: 200,
      verifiedFarmers: 150,
      farmerWithAccount: 100
    },
    {
      year: moment().year(),
      registeredFarmers: 200,
      verifiedFarmers: 150,
      farmerWithAccount: 100
    }
  ]);


  useEffect(async () => {
    let res = await api.get(`Dashboard/GetAllFarmersYearlyStatus`)
    let data = res.data;
    setStatusData(data);
  }, [])


  return (
    <Card className={clsx(classes.root)} className="shadow">
      <CardHeader title="Farmer Status" avatar={<EqualizerIcon />} classes={{ title: classes.titleStyle, avatar: classes.avatarStyle }} style={{ marginBottom: '16px' }} />
      <Divider />
      <CardContent className={classes.content} >
        <ResponsiveContainer width="100%" aspect={2}>
          <BarChart
            width={500}
            height={300}
            data={statusData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" name="Year" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="registeredFarmers" fill="#008000" name="Registered Farmers" />
            <Bar dataKey="verifiedFarmers" fill="#FFD700" name="Verified Farmers" />
            <Bar dataKey="farmerWithAccount" fill="#000080" name="Farmers with Account" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}

export default FarmerStatus;