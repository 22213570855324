import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
// import "./styles.css";
import { Card, CardHeader, CardContent, Divider } from "@material-ui/core";
import clsx from "clsx";
import InputBase from "@material-ui/core/InputBase";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { makeStyles, withStyles } from "@material-ui/styles";

import api from "../../../../../middlewares/axiosConfig";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      marginBottom: "16px",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    marginBottom: "16px",
  },

  content: {
    padding: "0 !important",
    marginTop: "7px",
    paddingBottom: "20px !important",
  },
  titleStyle: {
    fontWeight: 600,
    lineHeight: "30px",
    fontSize: "16px",
    color: "#0e0e0e",
  },
  avatarStyle: {
    border: "1px solid #c5ccc5",
    // fontSize: '30px',
    borderRadius: "5px",
    color: "#4a4c49",
  },
  margin: {
    margin: theme.spacing(3),
  },
}));

const FarmerStatus = () => {
  const classes = useStyles();
  const FarmerData = useSelector((state) => state.loginReducer.farmerData);
  const [farmDetails, SetFarmDetails] = useState([
    // {
    //   farmName: " ",
    //   totalAllocatedFarmSize: 1,
    // },
    // {
    //   farmName: " ",
    //   totalAllocatedFarmSize: 2,
    // },
    // {
    //   farmName: " ",
    //   totalAllocatedFarmSize: 3,
    // },
    // {
    //   farmName: " ",
    //   totalAllocatedFarmSize: 4,
    // },
    // {
    //   farmName: " ",
    //   totalAllocatedFarmSize: 5,
    // },
    // {
    //   farmName: " ",
    //   totalAllocatedFarmSize: 6,
    // },
  ]);

  useEffect(async () => {
    let res = await api.get(
      `FarmerMgt/GetFarmDetails/${FarmerData.individualFarmerId}`
    );
    let data = res.data;

    SetFarmDetails(data);
  }, []);

  return (
    <Card
      className={clsx(classes.root)}
      className="shadow"
      style={{ marginBottom: "16px" }}
    >
      <CardHeader
        title="Allocation Farm Size"
        avatar={<EqualizerIcon />}
        classes={{ title: classes.titleStyle, avatar: classes.avatarStyle }}
      />
      <Divider />
      <CardContent className={classes.content}>
        <ResponsiveContainer width="100%" aspect={2}>
          <ComposedChart
            width={500}
            height={400}
            data={farmDetails}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="farmName" name="Farm Name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="totalAllocatedFarmSize"
              barSize={20}
              fill="#007500"
              name="Allocated Farm Cell Size(Hectares)"
            />
            {/* <Line
              type="monotone"
              dataKey="numberAllocated"
              stroke="#ff7300"
              name="Number of Farm Cell Allocated"
            /> */}
          </ComposedChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default FarmerStatus;
