/** @format */

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

import { Page } from "../../../components/Admin";
import {
  Header,
  Overview,
  FinancialStats,
  EarningsSegmentation,
  TopReferrals,
  MostProfitableProducts,
  CustomerActivity,
  LatestOrders,
  FarmDetails,
  RecentRegistration,
  FarmerStatus,
  TotalFarmLand,
} from "./components";
import AllocatedStatus from "./components/AllocatedStatus";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  container: {
    "& > *": {
      height: "100%",
    },
  },
}));

const DashboardAnalytics = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Partner Dashboard">
      <Header />
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={12}>
          <Overview />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <RecentRegistration />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <FarmerStatus />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <TotalFarmLand />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <FarmDetails />
        </Grid>
        <Grid item xs={12} lg={6} xl={3}>
          <AllocatedStatus />
        </Grid>
        {/* <Grid
          item
          xs={12}
          lg={6}
          xl={3}
        >
          <MostProfitableProducts />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          xl={3}
        >
          <TopReferrals />
        </Grid> */}
      </Grid>
    </Page>
  );
};

export default DashboardAnalytics;
