/**
 * /* eslint-disable react/no-multi-comp
 *
 * @format
 */

/* eslint-disable react/display-name */
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import PeopleIcon from "@material-ui/icons/PeopleAltOutlined";
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting";
// import FarmIcon from '@material-ui/icons/HomeWorkOutlined';
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import LandscapeIcon from "@material-ui/icons/Landscape";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import NaturePeopleIcon from "@material-ui/icons/NaturePeopleOutlined";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import ReportIcon from "@material-ui/icons/Report";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import RouterIcon from "@material-ui/icons/Router";
import ViewModuleIcon from "@material-ui/icons/ViewModule";

export default [
  {
    title: "Pages",
    pages: [
      {
        title: "Home",
        href: "/super_admin",
        icon: HomeIcon,
      },
      {
        title: "User Access",
        href: "/super_admin/userAccess",
        icon: PeopleIcon,
        children: [
          {
            title: "Cooperative Roles",
            href: "/super_admin/cooperativeRoles",
          },
          {
            title: "Cooperative Account",
            href: "/super_admin/cooperativeAccount",
          },
          {
            title: "Admin Roles",
            href: "/super_admin/adminRoles",
          },
          {
            title: "Admin Account",
            href: "/super_admin/superAdmin_users",
          },
          {
            title: "Manage Partner",
            href: "/super_admin/manage-partner",
          },
          {
            title: "Partner Request",
            href: "/super_admin/partner-request",
          },
        ],
      },
      {
        title: "Setup",
        href: "/super_admin/setupManagement",
        icon: PermDataSettingIcon,
        children: [
          {
            title: "Cooperative",
            href: "/super_admin/cooperative",
          },
          {
            title: "Manage Cooperative Pages",
            href: "/super_admin/cooperative-pages",
          },
          {
            title: "Cooperative Menu",
            href: "/super_admin/cooperative-menu",
          },
          {
            title: "Admin Pages",
            href: "/super_admin/admin-pages",
          },
          {
            title: "Admin Menu",
            href: "/super_admin/admin-menu",
          },
        ],
      },
    ],
  },
];
