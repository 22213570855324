/** @format */

import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Card, CardHeader, CardContent, Divider } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/styles";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import moment from "moment";

import api from "../../../../../middlewares/axiosConfig";
import { states } from "./state";
// import { states } from './states';

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    padding: "0 !important",
    marginTop: "7px",
    paddingBottom: "20px !important",
  },
  titleStyle: {
    fontWeight: 600,
    lineHeight: "30px",
    fontSize: "16px",
    color: "#0e0e0e",
  },
  avatarStyle: {
    border: "1px solid #c5ccc5",
    // fontSize: '30px',
    borderRadius: "5px",
    color: "#4a4c49",
  },
  margin: {
    margin: theme.spacing(0),
  },
}));

const RecentRegistration = () => {
  const classes = useStyles();
  const [selectedYear, setSelectedYear] = useState(1);
  const [years, setYears] = useState([]);
  const [data, setData] = useState([]);
  const [cooperativeSocietyId, setCooperativeSocietyId] = useState(0);
  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const SelectYear = (
    <FormControl className={classes.margin}>
      <InputLabel id="demo-customized-select-label">Year</InputLabel>
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={selectedYear}
        onChange={handleChange}
        input={<BootstrapInput />}
      >
        {years.map((year, index) => {
          return (
            <MenuItem key={index} value={Number.parseInt(index) + 1}>
              {year}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const getPast5Years = () => {
    const past5Years = [];
    const currentYear = moment().year();
    past5Years.push(currentYear);
    for (let i = 1; i < 5; i++) {
      past5Years.push(currentYear - i);
    }
    setYears(past5Years);
  };

  useEffect(() => {
    getPast5Years();
    const getRecentRegStats = async () => {
      let activeYear;
      switch (selectedYear) {
        case 1:
          activeYear = moment().year();
          break;
        case 2:
          activeYear = moment().year() - 1;
          break;
        case 3:
          activeYear = moment().year() - 2;
          break;
        case 4:
          activeYear = moment().year() - 3;
          break;
        case 5:
          activeYear = moment().year() - 4;
          break;
      }
      let recentRegStats = await api.get(
        `Partners/PartnerRecentRegisteredFarmers/1/${activeYear}`
      );
      // let recentRegStats = await api.get(`Partners/PartnerRecentRegisteredFarmers/${cooperativeSocietyId}/${activeYear}`);
      setData([...recentRegStats.data]);
    };
    getRecentRegStats();
  }, [selectedYear]);

  return (
    <Card className={clsx(classes.root)} className="shadow">
      <CardHeader
        title="Recent Registration"
        avatar={<HowToRegIcon />}
        classes={{ title: classes.titleStyle, avatar: classes.avatarStyle }}
        action={SelectYear}
      />
      <Divider />
      <CardContent className={classes.content}>
        <ResponsiveContainer width="100%" aspect={2}>
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{ top: 8, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            {/* {data.map((data) => console.log(data))} */}
            {/* <Line type="monotone" dataKey="FederalCapitalTerrirtory" name="FCT" stroke="#de2fa8" activeDot={{ r: 8 }} /> */}
            {states.map((state, i) => (
              <Line
                key={i}
                type="monotone"
                dataKey={state.key}
                stroke={state.color}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default RecentRegistration;

