import { GENERIC_CONSTANTS } from '../actions/actionTypes';
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  roles: [
    {
      roleId: 1,
      roleName: "Admin"
    },
    {
      roleId: 2,
      roleName: "Manager"
    },
    {
      roleId: 3,
      roleName: "Front Desk"
    },
    {
      roleId: 5,
      roleName: "Developer"
    },
  ],
  states: [],
};


const getStatesStarts = ( state, action ) => {
  return updateObject( state,  { loading: true } );
};


const getStatesSuccess = ( state, action ) => {
  return updateObject( state, { states: action.states, loading: false });
};

const getStatesFails = ( state, action ) => {
  return updateObject( state, { error: action.error, loading: false } );
};


const adminReducer = (state = {...initialState}, action) => {
  switch (action.type) {
    case GENERIC_CONSTANTS.SET_ROLES:
      return {...state, roles : { ...action.payload } }
    
    case actionTypes.GENERIC_CONSTANTS.GET_STATES_STARTS: return getStatesStarts(state, action);
    case actionTypes.GENERIC_CONSTANTS.GET_STATES_SUCCESS: return getStatesSuccess(state, action);
    case actionTypes.GENERIC_CONSTANTS.GET_STATES_FAILS: return getStatesFails(state, action);

    default:
      return state;
  }
}

export default adminReducer;