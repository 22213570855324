import React, { Component } from 'react';
import {  withRouter, Router } from 'react-router-dom';
// import { Route } from 'react-router';
// import Layout from './components/Layout/layout';
// import { Home } from './components/Home';
import MomentUtils from '@date-io/moment';
import { connect, Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
// import { FetchData } from './components/FetchData';
// import { Counter } from './components/Counter';

import './custom.css';
import theme from './theme';
import { configureStore } from './redux/store';
import routes from './routes';
import * as actions from './redux/actions';


import {
  ScrollReset,
  GoogleAnalytics,
  CookiesNotification
} from './components/Admin/';
 
const history = createBrowserHistory();

// const store = configureStore();

class App extends Component {

  componentDidMount(){
    this.props.onTryAutoSignin()
  }

  static displayName = App.name;

  render () {
    return (
      // <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <ScrollReset />
              <GoogleAnalytics />
              <CookiesNotification />
              {renderRoutes(routes)}
            </Router>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      // </StoreProvider> 
    );
  }
}

// const mapStateToProps = state => {
//   return{
//     isStilLoggedIn: state.loginReducer.farmerData.token !== null
//   }
// }

const mapDispatchToProps = dispatch => {
  return{
    onTryAutoSignin: () => dispatch(actions.loggedinCheckState())
  }
}

export default withRouter(connect(null, mapDispatchToProps)(App));

