/** @format */

import { PAYMENT_CONSTANTS } from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  loading: false,
  error: null,

  paymentCat: [],
};
const paymentCat = (state, action) => {
  return updateObject(state, { loading: true, error: false });
};

const paymentCatSucc = (state, action) => {
  return updateObject(state, {
    loading: false,
    paymentCat: action.payload,
    error: false,
  });
};

const paymentCatFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_CONSTANTS.GET_ALL_PAYMENTCAT:
      return paymentCat(state, action);
    case PAYMENT_CONSTANTS.GET_ALL_PAYMENTCAT_SUCCESS:
      return paymentCatSucc(state, action);
    case PAYMENT_CONSTANTS.GET_ALL_PAYMENTCAT_FAIL:
      return paymentCatFail(state, action);
    default: // need this for default case
      return state;
  }
};

export default reducer;
