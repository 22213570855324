/** @format */

import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Button,
  Grid,
  TextField,
  Divider,
  Typography,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import CssBaseline from "@material-ui/core/CssBaseline";
// import SetupImg from "../../../assets/images/agric/signUp.jpg";
import SetupImg from "../../../../assets/images/agric/signUp.jpg";
import { green } from "@material-ui/core/colors";
import imgs from "../cre-regAccount/choiceImage.jpg";
import RegisterFarmersForm from "../Register";
import CreateFarmersForm from "../createAccount";
import options from "../cre-regAccount/options.PNG";

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: "url(" + options + ")",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.green
        : theme.palette.green,
    backgroundSize: "90% auto",
    backgroundPosition: "center",
    height: "40vh",
  },
  paper: {
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  images: {
    height: "50%",
    width: "100%",
    padding: "3px",
    margin: theme.spacing(2),
  },
  format: {
    // backgroundColor: "#edede9",
  },
  textFormat: {
    height: "50%",
    padding: "3px",
    margin: theme.spacing(2),
  },
  avatar: {
    marginTop: theme.spacing(1),
    backgroundColor: "#1FA12E",
  },
  form: {
    // display: "flex",
    width: "30%", // Fix IE 11 issue.
    marginTop: theme.spacing(0),
    backgroundColor: "#edede9   ",
  },

  successButton: {
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  btn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: green[700],
    },
    backgroundColor: "#4caf50",
  },
  subText: {
    fontSize: "10px",
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "#1FA12E",
    color: "#fff",
  },
  DialogTitle: {
    "& h2": {
      fontSize: "1.6rem",
      textAlign: "center",
      fontWeight: 700,
      margin: "5px 0 5px 0",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const CreorRegFarmer = (props) => {
  const { OptionDialogCl, onClose } = props;
  const classes = useStyles();

  const [open0, setOpen0] = useState(false);
  let [open1, setOpen1] = useState(false);

  function OptionCreOpen() {
    setOpen0(true);
  }
  function OptionCreClose() {
    setOpen0(false);
    onClose();
  }
  function OptionRegOpen() {
    setOpen1(true);
  }
  function OptionRegClose() {
    setOpen1(false);
    onClose();
  }

  return (
    <Fragment>
      <div>
        <DialogTitle
          id="customized-dialog-title"
          className={classes.DialogTitle}
        >
          {`Create Farmer Account`}

          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            component="main"
            style={{ overflow: "hidden" }}
            className={classes.root}
          >
            {/* <CssBaseline /> */}
            <Grid item xs={false} md={6} className={classes.image} />

            <Grid
              container
              // direction="column"
              item
              xs={4}
              md={6}
              component={Paper}
              elevation={6}
              spacing={5}
              className={classes.form}
              justify="center"
              alignItems="center"
              // square
            >
              {/* <div className={classes.paper}> */}

              <Grid item>
                <Typography align="center" variant="h4" nowrap={true}>
                  REGISTER FARMER
                  <br />
                  <span className={classes.subText}>
                    <i>(without Identity Number)</i>
                  </span>
                </Typography>
                <Button
                  variant="outlined"
                  Button
                  className={classes.btn}
                  onClick={OptionRegOpen}
                >
                  Register
                </Button>
              </Grid>
              <dividers variant="inset" />

              <Grid item>
                <Typography align="center" variant="h4" nowrap={true}>
                  CREATE FARMER
                  <span className={classes.subText}>
                    <i>(with Identity Number)</i>
                  </span>
                </Typography>
                <Button
                  className={classes.btn}
                  variant="outlined"
                  onClick={OptionCreOpen}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={open0}
          onClose={OptionCreClose}
          className={classes.Dialog}
          fullWidth={true}
          maxWidth="md"
        >
          <CreateFarmersForm onClose={OptionCreClose} />
          {/* <RegisterFarmersForm handleClose={handleClose2} /> */}
          {/* <FarmersForm handleRegCl={handleRegCl} /> */}
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={open1}
          onClose={OptionRegClose}
          className={classes.Dialog}
          fullWidth={true}
          maxWidth="md"
        >
          {/* <CreorRegFarmer OptionDialogCl={OptionDialogCl} /> */}
          <RegisterFarmersForm onClose={OptionRegClose} />
        </Dialog>
      </div>
    </Fragment>
  );
};
export default CreorRegFarmer;
