/** @format */

import { SUPER_ADMIN } from "./actionTypes";
import Cookies from "js-cookie";

export const superAdmin = (data) => (dispatch) => {
  Cookies.set("superAdminData", data, { expires: 7 });
  dispatch({
    type: SUPER_ADMIN.SUPER_ADMIN_AUTH,
    payload: data,
  });
};

export const superAdminLogout = () => (dispatch) => {
  Cookies.remove("superAdminData");
  dispatch({
    type: SUPER_ADMIN.DELETE_AUTH,
  });
};

export const superAdminDetailedUser = (data) => (dispatch) => {
  dispatch({
    type: SUPER_ADMIN.SET_DETAILED_USERS,
    payload: data,
  });
};

export const setDetailedSuperAdmin = (data) => (dispatch) => {
  dispatch({
    type: SUPER_ADMIN.SET_DETAILED_Partner,
    payload: data,
  });
};
