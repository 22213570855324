import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    farmers: [],
    editFarmerData: {},
    createError: null,
    editError: null,
    getError: null,
    loading: false,
    farmerCreated: false,
    farmerEdited: false,
    farmersPassportCaptured: [],
    getFarmerPassportCapturedError: null,
    farmersBiometricCaptured: [],
    getFarmerBiometricCapturedError: null,
};


const getFarmersStart = ( state, action ) => {
    return updateObject( state, { loading: true,  farmerCreated: false, getError: null });
}

const getFarmersSuccess = ( state, action ) => {
    return updateObject( state, { farmers: action.farmersData, loading: false, getError: null });
};

const getFarmersFail = ( state, action ) => {
    return updateObject( state, { getError: action.error, loading: false } );
};

const createFarmerStarts = ( state, action ) => {
    return updateObject( state, { loading: true, farmerCreated: false, createError: null } );
};

const createFarmerSuccess = ( state, action ) => {
    return updateObject( state, { loading: false, farmerCreated: true, createError: null } );
};

const createFarmerFails = ( state, action ) => {
    return updateObject( state, { createError: action.error, loading: false, farmerCreated: false } );
};

const getFarmerStarts = ( state, action ) => {
    return updateObject( state, { loading: true, farmerEdited: false, editError: null  } );
};

const getFarmerSuccess = ( state, action ) => {
    return updateObject( state, { loading: false, farmerEdited: false, editError: null, editFarmerData: action.farmerData } );
};

const getFarmerFails = ( state, action ) => {
    return updateObject( state, { getError: action.error, loading: false } );
};

const editFarmerSuccess = ( state, action ) => {
    return updateObject( state, { loading: false, farmerEdited: true, editError: null, } );
};

const editFarmerFails = ( state, action ) => {
    return updateObject( state, { editError: action.error, loading: false, farmerEdited: false } );
};



const getFarmersPassportCapturedStart = ( state, action ) => {
    return updateObject( state, { loading: true, getFarmerPassportCapturedError: null });
}

const getFarmersPassportCapturedSuccess = ( state, action ) => {
    return updateObject( state, { farmersPassportCaptured: action.farmersData, loading: false, getFarmerPassportCapturedError: null });
};

const getFarmersPassportCapturedFail = ( state, action ) => {
    return updateObject( state, { getFarmerPassportCapturedError: action.error, loading: false } );
};



const getFarmersBiometricsCapturedStart = ( state, action ) => {
    return updateObject( state, { loading: true, getFarmerBiometricCapturedError: null });
}

const getFarmersBiometricsCapturedSuccess = ( state, action ) => {
    return updateObject( state, { farmersBiometricCaptured: action.farmersData, loading: false, getFarmerBiometricCapturedError: null });
};

const getFarmersBiometricsCapturedFail = ( state, action ) => {
    return updateObject( state, { getFarmerBiometricCapturedError: action.error, loading: false } );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FARMERS_CONSTANTS.GET_FARMERS_START: return getFarmersStart(state, action);
        case actionTypes.FARMERS_CONSTANTS.GET_FARMERS_SUCCESS: return getFarmersSuccess(state, action);
        case actionTypes.FARMERS_CONSTANTS.GET_FARMERS_FAIL: return getFarmersFail(state, action);
        case actionTypes.FARMERS_CONSTANTS.CREATE_FARMER_STARTS: return createFarmerStarts(state, action);
        case actionTypes.FARMERS_CONSTANTS.CREATE_FARMER_SUCCESS: return createFarmerSuccess(state, action);
        case actionTypes.FARMERS_CONSTANTS.CREATE_FARMER_FAILS: return createFarmerFails(state, action);
        case actionTypes.FARMERS_CONSTANTS.GET_FARMER_STARTS: return getFarmerStarts(state, action);
        case actionTypes.FARMERS_CONSTANTS.GET_FARMER_SUCCESS: return getFarmerSuccess(state, action);
        case actionTypes.FARMERS_CONSTANTS.GET_FARMER_FAILS: return getFarmerFails(state, action);
        case actionTypes.FARMERS_CONSTANTS.EDIT_FARMER_SUCCESS: return editFarmerSuccess(state, action);
        case actionTypes.FARMERS_CONSTANTS.EDIT_FARMER_FAILS: return editFarmerFails(state, action);

        case actionTypes.FARMERS_CONSTANTS.GET_FARMERS_PASSPORT_CAPTURED_START: return getFarmersPassportCapturedStart(state, action);
        case actionTypes.FARMERS_CONSTANTS.GET_FARMERS_PASSPORT_CAPTURED_SUCCESS: return getFarmersPassportCapturedSuccess(state, action);
        case actionTypes.FARMERS_CONSTANTS.GET_FARMERS_PASSPORT_CAPTURED_FAIL: return getFarmersPassportCapturedFail(state, action);

        case actionTypes.FARMERS_CONSTANTS.GET_FARMERS_BIOMETRIC_CAPTURED_STARTS: return getFarmersBiometricsCapturedStart(state, action);
        case actionTypes.FARMERS_CONSTANTS.GET_FARMERS_BIOMETRIC_CAPTURED_SUCCESS: return getFarmersBiometricsCapturedSuccess(state, action);
        case actionTypes.FARMERS_CONSTANTS.GET_FARMERS_BIOMETRIC_CAPTURED_FAILS: return getFarmersBiometricsCapturedFail(state, action);


        default: return state;
    }
};

export default reducer;
