/**
 * /* eslint-disable react/no-multi-comp
 *
 * @format
 */

/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import PeopleIcon from "@material-ui/icons/PeopleAltOutlined";
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting";
// import FarmIcon from '@material-ui/icons/HomeWorkOutlined';
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import LandscapeIcon from "@material-ui/icons/Landscape";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import NaturePeopleIcon from "@material-ui/icons/NaturePeopleOutlined";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import ReportIcon from "@material-ui/icons/Report";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import RouterIcon from "@material-ui/icons/Router";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { useSelector, useDispatch } from "react-redux";
// import api from "middlewares/axiosConfig";

// const [pages, setPages] = useState([]);
// const user = useSelector((state) => state.loginReducer.user);

// useEffect(async () => {
//   let pages = await api.get(`Admin/GetAdminSideBar/${user.roleId}`);
//   setPages(pages);
// }, []);

// console.log("USER", user.roleId);

export default [
  {
    pages: [
      {
        title: "Home",
        href: "/admin",
        icon: HomeIcon,
      },
      //   {
      //     title: "User Access",
      //     href: "/admin/allusers",
      //     icon: PeopleIcon,
      //     children: [
      //       {
      //         title: "Admin Users",
      //         href: "/admin/all-users",
      //       },
      //       {
      //         title: "Admin Roles",
      //         href: "/admin/all-roles",
      //       },
      //       {
      //         title: "Farmers Account",
      //         href: "/admin/farmers-account",
      //       },
      //       {
      //         title: "Partners Account",
      //         href: "/admin/partners-account",
      //       },
      //     ],
      //   },
      //   {
      //     title: "Setup Management",
      //     href: "/admin/setup",
      //     icon: PermDataSettingIcon,
      //     children: [
      //       {
      //         title: "Towns",
      //         href: "/admin/towns",
      //       },
      //       {
      //         title: "Farm Types",
      //         href: "/admin/farmtypes",
      //       },
      //       {
      //         title: "Farm Produce",
      //         href: "/admin/produce",
      //       },
      //       {
      //         title: "Seasons",
      //         href: "/admin/seasons",
      //       },
      //       {
      //         title: "Download Client",
      //         href: "/admin/downloadclient",
      //       },
      //     ],
      //   },
      //   {
      //     title: "Manage Farmland",
      //     href: "/admin/manage-farm",
      //     icon: LandscapeIcon,
      //     children: [
      //       {
      //         title: "Register Farm",
      //         href: "/admin/farm-details",
      //       },
      //       {
      //         title: "Partition farm",
      //         href: "/admin/partition-farm",
      //       },
      //       {
      //         title: "Allocate farms",
      //         href: "/admin/allocate-farm",
      //       },
      //       {
      //         title: "Farm Activities",
      //         href: "/admin/farm-activities",
      //       },
      //     ],
      //   },
      //   {
      //     title: "Register Farmer",
      //     href: "/admin/allFarmers",
      //     icon: HowToRegIcon,
      //     children: [
      //       {
      //         title: "Bio-Data Capture",
      //         href: "/admin/biodata-capture",
      //       },
      //       {
      //         title: "Passport Capture",
      //         href: "/admin/passport-capture",
      //       },
      //       {
      //         title: "Biometric Enrollment",
      //         href: "/admin/biometrics-capture",
      //       },
      //     ],
      //   },
      //   {
      //     title: "Manage Farmer",
      //     href: "/admin/allFarmers",
      //     icon: SupervisedUserCircleIcon,
      //     children: [
      //       {
      //         title: "All Farners",
      //         href: "/admin/farmers-enrolled",
      //       },
      //     ],
      //   },

      //   {
      //     title: " Manage Inventory",
      //     href: "/admin/inventory-management",
      //     icon: ReceiptIcon,
      //     children: [
      //       {
      //         title: "Category",
      //         href: "/admin/category",
      //       },
      //       {
      //         title: "Sub Category",
      //         href: "/admin/sub-category",
      //       },
      //       {
      //         title: "Inventory Type",
      //         href: "/admin/inventory-type",
      //       },

      //       {
      //         title: "Inventory Details",
      //         href: "/admin/inventory-management",
      //       },
      //       {
      //         title: "Request",
      //         href: "/admin/request",
      //       },
      //     ],
      //   },

      //   {
      //     title: " Manage Labour",
      //     href: "/admin/labour-management",
      //     icon: TransferWithinAStationIcon,
      //     children: [
      //       {
      //         title: "Category",
      //         href: "/admin/categorys",
      //       },
      //       {
      //         title: "Sub Category",
      //         href: "/admin/sub-categorys",
      //       },
      //       {
      //         title: "Labour Type",
      //         href: "/admin/labour-type",
      //       },
      //       {
      //         title: "Labour",
      //         href: "/admin/labours",
      //       },
      //       {
      //         title: "Request",
      //         href: "/admin/requests",
      //       },
      //     ],
      //   },

      //   {
      //     title: "Manage Services",
      //     href: "/admin/manage-services",
      //     icon: RouterIcon,
      //     children: [
      //       {
      //         title: "Services",
      //         href: "/admin/services",
      //       },
      //       {
      //         title: "Service Details",
      //         href: "/admin/service",
      //       },
      //       {
      //         title: "Service Request",
      //         href: "/admin/request-for-service",
      //       },
      //       {
      //         title: "Service Provider",
      //         href: "/admin/service-provider",
      //       },
      //     ],
      //   },

      //   {
      //     title: "Reports",
      //     href: "/admin/reports",
      //     icon: LibraryBooksIcon,
      //     children: [
      //       {
      //         title: "List of Farmers",
      //         href: "/admin/reports/farmers-list",
      //       },
      //       {
      //         title: "Farmers Details",
      //         href: "/admin/reports/farmers-details",
      //       },
      //       {
      //         title: "Disbursements",
      //         href: "/admin/reports/disbursement-reports",
      //       },
      //     ],
      //   },
      //   {
      //     title: "Manage Contents",
      //     href: "/admin/contents",
      //     icon: ContentPasteSearchIcon,
      //     children: [
      //       {
      //         title: "Photo Gallery",
      //         href: "/admin/content/photogallery",
      //       },
      //       {
      //         title: "Video Gallery",
      //         href: "/admin/contents/videogallery",
      //       },
      //     ],
      //   },
    ],
  },
];
