/**
 * /* eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import React, { Fragment, useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import clsx from "clsx";
import validators from "../../middlewares/validators";
import * as actions from "../../redux/actions";

const Classes = makeStyles((theme) => ({
  socials: {
    pointerEvents: "none !important",
    cursor: "default",
    color: "Gray",
    opacity: 0.6,
  },

  disabled: {
    pointerEvents: "none !important",
    cursor: "not-allowed !important",
    opacity: 0.6,
  },
  iconButton: {
    outline: "none",

    "&:hover, &:focus": {
      outline: "none",
    },
  },
  [theme.breakpoints.down("xs")]: {
    forms: {
      hieight: "10vh",
    },
  },
}));

const SuperLoginForm = (props) => {
  const classes = Classes();

  const [states, setStates] = useState({
    emailError: false,
    emailErrorMsg: "",
    passwordError: false,
    passwordErrorMsg: "",
    isButtonDisabled: false,
    buttonText: "LOG IN",
    isPasswordVisible: false,
  });

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const dispatch = useDispatch();
  const loginSuccess = useSelector(
    (state) => state.loginReducer.farmerData.token
  );
  const loginFailed = useSelector((state) => state.loginReducer.error);

  useEffect(() => {
    loginSuccess && resetForm();
    loginSuccess && document.querySelector(".success_close").click();

    document.querySelector(".success_close") &&
      document
        .querySelector(".success_close")
        .addEventListener("click", resetForm);

    return () => {
      document.querySelector(".success_close") &&
        document
          .querySelector(".success_close")
          .removeEventListener("click", resetForm);
    };
  }, [loginSuccess]);

  useEffect(() => {
    loginFailed &&
      setStates({ ...states, isButtonDisabled: false, buttonText: "LOG IN" });
  }, [loginFailed]);

  const resetForm = () => {
    emailRef.current.value = "";
    passwordRef.current.value = "";
    setStates({ ...states, isButtonDisabled: false, buttonText: "LOG IN" });
  };

  const inputEmailHandler = (event) => {
    if (event) event.preventDefault();

    let emailPhoneNumberField = emailRef.current.value;
    let isValidEmail = validators.isValidEmail(emailPhoneNumberField);
    // let isValidPhoneNumber = !isNaN(emailPhoneNumberField) ? emailPhoneNumberField.toString().length == 11 ? true : false : false;
    // emailRef.current.value = !isNaN(emailPhoneNumberField) ? emailRef.current.value.toString().length > 11 ? emailRef.current.value.slice(0, 11) : emailRef.current.value  : emailRef.current.value ;

    if (!isValidEmail) {
      // if(!isNaN(emailRef.current.value) && emailRef.current.value.toString().length == 11){
      //     setStates({ ...states, emailError: false, emailErrorMsg: "" });
      //     return true;
      // }
      setStates({
        ...states,
        emailError: true,
        emailErrorMsg: "Please enter a valid email address",
      });
      return false;
    }
    setStates({ ...states, emailError: false, emailErrorMsg: "" });
    return true;
  };

  const inputPasswordHandler = (event) => {
    if (event) event.preventDefault();

    // let isStrongPassword = validators.isStrongPassword(passwordRef.current.value);

    // if (!isStrongPassword) {
    //     setStates({ ...states, passwordError: true, passwordErrorMsg: "Password must have at least one capital letter, small letter, a number and more than 5 letters"  });
    //     return false;
    // }

    setStates({ ...states, passwordError: false, passwordErrorMsg: "" });
    return true;
  };

  const handleClickShowPassword = () => {
    setStates({ ...states, isPasswordVisible: !states.isPasswordVisible });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    if (inputEmailHandler() && inputPasswordHandler()) {
      const formData = {
        email: emailRef.current.value,
        password: passwordRef.current.value,
      };
      setStates({
        ...states,
        isButtonDisabled: true,
        buttonText: "...LOADING",
      });
      dispatch(actions.superAdminLogin(formData));
    } else {
      toastr.error("Error", "Please fill in valid email and password", {
        onCloseButtonClick: true,
      });
      setStates({ ...states, isButtonDisabled: false, buttonText: "LOG IN" });
    }
  };

  return (
    <Fragment>
      <div autoComplete="off" className="forms">
        <div className="form_block">
          <TextField
            label="Email or Phone number"
            variant="outlined"
            fullWidth
            id="ss"
            error={states.emailError}
            onChange={inputEmailHandler}
            helperText={states.emailErrorMsg}
            inputRef={emailRef}
          />
        </div>
        <div className="form_block">
          <FormControl
            variant="outlined"
            fullWidth
            error={states.passwordError}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={states.isPasswordVisible ? "text" : "password"}
              fullWidth={true}
              inputRef={passwordRef} // value={states.password}
              onChange={inputPasswordHandler}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword} // onMouseDown={handleMouseDownPassword}
                    edge="end"
                    className={classes.iconButton}
                  >
                    {states.isPasswordVisible ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
            <FormHelperText id="component-error-text">
              {states.passwordErrorMsg}
            </FormHelperText>
          </FormControl>
        </div>

        <button
          type="button"
          onClick={submitHandler}
          disabled={states.isButtonDisabled}
          className={
            states.isButtonDisabled
              ? clsx("clv_btn", classes.disabled)
              : "clv_btn"
          }
        >
          <a href="/">{states.buttonText}</a>
        </button>
        <br />
        <br />
        <a href="/forgot-Password" style={{ color: "black" }}>
          FORGOT PASSWORD?
        </a>
      </div>
    </Fragment>
  );
};
export default SuperLoginForm;
