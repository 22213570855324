import { put } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import api from '../../middlewares/axiosConfig';
import Cookies from 'js-cookie';
import {toastr} from 'react-redux-toastr';
import * as actions from '../actions';

let history = createBrowserHistory({forceRefresh:true});


export function* createAccountSaga(action){
    yield put(actions.createAccountStarts());

    try{
        let response = yield api.post('FarmerAccounts/RegisterFarmerAccount', action.farmerData);
        let data = response.data;
        if(data.isSuccess){ 
            toastr.success("Success", data.message, {
                onCloseButtonClick: true
            })
            yield put(actions.createAccountSuccess());
        }else{
            toastr.error("Warning", data.message, {
                onCloseButtonClick: true
            })
            let error = data.message;
            yield put(actions.createAccountFails(error))
        }
    }catch(error){
        toastr.error("Error", error.data.message, {
            onCloseButtonClick: true
        });
        yield put(actions.createAccountFails(error));
    }

}