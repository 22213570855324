/** @format */

import { put, delay } from "redux-saga/effects";
import api from "../../middlewares/axiosConfig";
import { toastr } from "react-redux-toastr";

import * as actions from "../actions";

export function* getAllCooperativeSocietySaga() {
  try {
    // yield put(actions.getAllCooperativeSociety());
    const response = yield api.get("partners/getcooperativesocieties");
    let cooperative = response.data;
    // yield put(actions.getAllCooperativeSociety(cooperative));
  } catch (error) {
    toastr.error("Error", "An error occured while trying to get Cooperatives", {
      onCloseButtonClick: true,
    });
    yield put(actions.getAllCooperativeSocietyFails(error));
  }
}
