/** @format */

import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";
import validators from "../../../../middlewares/validators";
import Swal from "sweetalert2";
import api from "../../../../middlewares/axiosConfig";
import {
  Grid,
  Button,
  CircularProgress,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import FarmForm from "./FarmForm";
import { AppIndicator } from "react-bootstrap-icons";
import withReactContent from "sweetalert2-react-content";
import { toastr } from "react-redux-toastr";
import { useSelector } from "react-redux";
import { initial } from "lodash";
import { ToastContainer } from "react-toastr";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    "& .MuiTableCell-head": {
      color: "#263238",
      fontWeight: 700,
    },

    "&  .MuiTableCell-head .MuiButton-text .MuiButton-label": {
      fontWeight: 700,
    },

    "& .MuiToolbar-regular .MuiTypography-root.MuiTypography-h6": {
      opacity: 0.3,
      fontWeight: 600,
    },
  },
  fields: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    marginBottom: "2rem",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dangerButton: {
    color: "#fff",
    backgroundColor: "#dc3545",
    "&:hover": {
      backgroundColor: "#dc3545",
    },
  },
  container: {
    marginTop: "3rem",
    marginBottom: "3rem",
  },
  header: {
    fontSize: "1.5rem",
    marginBottom: "0.5rem",
    textTransform: "uppercase",
    fontWeight: 700,
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },
  headerDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  headerButton: {
    outline: "none",
    "&:focus": {
      outline: "none",
    },
    background: "#2E7D32",
    color: "#fff",
    "&:hover": {
      background: "#fff",
      color: "#2E7D32",
      outline: "#2E7D32 solid 1px",
    },
    marginBottom: "2.5rem",
    // marginTop: "0.6rem",
    width: "10rem",
  },
  Dialog: {
    "& *:focus": {
      outline: "none",
    },
  },
  gridContainer: {
    "& > div": {
      padding: ".5rem",
    },
  },
  DialogTitle: {
    "& h2": {
      fontSize: "1.5rem",
    },
  },
  tableName: {
    marginTop: "2rem",
    // marginRight: "2rem",
    // marginBottom: "2rem",
    fontSize: "1.5rem",
    marginBottom: "1rem",
    fontWeight: 500,
    // textTransform: "uppercase",
  },
  deleteButton: {
    color: "#dc3545",
    "&:hover": {
      backgroundColor: "#b12c39",
    },
  },
  formControl: {
    display: "flex-end",
    flexDirection: "column",
    alignContent: "space-evenly",
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-end",
    },
  },
  formControlDiv: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-end",
    },
  },
}));

function FarmAcivites() {
  // {Hooks for the various states used in farm activities}
  const classes = useStyles();
  const [tableName, setTableName] = useState("Pre-Planting");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const MySwal = withReactContent(Swal);
  const [formMode, setFormMode] = useState("create");
  const [farmCell, setFarmCell] = useState([]);
  const [farmCellIds, setFarmCellIds] = useState(0);
  const FarmerData = useSelector((state) => state.loginReducer.farmerData);
  const [operation, setOperation] = useState([]);
  const [operationId, setOperationId] = useState(1);
  const [farmActive, setFarmActive] = useState([]);
  const [farmingActivityStageId, setFarmingActivityStageId] = useState(0);
  const [season, setSeason] = useState([]);
  const [seasonId, setSeasonId] = useState(0);

  // console.log("show me", operationId);

  useEffect(() => {
    async function getAllFarmCells() {
      let response = await api.get(
        `FarmerMgt/GetFarmCellsAllocated/${FarmerData.individualFarmerId}`
      );
      if (response.data.length) {
        setFarmCellIds(response.data[0].farmCellId);
        // console.log("ressssss", response.data[0].farmCellId);
        // console.log("farmName", response.data[1].farmCellName);
      }
      setFarmCell(response.data);

      let responed = await api.get(
        `FarmingActivityStages/GetAllFarmingOperations`
      );
      setOperation(responed.data);
    }
    getAllFarmCells();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log("test", farmActive);
  }, [farmActive]);

  useEffect(async () => {
    let seasonResponse = await api.get(
      "FarmingActivityStages/GetAllSeasonDetails"
    );
    setSeason(seasonResponse.data);
  }, []);

  useEffect(() => {
    let currentSeasonId = 0;
    season.forEach((currentSeason) => {
      if (currentSeason.isCurrentSeason) {
        currentSeasonId = currentSeason.seasonDetailsId;
      }
    });
    setSeasonId(currentSeasonId);
  }, [season]);

  async function getFarmData() {
    try {
      if (operation.length && farmCell.length) {
        let response = await api.get(
          `FarmingActivityStages/GetAllFarmingActivityStages?farmCellId=${farmCellIds}&farmingOperationId=${operationId}&seasonDetailsId=${seasonId}`
        );
        let farmActive = await response.data.map((data) => {
          if (operationId === 1) {
            return [
              "",
              data.farmCellName,
              data.operationName,
              data.statusName,
              data.procedureName,
              `${moment(data.dateCreated).format("MMMM Do YYYY")}`,
              data.farmingActivityStageId,
            ];
          }
          return [
            "",
            data.farmCellName,
            data.operationName,
            data.statusName,
            data.growthStageName,
            data.procedureName,
            `${moment(data.dateCreated).format("MMMM Do YYYY")}`,

            data.farmingActivityStageId,
          ];
        });
        // console.log("deeeee", farmActive);
        setFarmActive(farmActive);
      }
    } catch (error) {
      // console.log("error occured", error);
    }
  }

  useEffect(() => {
    setTableName();
  }, [operation, farmCell]);

  useEffect(() => {
    getFarmData();
  }, [farmCellIds, operationId, seasonId]);

  // {UseEffect Hook for the Select fields}
  useEffect(() => {
    switch (+operationId) {
      case 1:
        setTableName("Pre-Planting");
        break;
      case 2:
        setTableName("Planting");
        break;
      case 3:
        setTableName("Post-Planting");
        break;
      default:
        setTableName("Pre-Planting");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationId]);

  // {Function that handles the Modal for Edith and Delect Button}
  const handleClickOpen = () => {
    setFormMode("create");
    setOpen(true);
  };

  // {Function that handles the Modal for Edith and Delect Button}
  const handleClose = () => {
    setFarmingActivityStageId(0);
    setOpen(false);
  };

  // {function that picks up the id of the individual farm cells on edith}
  const viewFarmerId = (id) => {
    setFarmingActivityStageId(id);
    setFormMode("edit");
    setOpen(true);
  };

  const deleteFarmerId = (Id) => {
    MySwal.fire({
      title: "Warning",
      text: "Are you sure you want to delete?",
      icon: "Warning",
      showCancelButton: true,
    }).then((data) => {
      if (data.isConfirmed) {
        api
          .delete(`FarmingActivityStages/DeleteFarmActivityStage/${Id}`)
          .then(() => {
            toastr.success("Success", "Successfully deleted a Farmcell");
            getFarmData();
          })
          .catch((err) => {
            toastr.success("Error", "Error deleting Farm");
          });
      }
    });
  };

  // {Table Columns: table 1 is named 'COLUMNS' while table 2 is named 'COLUMN'}
  const column1 = [
    {
      name: "S/N",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return <span>{tableMeta.rowIndex + 1}</span>;
        },
      },
    },
    "Farm Cell",
    "Operation",
    "Status",
    "Procedure",
    "Date",
    {
      name: "Actions",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title={"Edit"}>
                <IconButton
                  size="small"
                  className="mx-1 mb-1 mb-lg-0"
                  variant="contained"
                  color="secondary"
                  onClick={() => viewFarmerId(value)}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={"Delete"}>
                <IconButton
                  size="small"
                  className={`mx-1 ${classes.deleteButton}`}
                  variant="contained"
                  color="primary"
                  onClick={() => deleteFarmerId(value)}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const column2 = [
    {
      name: "S/N",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return <span>{tableMeta.rowIndex + 1}</span>;
        },
      },
    },
    "Farm Cell",
    "Operation",
    "Status",
    // "Growth Stage",
    "Procedure",
    "Date",
    {
      name: "Actions",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title={"Edit"}>
                <IconButton
                  size="small"
                  className="mx-1 mb-1 mb-lg-0"
                  variant="contained"
                  color="secondary"
                  onClick={() => viewFarmerId(value)}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={"Delete"}>
                <IconButton
                  size="small"
                  className={`mx-1 ${classes.deleteButton}`}
                  variant="contained"
                  color="primary"
                  onClick={() => deleteFarmerId(value)}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <Container className={classes.root}>
        <div className={classes.headerDiv}>
          <Typography variant="h3" className={classes.header}>
            Farming Activities
          </Typography>
          <Button
            variant="outlined"
            className={classes.headerButton}
            onClick={handleClickOpen}
            style={{}}
          >
            Add Activities
          </Button>
        </div>

        {/* { FIlter Select Field for the Farm and the OperationId Stages } */}
        <Grid
          container
          spacing={4}
          className={classes.formControlDiv}
          sx={{ display: "flex " }}
        >
          <Grid item className={"mb-8"}>
            <FormControl className={"mb-2"}>
              <Select
                getContentAnchorEl={null}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper1"
                onChange={(e) => {
                  setSeasonId(e.target.value);
                }}
                value={seasonId}
              >
                <MenuItem value={0}> Season</MenuItem>
                {season.map((item, index) => (
                  <MenuItem key={index} value={item.seasonDetailsId}>
                    {" "}
                    {item.seasonName}{" "}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select a season to see details.</FormHelperText>
            </FormControl>
          </Grid>

          <Grid
            item
            sx={{ display: "flex", justifyContent: "flex-end", p: "5px" }}
            className={classes.formControl}
          >
            <FormControl item sx={{ m: "5px" }}>
              <Select
                getContentAnchorEl={null}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper1"
                variant="outlined"
                onChange={(e) => {
                  setFarmCellIds(e.target.value);
                }}
                value={farmCellIds}
              >
                <MenuItem value={0}> Farm</MenuItem>
                {farmCell.map((item, index) => (
                  <MenuItem key={index} value={item.farmCellId}>
                    {" "}
                    {item.farmCellName}{" "}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select a farm to see details.</FormHelperText>
            </FormControl>

            <FormControl item sx={{ m: "5px" }}>
              <Select
                getContentAnchorEl={null}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                variant="outlined"
                value={operationId}
                onChange={(e) => {
                  setOperationId(e.target.value);
                }}
              >
                <MenuItem value={operationId}> Select </MenuItem>
                {operation.map((item, index) => (
                  <MenuItem key={index} value={item.farmingOperationId}>
                    {item.operationName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Select an operation to see details.
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        {/* { MuI Table Name and Table Contents} */}
        <Typography className={classes.tableName} variant="h3">
          {" "}
          {tableName}{" "}
        </Typography>
        <MUIDataTable
          data={farmActive}
          columns={
            operationId === 1 ? column1 : operationId > 1 ? column2 : null
          }
          options={{
            selectableRows: "none",
            selectableRowsHeader: false,
            elevation: 5,
            print: false,
            download: false,
            textLabels: {
              body: {
                noMatch: loading ? (
                  <CircularProgress />
                ) : (
                  "Sorry, there is no matching data to display"
                ),
              },
            },
          }}
        />
      </Container>

      <FarmForm
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        formMode={formMode}
        viewFarmDetails={getFarmData}
        farmingActivityStageId={farmingActivityStageId}
        farmCellIds={farmCellIds}
        setFarmCellIds={setFarmCellIds}
      />
    </>
  );
}

export default FarmAcivites;
