/**
 * /* eslint-disable react/no-multi-comp
 *
 * @format
 */

/* eslint-disable react/display-name */
import { lazy } from "react";
import AdminDashboardLayout from "./layouts/AdminDashboard";
import FarmerDashboardLayout from "./layouts/FarmerDashboard";
import PartnerDashboardLayout from "./layouts/PartnerDashboard";
import ServiceProvidersDashboardLayout from "./layouts/ServiceProvidersDashboard";
// import SuperAdminDashBoard from "layouts/SuperAdminDashBoard";
import SuperAdminDashBoard from "./layouts/SuperAdminDashBoard";

import HomeLayout from "./layouts/HomeLayout";
import DashboardAnalytics from "./views/Admin/DashboardAnalytics";
import FarmerIndex from "./views/Farmer/DashboardAnalytics";
import PartnerIndex from "./views/Partner/DashboardAnalytics";
import ServiceProvidersIndex from "./views/ServiceProviders/DashboardDefault";
import PortalIndex from "./views/SuperAdmin/DashboardAnalytics";
import Error404 from "./views/Error404";

const routes = [
  {
    path: "/",
    exact: true,
    component: HomeLayout,
    routes: [
      {
        path: "/",
        exact: true,
        component: lazy(() => import("./views/Home/HomePage")),
      },
    ],
  },

  {
    path: "/super_admin",
    component: SuperAdminDashBoard,
    routes: [
      {
        path: "/super_admin/",
        exact: true,
        component: PortalIndex,
      },
      {
        path: "/super_admin/cooperative",
        component: lazy(() =>
          import("./views/SuperAdmin/SetupManagement/CooperativeAccount/index")
        ),
      },

      {
        path: "/super_admin/cooperativeAccount",
        component: lazy(() =>
          import("./views/SuperAdmin/UserAccess/CooperativeAccount/index")
        ),
      },
      {
        path: "/super_admin/cooperativeRoles",
        component: lazy(() =>
          import("./views/SuperAdmin/UserAccess/CooperativeRoles/index")
        ),
      },
      {
        path: "/super_admin/rolesPermission",
        component: lazy(() =>
          import(
            "./views/SuperAdmin/UserAccess/CooperativeRolePermission/index"
          )
        ),
      },
      {
        path: "/super_admin/managePermission",
        component: lazy(() =>
          import(
            "./views/SuperAdmin/UserAccess/CooperativeRolePermission/ManagePermission"
          )
        ),
      },
      {
        path: "/super_admin/adminRoles",
        component: lazy(() =>
          import("./views/SuperAdmin/UserAccess/AdminRole/index")
        ),
      },
      {
        path: "/super_admin/superAdmin_users",
        component: lazy(() =>
          import("./views/SuperAdmin/UserAccess/AdminAccount/index")
        ),
      },
      {
        path: "/super_admin/cooperative-pages",
        component: lazy(() =>
          import("./views/SuperAdmin/SetupManagement/CooperativePages/index")
        ),
      },
      {
        path: "/super_admin/cooperative-menu",
        component: lazy(() =>
          import("./views/SuperAdmin/SetupManagement/CooperativeMenu/index")
        ),
      },
      {
        path: "/super_admin/admin-pages",
        component: lazy(() =>
          import("./views/SuperAdmin/SetupManagement/AdminPages/index")
        ),
      },
      {
        path: "/super_admin/admin-menu",
        component: lazy(() =>
          import("./views/SuperAdmin/SetupManagement/AdminMenu/index")
        ),
      },

      {
        path: "/super_admin/superAdmin_permission",
        component: lazy(() =>
          import(
            "./views/SuperAdmin/UserAccess/AdminRolePermission/SuperAdminPermission"
          )
        ),
      },
      {
        path: "/super_admin/manager_permission_Sadmin",
        component: lazy(() =>
          import(
            "./views/SuperAdmin/UserAccess/AdminRolePermission/ManageSuperRolePermission"
          )
        ),
      },
      {
        path: "/super_admin/manage-partner",
        component: lazy(() =>
          import("./views/SuperAdmin/UserAccess/PartnerAccess/ManagePartner")
        ),
      },
      {
        path: "/super_admin/registeredFarmer",
        component: lazy(() =>
          import("./views/SuperAdmin/ManageFarmer/BioDataCapture")
        ),
      },
      {
        path: "/super_admin/verifiedFarmer",
        component: lazy(() =>
          import("./views/SuperAdmin/ManageFarmer/BiometricsCapture")
        ),
      },
      {
        path: "/super_admin/register-farm",
        component: lazy(() =>
          import("./views/SuperAdmin/ManageFarm/RegisterFarm/index")
        ),
      },
      {
        path: "/super_admin/allocate-farm",
        component: lazy(() =>
          import("./views/SuperAdmin/ManageFarm/AllocateFarm/index")
        ),
      },
      {
        path: "/super_admin/farm-activites",
        component: lazy(() =>
          import("./views/SuperAdmin/ManageFarm/FarmActivites/index")
        ),
      },
      {
        path: "/super_admin/partner-request",
        component: lazy(() =>
          import("./views/SuperAdmin/partnerRequest/index")
        ),
      },

      {
        component: Error404,
      },
    ],
  },
  {
    path: "/admin",
    component: AdminDashboardLayout,
    routes: [
      {
        path: "/admin/",
        exact: true,
        component: DashboardAnalytics,
      },
      {
        path: "/admin/towns",
        component: lazy(() => import("./views/Admin/SetupManagement/Town")),
      },
      {
        path: "/admin/crop-types",
        component: lazy(() =>
          import("./views/Admin/SetupManagement/CropTypes")
        ),
      },
      {
        path: "/admin/farm-types",
        component: lazy(() =>
          import("./views/Admin/SetupManagement/FarmTypes")
        ),
      },
      {
        path: "/admin/Produce",
        component: lazy(() => import("./views/Admin/SetupManagement/Produce")),
      },

      {
        path: "/admin/seasons",
        component: lazy(() => import("./views/Admin/SetupManagement/Seasons")),
      },
      {
        path: "/admin/payment-category",
        component: lazy(() =>
          import("./views/Admin/SetupManagement/CategoryPayment")
        ),
      },
      {
        path: "/admin/payment",
        component: lazy(() =>
          import("./views/Admin/SetupManagement/PaymentItem")
        ),
      },
      {
        path: "/admin/downloadclient",
        component: lazy(() =>
          import("./views/Admin/SetupManagement/DownloadClient")
        ),
      },

      {
        path: "/admin/all-users",
        component: lazy(() => import("./views/Admin/UserAccess/ManageUsers")),
      },
      {
        path: "/admin/all-roles",
        component: lazy(() => import("./views/Admin/UserAccess/ManageRoles")),
      },
      {
        path: "/admin/biodata-capture",
        component: lazy(() =>
          import("./views/Admin/RegisterFarmer/BioDataCapture")
        ),
      },
      {
        path: "/admin/bulk-upload",
        component: lazy(() =>
          import("./views/Admin/RegisterFarmer/BioDataCapture/BulkUpload")
        ),
      },
      {
        path: "/admin/passport-capture",
        component: lazy(() =>
          import("./views/Admin/RegisterFarmer/PassportCapture")
        ),
      },
      {
        path: "/admin/biometrics-capture",
        component: lazy(() =>
          import("./views/Admin/RegisterFarmer/BiometricsCapture")
        ),
      },
      {
        path: "/admin/farmers-enrolled",
        component: lazy(() => import("./views/Admin/ManageFarmer/index")),
      },
      {
        path: "/admin/all-farms",
        component: lazy(() => import("./views/Admin/AllFarms")),
      },
      {
        path: "/admin/farmtypes",
        component: lazy(() =>
          import("./views/Admin/SetupManagement/FarmTypes")
        ),
      },
      {
        path: "/admin/farm-details",
        component: lazy(() => import("./views/Admin/FarmDetails")),
      },

      {
        path: "/admin/add-allocate-farm",
        component: lazy(() =>
          import("./views/Admin/ManageFarmland/AddAllocateFarm/indexNew")
        ),
      },

      {
        path: "/admin/allocate-farm",
        component: lazy(() =>
          import("./views/Admin/ManageFarmland/AllocateFarm/index")
        ),
      },

      {
        path: "/admin/farm-activities",
        component: lazy(() =>
          import("./views/Admin/ManageFarmland/FarmActivities/index")
        ),
      },
      {
        path: "/admin/farmer-activities/:farmerId/:farmCellId",
        component: lazy(() =>
          import("./views/Admin/ManageFarmland/FarmActivities/index")
        ),
      },
      {
        path: "/admin/partition-farm",
        component: lazy(() =>
          import("./views/Admin/ManageFarmland/PartitionFarm")
        ),
      },
      {
        path: "/admin/farmers-account",
        component: lazy(() =>
          import("./views/Admin/UserAccess/FarmersAccount")
        ),
      },
      {
        path: "/admin/partners-account",
        component: lazy(() =>
          import("./views/Admin/UserAccess/PartnersAccount")
        ),
      },
      {
        path: "/admin/payment-history",
        component: lazy(() =>
          import("./views/Admin/UserAccess/PaymentHistory")
        ),
      },

      {
        path: "/admin/reports/farmers-list",
        component: lazy(() => import("./views/Admin/Reports/farmersList")),
      },
      {
        path: "/admin/reports/farmers-details",
        component: lazy(() => import("./views/Admin/Reports/farmersDetails")),
      },
      {
        path: "/admin/category",
        component: lazy(() =>
          import("./views/Admin/InventoryManagement/Category")
        ),
      },
      {
        path: "/admin/sub-category",
        component: lazy(() =>
          import("./views/Admin/InventoryManagement/SubCategory")
        ),
      },
      {
        path: "/admin/inventory-type",
        component: lazy(() =>
          import("./views/Admin/InventoryManagement/Type/index")
        ),
      },
      {
        path: "/admin/inventory-management",
        component: lazy(() =>
          import("./views/Admin/InventoryManagement/Inventory/index")
        ),
      },
      {
        path: "/admin/request",
        component: lazy(() =>
          import("./views/Admin/InventoryManagement/Request")
        ),
      },

      {
        path: "/admin/categorys",
        component: lazy(() =>
          import("./views/Admin/ManageLabour/Category/index")
        ),
      },

      {
        path: "/admin/sub-categorys",
        component: lazy(() =>
          import("./views/Admin/ManageLabour/SubCategory/index")
        ),
      },
      {
        path: "/admin/labour-type",
        component: lazy(() => import("./views/Admin/ManageLabour/Types")),
      },

      {
        path: "/admin/labours",
        component: lazy(() =>
          import("./views/Admin/ManageLabour/Labour/index")
        ),
      },

      {
        path: "/admin/requests",
        component: lazy(() =>
          import("./views/Admin/ManageLabour/Request/index")
        ),
      },

      {
        path: "/admin/service",
        component: lazy(() =>
          import("./views/Admin/ManageServices/ServiceDetails")
        ),
      },

      {
        path: "/admin/request-for-service",
        component: lazy(() =>
          import("./views/Admin/ManageServices/ServiceRequest/index")
        ),
      },
      {
        path: "/admin/service-provider",
        component: lazy(() =>
          import("./views/Admin/ManageServices/ServiceProvider")
        ),
      },
      {
        path: "/admin/services",
        component: lazy(() => import("./views/Admin/SetupManagement/Services")),
      },
      {
        path: "/admin/contents/",
        component: lazy(() =>
          import("./views/Admin/Content/Videogallery/Videogallery")
        ),
      },
      {
        path: "/admin/content/",
        component: lazy(() =>
          import("./views/Admin/Content/Photogallery/photogallery")
        ),
      },
      {
        path: "/admin/reports/disbursement-reports",
        component: lazy(() =>
          import("./views/Admin/Reports/disbursementReports")
        ),
      },
      {
        path: "/admin/my-crop",
        component: lazy(() => import("./views/Admin/ManageCrop/MyCrop/index")),
      },

      {
        component: Error404,
      },
    ],
  },
  {
    path: "/farmer",
    // exact: true,
    component: FarmerDashboardLayout,
    routes: [
      {
        path: "/farmer/",
        exact: true,
        component: FarmerIndex,
      },
      {
        path: "/farmer/my-Profile",
        component: lazy(() =>
          import("./views/Farmer/UserAccess/MyProfile/index")
        ),
      },
      {
        path: "/farmer/change-Password",
        component: lazy(() =>
          import("./views/Farmer/UserAccess/ChangePassword/Index")
        ),
      },
      {
        path: "/farmer/member-dues",
        component: lazy(() => import("./views/Farmer/Payment/MemberDues")),
      },

      {
        path: "/farmer/my-farmland",
        component: lazy(() =>
          import("./views/Farmer/MyFarmland/AllocationStatus/index")
        ),
      },

      {
        path: "/farmer/my-farming-activities",
        component: lazy(() =>
          import("./views/Farmer/MyFarmland/MyFarmActivities")
        ),
      },
      {
        path: "/farmer/request-farmland",
        component: lazy(() =>
          import("./views/Farmer/LabourManagement/RequestFarmland")
        ),
      },
      {
        path: "/farmer/tools-request",
        component: lazy(() =>
          import("./views/Farmer/LabourManagement/ToolsRequest/index")
        ),
      },

      {
        path: "/farmer/labour-request",
        component: lazy(() =>
          import("./views/Farmer/LabourManagement/LabourRequest/")
        ),
      },

      {
        path: "/farmer/service-request",
        component: lazy(() =>
          import("./views/Farmer/LabourManagement/ServiceRequest/index")
        ),
      },

      {
        path: "/farmer/pre-planting",
        component: lazy(() =>
          import("./views/Farmer/CropManagement/PrePlanting/index")
        ),
      },
      {
        path: "/farmer/crop-plan",
        component: lazy(() =>
          import("./views/Farmer/CropManagement/CropPlan/index")
        ),
      },
    ],
  },
  {
    path: "/partners",
    // exact: true,
    component: PartnerDashboardLayout,
    routes: [
      {
        path: "/partners/",
        exact: true,
        component: PartnerIndex,
      },
      {
        path: "/partners/create-Password",
        exact: true,
        component: lazy(() =>
          import("./views/Partner/CreatePassword/index.js")
        ),
      },
      {
        path: "/partners/userAccess/partnerAccount",
        exact: true,
        component: lazy(() =>
          import("./views/Partner/UserAccess/PartnersAccount/index.js")
        ),
      },
      {
        path: "/partners/manageFarmland/registerFarm",
        exact: true,
        component: lazy(() =>
          import("./views/Partner/ManageFarmland/RegisterFarm/index.js")
        ),
      },
      {
        path: "/partners/manageFarmland/allocateFarm",
        exact: true,
        component: lazy(() =>
          import("./views/Partner/ManageFarmland/AllocateFarm/index.js")
        ),
      },
      {
        path: "/partners/manageFarmland/farmActivities",
        exact: true,
        component: lazy(() =>
          import("./views/Partner/ManageFarmland/FarmActivities/index.js")
        ),
      },
      {
        path: "/partners/manageFarmer/registerFarmer",
        exact: true,
        component: lazy(() =>
          import("./views/Partner/ManageFarmer/RegisterFarmer/index")
        ),
      },
      {
        path: "/partners/manageFarmer/verifiedFarmer",
        exact: true,
        component: lazy(() =>
          import("./views/Partner/ManageFarmer/VerifiedFarmer")
        ),
      },
      {
        component: Error404,
      },
    ],
  },
  {
    path: "/service-providers",
    exact: true,
    component: ServiceProvidersDashboardLayout,
    routes: [
      {
        path: "/service-providers/",
        exact: true,
        component: ServiceProvidersIndex,
      },
      {
        component: Error404,
      },
    ],
  },
  {
    path: "*",
    exact: true,
    component: HomeLayout,
    routes: [
      {
        path: "/login",
        exact: true,
        component: lazy(() => import("./views/Home/HomePage")),
      },
      {
        path: "/login-partner",
        exact: true,
        component: lazy(() =>
          import("./views/Home/HomePage/PartnerServices/LoginP")
        ),
      },
      {
        path: "/login-service",
        exact: true,
        component: lazy(() =>
          import("./views/Home/HomePage/PartnerServices/Service")
        ),
      },
      {
        path: "/superAdmin_login",
        exact: true,
        component: lazy(() => import("./views/SuperAdmin/SuperAdminLogin")),
      },
      {
        path: "/forgot-Password",
        exact: true,
        component: lazy(() => import("./views/Home/HomePage/forgotPassword")),
      },
      {
        path: "/about",
        exact: true,
        component: lazy(() => import("./views/Home/about")),
      },
      {
        path: "/contact-us",
        exact: true,
        component: lazy(() => import("./views/Home/contactUs")),
      },
      {
        path: "/services",
        exact: true,
        component: lazy(() => import("./views/Home/services")),
      },
      {
        path: "/agric-investment",
        exact: true,
        component: lazy(() =>
          import("./views/Home/HomePage/ServiceSection/agricInvestment")
        ),
      },
      {
        path: "/credit-assistance",
        exact: true,
        component: lazy(() =>
          import("./views/Home/HomePage/ServiceSection/creditAssistance")
        ),
      },
      {
        path: "/farm-allocation",
        exact: true,
        component: lazy(() =>
          import("./views/Home/HomePage/ServiceSection/farmAllocation")
        ),
      },
      {
        path: "/farm-management",
        exact: true,
        component: lazy(() =>
          import("./views/Home/HomePage/ServiceSection/farmManagement")
        ),
      },
      {
        path: "/marketing",
        exact: true,
        component: lazy(() =>
          import("./views/Home/HomePage/ServiceSection/marketing")
        ),
      },
      {
        path: "/video-gallery",
        exact: true,
        component: lazy(() => import("./views/Home/HomePage/video-gallery")),
      },
      {
        path: "/photo-gallery",
        exact: true,
        component: lazy(() => import("./views/Home/HomePage/photo-gallery")),
      },
      {
        path: "/gallery-content",
        exact: true,
        component: lazy(() => import("./views/Home/HomePage/GalleryContent")),
      },
      {
        path: "/GalleryContentVideo",
        exact: true,
        component: lazy(() =>
          import("./views/Home/HomePage/GalleryContentVideo")
        ),
      },
      {
        path: "/reset-Password",
        exact: true,
        component: lazy(() => import("./views/Home/resetPassword")),
      },
      {
        path: "/farmland",
        exact: true,
        component: lazy(() =>
          import("./views/Home/HomePage/TaskSection/Farmland")
        ),
      },
      {
        path: "/farmlnput",
        exact: true,
        component: lazy(() =>
          import("./views/Home/HomePage/TaskSection/FarmInput")
        ),
      },
      {
        path: "/offtaking",
        exact: true,
        component: lazy(() =>
          import("./views/Home/HomePage/TaskSection/OffTaking")
        ),
      },
      {
        path: "/create-Password",
        exact: true,
        component: lazy(() =>
          import("./views/Partner/CreatePassword/index.js")
        ),
      },

      {
        component: Error404,
        // component: lazy(() => import('./views/Home/HomePage'))
      },
    ],
  },
];

export default routes;
