/** @format */

import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

import aboutImg from "../../assets/images/agric/aboutPageImg.jpg";
import signupImg2 from "../../assets/images/clv_underline.png";
import signupImg3 from "../../assets/images/fb.png";
import signupImg4 from "../../assets/images/google.png";
import SuperLoginForm from "./SuperAdminForm";
import heroImage1 from "../../assets/images/agric/fruits.jpg";
import heroImage2 from "../../assets/images/agric/tomatoesTree.jpg";
// import Button from "@material-ui/core/Button";
// import ForgotPasswordForm  from './forgotPasswordForm';

const Classes = makeStyles((theme) => ({
  socials: {
    pointerEvents: "none !important",
    cursor: "default",
    color: "Gray",
    opacity: 0.6,
  },
  signinHeader: {
    fontSize: "25px",
    fontWeight: 600,
    color: "#ffffff",
    paddingBottom: "10px",
    position: "relative",
    marginBottom: "20px",
    textTransform: "capitalize",
  },
  image: {
    width: "100%",
    height: "400px",
    overflow: "hidden",
    objectFit: "cover",
  },

  customerAb: {
    width: "100px",
    overflow: "hidden",
    height: "100px",
  },

  faicon: {
    color: "green",
  },
  resetWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    paddingTop: 25,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.7)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 1, //intentional
    visibility: "visible",
    zIndex: 999,
    overflowX: "hidden",
    overflowY: "auto",
    transition: "all 0.3s ease-in-out",
    "-webkit-transition": "all 0.3s ease-in-out",
    "-moz-transition": "all 0.3s ease-in-out",
  },

  resetInner: {
    width: "100%",
    maxWidth: "902px",
    minHeight: "600px",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    position: "relative",
    display: "flex",
    /* opacity: 0; */
    visibility: "visible",
    transform: "translateY(-50px) rotateY(10deg)",
    "-webkit-transform": "translateY(-50px) rotateY(10deg)",
    "-moz-transform": "translateY(-50px) rotateY(10deg)",
    transition: "all 0.3s ease-in-out",
    "-webkit-transition": "all 0.3s ease-in-out",
    "-moz-transition": "all 0.3s ease-in-out",
  },

  resetDetails: {
    position: "relative",
    textAlign: "center",
    borderRadius: "10px 0px 0px 10px",
    background: "url(https://via.placeholder.com/402x600) no-repeat center",
    backgroundSize: "cover",
    padding: "40px 20px",
    width: "50%",
    paddingTop: "58px",
    zIndex: 1,

    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      /* background-color: rgba(254,192,7,0.90); */
      backgroundColor: "green",
      zIndex: -1,
      borderRadius: "10px 0px 0px 10px",
    },
    "& > h3": {
      fontSize: "22px",
      fontWeight: "600",
      color: "#ffffff",
      paddingBottom: "10px",
      position: "relative",
      marginBottom: "20px",
      textTransform: "capitalize",
    },

    "& > h3:before": {
      content: '""',
      width: "98px",
      height: "1px",
      position: "absolute",
      top: "100%",
      left: "50%",
      backgroundColor: "#ffffff",
      transform: "translateX(-50%)",
      "-webkit-transform": "translateX(-50%)",
      "-moz-transform": "translateX(-50%)",
    },
    "& > h3:after": {
      content: '""',
      width: "98px",
      height: "1px",
      position: "absolute",
      top: "100%",
      left: "50%",
      backgroundColor: "#ffffff",
      transform: "translateX(-50%)",
      "-webkit-transform": "translateX(-50%)",
      "-moz-transform": "translateX(-50%)",
      width: "56px",
      top: "calc(100% + 5px)",
    },
    "& > p": {
      color: "#ffffff",
    },
    "& > ul": {
      marginTop: "30px",
    },
    "& > ul > li:first-child": {
      marginLeft: 0,
    },
    "& > ul > li": {
      margin: "0px 10px",
      display: "inline-block",
    },
    "& > ul > li > a": {
      width: "50px",
      height: "50px",
      border: "1px solid #ffffff",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "20px",
      color: "#ffffff",
      transition: "all 0.3s ease-in-out",
      "-webkit-transition": "all 0.3s ease-in-out",
      "-moz-transition": "all 0.3s ease-in-out",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  siteLogo: {
    marginBottom: "35px",
  },
  resetFormSection: {
    width: "60%",
    textAlign: "center",
    padding: "70px 70px",
    position: "relative",
    "& > h4": {
      fontSize: "32px",
      color: "#222222",
      textTransform: "capitalize",
      fontWeight: "600",
    },
  },

  successClose: {
    position: "absolute",
    top: "20px",
    right: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    border: "1px solid #dbdbdb",
    cursor: "pointer",
  },
}));

const handleCloseLoginModal = (e) => {
  window.location.href = "/";
};

const SuperLogin = () => {
  const classes = Classes();
  const fb_btnCl = [classes.socials, "fb_btn"];
  const goo_btnCl = [classes.socials, "google_btn"];

  const servicesHandler = () => {
    window.location.href = "/login-service";
  };

  return (
    <Fragment>
      {/* <!--Breadcrumb--> */}
      <div
        className="breadcrumb_wrapper"
        style={{
          backgroundImage: "url(" + aboutImg + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="breadcrumb_inner">
                <h3>Forgot Password</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="breadcrumb_block">
          <ul>
            <li>
              <Link to="/">home</Link>
            </li>
            <li>Forget Password</li>
          </ul>
        </div>
      </div>
      {/* <!--Revolution Slider--> */}
      <div className="clv_rev_slider">
        <div
          id="rev_slider_1164_1_wrapper"
          className="rev_slider_wrapper fullscreen-container"
          data-alias="exploration-header"
          data-source="gallery"
          style={{ backgroundColor: "transparent", padding: "0px" }}
        >
          <div
            id="rev_slider_1164_1"
            className="rev_slider fullscreenbanner"
            style={{ display: "none" }}
            data-version="5.4.1"
          >
            <ul>
              <li
                data-index="rs-3204"
                data-transition="slideoververtical"
                data-slotamount="default"
                data-hideafterloop="0"
                data-hideslideonmobile="off"
                data-easein="default"
                data-easeout="default"
                data-masterspeed="default"
                data-thumb="../../assets/images/news1-1-100x50.jpg"
                data-rotate="0"
                data-fstransition="fade"
                data-fsmasterspeed="2000"
                data-fsslotamount="7"
                data-saveperformance="off"
                data-title=""
                data-param1=""
                data-param2=""
                data-param3=""
                data-param4=""
                data-param5=""
                data-param6=""
                data-param7=""
                data-param8=""
                data-param9=""
                data-param10=""
                data-description=""
              >
                <img
                  src={heroImage1}
                  alt="image"
                  data-lazyload=""
                  data-bgposition="center bottom"
                  data-bgfit="cover"
                  data-bgrepeat="no-repeat"
                  data-bgparallax="off"
                  className="rev-slidebg"
                  data-no-retina
                />

                <div
                  className="tp-caption d-none d-sm-block"
                  id="slide-3204-layer-1"
                  data-x="['left','left','left','left']"
                  data-hoffset="['364','114','76','26']"
                  data-y="['top','top','top','top']"
                  data-voffset="['262','252','195','185']"
                  data-fontsize="['20','20','20','20']"
                  data-lineheight="['22','22','22','22']"
                  data-width="['700','700','700','700']"
                  data-height="none"
                  data-whitespace="normal"
                  data-type="text"
                  data-basealign="slide"
                  data-responsive_offset="on"
                  data-responsive="off"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":1500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "5",
                    whiteSpace: "normal",
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "#1fa12e",
                    display: "inline-block",
                    fontFamily: "Source Sans Pro, sansSerif",
                    letterSpacing: "3px",
                  }}
                >
                  A New Way To Invest In Agriculture
                </div>

                <div
                  className="tp-caption d-sm-none"
                  id="slide-3204-layer-1"
                  data-x="['left','left','left','left']"
                  data-hoffset="['364','114','76','26']"
                  data-y="['top','top','top','top']"
                  data-voffset="['262','252','195','185']"
                  data-fontsize="['20','20','20','20']"
                  data-lineheight="['22','22','22','22']"
                  data-width="['700','700','700','700']"
                  data-height="none"
                  data-whitespace="normal"
                  data-type="text"
                  data-basealign="slide"
                  data-responsive_offset="on"
                  data-responsive="off"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":1500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "5",
                    whiteSpace: "normal",
                    fontSize: "20px",
                    marginBottom: "10px",
                    fontWeight: "700",
                    color: "#1fa12e",
                    display: "inline-block",
                    fontFamily: "Source Sans Pro, sansSerif",
                    letterSpacing: "3px",
                  }}
                >
                  A New Way To Invest In
                  <br /> Agriculture
                </div>

                <div
                  className="tp-caption tp-shape tp-shapewrapper "
                  id="slide-3204-layer-2"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['0','0','0','0']"
                  data-width="full"
                  data-height="full"
                  data-whitespace="normal"
                  data-type="shape"
                  data-basealign="slide"
                  data-responsive_offset="off"
                  data-responsive="off"
                  data-frames='[{"delay":10,"speed":2000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{ zIndex: "1", backgroundColor: "rgba(0,0,0,0.70)" }}
                >
                  {" "}
                </div>

                <div
                  className="tp-caption mt-3 mt-sm-0"
                  id="slide-3204-layer-3"
                  data-x="['left','left','left','left']"
                  data-hoffset="['364','114','76','26']"
                  data-y="['top','top','top','top']"
                  data-voffset="['302','352','235','225']"
                  data-width="['100%','100%','100%','100%']"
                  data-fontsize="['82','82','60','60']"
                  data-lineheight="['82','82','60','60']"
                  data-height="none"
                  data-whitespace="normal"
                  data-type="text"
                  data-basealign="slide"
                  data-responsive_offset="on"
                  data-responsive="off"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":1500,"to":"o:1;","delay":650,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  // style={{zIndex: '7', fontSize: '45px !important', lineHeight: '81px', fontWeight: '300', color: 'rgba(255, 255, 255, 1)', display: 'block', fontFamily: 'Source Sans Pro, sans-serif'}}>
                  //     <span className="d-sm-none" style={{fontSize: '45px', fontWeight: 'bolder', lineHeight: '41px', fontFamily:'Source Sans Pro, sansSerif'}}>Experience the<br/> Real</span>
                  //     <span className="d-none d-sm-inline"  style={{zIndex: '7', fontSize: '45px !important', lineHeight: '81px', fontWeight: '300', color: 'rgba(255, 255, 255, 1)', display: 'block', fontFamily: 'Source Sans Pro, sans-serif'}}> Experience The Real</span>
                  // <span className="d-sm-none font-weight-bold" style={{fontSize: '45px', color: '#1fa12e', lineHeight: '41px', fontFamily:'Source Sans Pro, sansSerif'}}> Agriculture</span> </div>
                  style={{
                    zIndex: "7",
                    fontSize: "45px !important",
                    lineHeight: "81px",
                    fontWeight: "300",
                    color: "rgba(255, 255, 255, 1)",
                    display: "block",
                    fontFamily: "Source Sans Pro, sans-serif",
                  }}
                >
                  <span
                    className="d-sm-none"
                    style={{
                      fontSize: "45px",
                      fontWeight: "bolder",
                      fontFamily: "Source Sans Pro, sansSerif",
                      display: "inline",
                    }}
                  >
                    Experience the
                    <br /> Real
                  </span>
                  <span
                    className="d-none d-sm-inline"
                    style={{
                      zIndex: "7",
                      fontSize: "45px !important",
                      lineHeight: "41px",
                      fontWeight: "300",
                      color: "rgba(255, 255, 255, 1)",
                      display: "block",
                      fontFamily: "Source Sans Pro, sans-serif",
                    }}
                  >
                    {" "}
                    Experience The Real
                  </span>
                  <span
                    className="d-sm-none font-weight-bold"
                    style={{
                      fontSize: "45px",
                      color: "#1fa12e",
                      lineHeight: "41px",
                      fontFamily: "Source Sans Pro, sansSerif",
                    }}
                  >
                    {" "}
                    Agriculture
                  </span>{" "}
                </div>

                <div
                  className="tp-caption  d-none d-sm-block"
                  id="slide-3204-layer-4"
                  data-x="['left','left','left','left']"
                  data-hoffset="['364','118','76','26']"
                  data-y="['top','top','top','top']"
                  data-voffset="['382','272','305','295']"
                  data-width="['100%','100%','100%','100%']"
                  data-fontsize="['82','82','60','60']"
                  data-lineheight="['82','82','60','60']"
                  data-height="none"
                  data-whitespace="normal"
                  data-type="text"
                  data-basealign="slide"
                  data-responsive_offset="on"
                  data-responsive="off"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":1500,"to":"o:1;","delay":650,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "7",
                    fontSize: "82px",
                    lineHeight: "82px",
                    fontWeight: "700",
                    color: "rgba(255, 255, 255, 1)",
                    display: "block",
                    fontFamily: "Source Sans Pro, sansSerif",
                  }}
                >
                  Agriculture
                </div>

                <Link
                  className="tp-caption rev-btn clv_btn d-sm-none mt-5"
                  to="/"
                  id="slide-3204-layer-5"
                  data-x="['left','left','left','left']"
                  data-hoffset="['364','114','76','26']"
                  data-y="['top','top','top','top']"
                  data-voffset="['489','550','385','376']"
                  data-fontweight="['500','500','500','500']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="button"
                  data-basealign="slide"
                  data-responsive_offset="on"
                  data-responsive="off"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":1500,"to":"o:1;","delay":800,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[45,45,45,45]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[45,45,45,45]"
                  style={{ zIndex: "8", whiteSpace: "nowrap" }}
                >
                  read more
                </Link>

                <Link
                  className="tp-caption rev-btn clv_btn d-none d-sm-block mt-4"
                  to="/"
                  id="slide-3204-layer-5"
                  data-x="['left','left','left','left']"
                  data-hoffset="['364','114','76','26']"
                  data-y="['top','top','top','top']"
                  data-voffset="['489','550','385','376']"
                  data-fontweight="['500','500','500','500']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="button"
                  data-basealign="slide"
                  data-responsive_offset="on"
                  data-responsive="off"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":1500,"to":"o:1;","delay":800,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[45,45,45,45]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[45,45,45,45]"
                  style={{ zIndex: "8", whiteSpace: "nowrap" }}
                >
                  read more
                </Link>
              </li>
              <li
                data-index="rs-3205"
                data-transition="slideoververtical"
                data-slotamount="default"
                data-hideafterloop="0"
                data-hideslideonmobile="off"
                data-easein="default"
                data-easeout="default"
                data-masterspeed="default"
                data-thumb="../../assets/images/news2-1-100x50.jpg"
                data-rotate="0"
                data-saveperformance="off"
                data-title=""
                data-param1=""
                data-param2=""
                data-param3=""
                data-param4=""
                data-param5=""
                data-param6=""
                data-param7=""
                data-param8=""
                data-param9=""
                data-param10=""
                data-description=""
              >
                <img
                  src={heroImage2}
                  alt="image"
                  data-lazyload=""
                  data-bgposition="center bottom"
                  data-bgfit="cover"
                  data-bgrepeat="no-repeat"
                  data-bgparallax="off"
                  className="rev-slidebg"
                  data-no-retina
                />

                <div
                  className="tp-caption d-none d-sm-block"
                  id="slide-3205-layer-1"
                  data-x="['left','left','left','left']"
                  data-hoffset="['364','114','76','26']"
                  data-y="['top','top','top','top']"
                  data-voffset="['262','252','195','185']"
                  data-fontsize="['20','20','20','20']"
                  data-lineheight="['22','22','22','22']"
                  data-width="['700','700','700','700']"
                  data-height="none"
                  data-whitespace="normal"
                  data-type="text"
                  data-basealign="slide"
                  data-responsive_offset="on"
                  data-responsive="off"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":1500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "5",
                    whiteSpace: "nowrap",
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "#1fa12e",
                    display: "inline-block",
                    fontFamily: "Source Sans Pro, sans-serif",
                    letterSpacing: "3px",
                  }}
                >
                  A New Way To Invest In Agriculture
                </div>

                <div
                  className="tp-caption d-sm-none"
                  id="slide-3205-layer-1"
                  data-x="['left','left','left','left']"
                  data-hoffset="['364','114','76','26']"
                  data-y="['top','top','top','top']"
                  data-voffset="['262','252','195','185']"
                  data-fontsize="['20','20','20','20']"
                  data-lineheight="['22','22','22','22']"
                  data-width="['700','700','700','700']"
                  data-height="none"
                  data-whitespace="normal"
                  data-type="text"
                  data-basealign="slide"
                  data-responsive_offset="on"
                  data-responsive="off"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":1500,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "5",
                    whiteSpace: "nowrap",
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "#1fa12e",
                    display: "inline-block",
                    fontFamily: "Source Sans Pro, sans-serif",
                    letterSpacing: "3px",
                  }}
                >
                  A New Way To Invest In
                  <br /> Agriculture
                </div>

                <div
                  className="tp-caption tp-shape tp-shapewrapper "
                  id="slide-3205-layer-2"
                  data-x="['center','center','center','center']"
                  data-hoffset="['0','0','0','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['0','0','0','0']"
                  data-width="full"
                  data-height="full"
                  data-whitespace="normal"
                  data-type="shape"
                  data-basealign="slide"
                  data-responsive_offset="off"
                  data-responsive="off"
                  data-frames='[{"delay":10,"speed":2000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                  data-textalign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{ zIndex: "1", backgroundColor: "rgba(0,0,0,0.70)" }}
                >
                  {" "}
                </div>

                <div
                  className="tp-caption mt-sm-0"
                  id="slide-3205-layer-3"
                  data-x="['left','left','left','left']"
                  data-hoffset="['364','114','76','26']"
                  data-y="['top','top','top','top']"
                  data-voffset="['302','352','235','225']"
                  data-width="['100%','100%','100%','100%']"
                  // data-width="['100%','100%','100%','100%']"
                  data-fontsize="['82','82','60','60']"
                  data-height="none"
                  data-whitespace="normal"
                  data-type="text"
                  data-basealign="slide"
                  data-responsive_offset="on"
                  data-responsive="off"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":1500,"to":"o:1;","delay":650,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "7",
                    fontSize: "45px !important",
                    lineHeight: "81px",
                    fontWeight: "300",
                    color: "rgba(255, 255, 255, 1)",
                    display: "block",
                    fontFamily: "Source Sans Pro, sans-serif",
                  }}
                >
                  <span
                    className="d-sm-none"
                    style={{
                      fontSize: "45px",
                      fontWeight: "bolder",
                      fontFamily: "Source Sans Pro, sansSerif",
                    }}
                  >
                    Experience the
                    <br /> Real{" "}
                  </span>
                  <span
                    className="d-none d-sm-inline"
                    style={{
                      zIndex: "7",
                      fontSize: "45px !important",
                      lineHeight: "41px",
                      fontWeight: "300",
                      color: "rgba(255, 255, 255, 1)",
                      display: "block",
                      fontFamily: "Source Sans Pro, sans-serif",
                    }}
                  >
                    {" "}
                    Experience The Real
                  </span>
                  <span
                    className="d-sm-none font-weight-bold"
                    style={{
                      fontSize: "45px",
                      color: "#1fa12e",
                      lineHeight: "41px",
                      fontFamily: "Source Sans Pro, sansSerif",
                    }}
                  >
                    {" "}
                    Agriculture
                  </span>{" "}
                </div>

                <div
                  className="tp-caption  d-none d-sm-block"
                  id="slide-3205-layer-4"
                  data-x="['left','left','left','left']"
                  data-hoffset="['364','114','76','26']"
                  data-y="['top','top','top','top']"
                  data-voffset="['382','272','305','295']"
                  data-width="['100%','100%','100%','100%']"
                  data-fontsize="['82','82','60','60']"
                  data-lineheight="['82','82','60','60']"
                  data-height="none"
                  data-whitespace="normal"
                  data-type="text"
                  data-basealign="slide"
                  data-responsive_offset="on"
                  data-responsive="off"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":1500,"to":"o:1;","delay":650,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: "7",
                    fontSize: "82px",
                    lineHeight: "82px",
                    fontWeight: "700",
                    color: "rgba(255, 255, 255, 1)",
                    display: "block",
                    fontFamily: "Source Sans Pro, sans-serif",
                  }}
                >
                  Agriculture
                </div>

                <Link
                  className="tp-caption rev-btn clv_btn d-sm-none mt-5"
                  to="/"
                  id="slide-3205-layer-5"
                  data-x="['left','left','left','left']"
                  data-hoffset="['364','114','76','26']"
                  data-y="['top','top','top','top']"
                  data-voffset="['489','550','385','376']"
                  data-fontweight="['500','500','500','500']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="button"
                  data-basealign="slide"
                  data-responsive_offset="on"
                  data-responsive="off"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":1500,"to":"o:1;","delay":800,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[45,45,45,45]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[45,45,45,45]"
                  style={{ zIndex: "8", whiteSpace: "nowrap" }}
                >
                  read more
                </Link>

                <Link
                  className="tp-caption rev-btn clv_btn d-none d-sm-block mt-4"
                  to="/"
                  id="slide-3205-layer-5"
                  data-x="['left','left','left','left']"
                  data-hoffset="['364','114','76','26']"
                  data-y="['top','top','top','top']"
                  data-voffset="['489','550','385','376']"
                  data-fontweight="['500','500','500','500']"
                  data-width="none"
                  data-height="none"
                  data-whitespace="nowrap"
                  data-type="button"
                  data-basealign="slide"
                  data-responsive_offset="on"
                  data-responsive="off"
                  data-frames='[{"from":"y:50px;opacity:0;","speed":1500,"to":"o:1;","delay":800,"ease":"Power4.easeOut"},{"delay":"wait","speed":300,"to":"opacity:0;","ease":"nothing"}]'
                  data-textalign="['left','left','left','left']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[45,45,45,45]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[45,45,45,45]"
                  style={{ zIndex: "8", whiteSpace: "nowrap" }}
                >
                  read more
                </Link>
              </li>
            </ul>
            <div
              className="tp-bannertimer tp-bottom"
              style={{ visibility: "hidden !important" }}
            ></div>
          </div>
        </div>
      </div>
      {/* <!--Form Section--> */}
      <div className="clv_about_wrapper clv_section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              {/* <!--SignIn Popup--> */}
              {/* <div className="signin_wrapper"> */}
              <div className={classes.resetWrapper}>
                <div className={`${classes.resetInner} mt-5`}>
                  <div className={classes.resetDetails}>
                    <div className={classes.siteLogo}>
                      {/* <Link to="/"> <img src={signinImg1} alt="image"/></Link> */}
                      <Link className={classes.signinHeader} to="/">
                        HOPE CONCEPT
                      </Link>
                    </div>
                    <h3>
                      welcome to{" "}
                      <span style={{ textTransform: "none" }}>e</span>Agric360!
                    </h3>
                    <p>
                      HOPE CONCEPT COOPERATIVE is a cooperative registered in
                      Ogun State is a Social, Business and Pressure Group
                      focused on community based development in the
                      Manufacturing, Micro, Small and Medium Scale Enterprises
                      in the Agricultural and Solid Minerals value chain in
                      Nigeria.{" "}
                    </p>
                    {/* <a href="javascript:;" className="clv_btn white_btn pop_signup">sign up</a> */}
                    {/* <Link to="" className="clv_btn white_btn pop_signup">sign up</Link> */}
                    <ul>
                      <li>
                        <Link className={classes.socials} to="/">
                          <span>
                            <i
                              className="fa fa-facebook"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link className={classes.socials} to="/">
                          <span>
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link className={classes.socials} to="/">
                          <span>
                            <i
                              className="fa fa-linkedin"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link className={classes.socials} to="/">
                          <span>
                            <i
                              className="fa fa-youtube-play"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className={classes.resetFormSection}>
                    <h4>Super_Admin LogIn</h4>
                    <img src={signupImg2} alt="image" />
                    <br />
                    <br />
                    {/* <ForgotPasswordForm /> */}
                    <SuperLoginForm />
                    {/* <Button className={classes.Services} onClick={servicesHandler}>Service Login</Button> */}

                    <br />
                    <Link to="/forgot-Password"></Link>
                    <div className="social_button_section">
                      <Link to="/" className={fb_btnCl.join(" ")}>
                        <span>
                          <img src={signupImg3} alt="image" />
                        </span>
                        <span>facebook</span>
                      </Link>
                      <Link to="/" className={goo_btnCl.join(" ")}>
                        <span>
                          <img src={signupImg4} alt="image" />
                        </span>
                        <span>google+</span>
                      </Link>
                    </div>

                    <span
                      className={classes.successClose}
                      onClick={handleCloseLoginModal}
                    >
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 212.982 212.982"
                        style={{ enableBackground: "new 0 0 212.982 212.982" }}
                        width="11px"
                        height="11px"
                      >
                        <g>
                          <path
                            fill="#fec007"
                            style={{ fillRule: "evenodd", clipRule: "evenodd" }}
                            d="M131.804,106.491l75.936-75.936c6.99-6.99,6.99-18.323,0-25.312
                                                    c-6.99-6.99-18.322-6.99-25.312,0l-75.937,75.937L30.554,5.242c-6.99-6.99-18.322-6.99-25.312,0c-6.989,6.99-6.989,18.323,0,25.312
                                                    l75.937,75.936L5.242,182.427c-6.989,6.99-6.989,18.323,0,25.312c6.99,6.99,18.322,6.99,25.312,0l75.937-75.937l75.937,75.937
                                                    c6.989,6.99,18.322,6.99,25.312,0c6.99-6.99,6.99-18.322,0-25.312L131.804,106.491z"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default SuperLogin;
