import * as actionTypes from './actionTypes';

export const getRoles = () => {
    return {
        type: actionTypes.ROLES_CONSTANTS.GET_ROLES
    };
}

export const getRolesStarts = () => {
    return {
        type: actionTypes.ROLES_CONSTANTS.GET_ROLES_STARTS
    };
}

export const getRolesSuccess = (rolesData, rolesDataTable) => {
    return {
        type: actionTypes.ROLES_CONSTANTS.GET_ROLES_SUCCESS,
        roles: rolesData,
        rolesDataTable: rolesDataTable
    };
}

export const getRolesFails = (error) => {
    return {
        type: actionTypes.ROLES_CONSTANTS.GET_ROLES_FAILS,
        error: error
    };
}

export const createRole = (data) => {
    return {
        type: actionTypes.ROLES_CONSTANTS.CREATE_ROLE,
        role: data
    };
}

export const createRoleStarts = () => {
    return {
        type: actionTypes.ROLES_CONSTANTS.CREATE_ROLE_STARTS
    };
}

export const createRoleSuccess = () => {
    return {
        type: actionTypes.ROLES_CONSTANTS.CREATE_ROLE_SUCCESS
    };
}

export const createRoleFails = (error) => {
    return {
        type: actionTypes.ROLES_CONSTANTS.CREATE_ROLE_FAILS,
        error: error
    };
}

export const editRole = (data) => {
    return {
        type: actionTypes.ROLES_CONSTANTS.EDIT_ROLE,
        roleId: data
    };
}

export const editRoleStarts = () => {
    return {
        type: actionTypes.ROLES_CONSTANTS.EDIT_ROLE_STARTS
    };
}

export const editRoleSuccess = (data) => {
    return {
        type: actionTypes.ROLES_CONSTANTS.EDIT_ROLE_SUCCESS,
        roleData: data
    };
}

export const editRoleFails = (error) => {
    return {
        type: actionTypes.ROLES_CONSTANTS.EDIT_ROLE_FAILS,
        error: error
    };
}

export const editRoleSaved = (data) => {
    return {
        type: actionTypes.ROLES_CONSTANTS.EDIT_ROLE_SAVED,
        role: data
    };
}

export const editRoleSavedSuccess = () => {
    return {
        type: actionTypes.ROLES_CONSTANTS.EDIT_ROLE_SAVED_SUCCESS,
    };
}

export const deleteRole = (data) => {
    return{
        type: actionTypes.ROLES_CONSTANTS.DELETE_ROLE,
        roleId: data
    }
}