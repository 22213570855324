import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import InputBase from '@material-ui/core/InputBase';
import api from '../../../../../middlewares/axiosConfig';
import moment from 'moment';



const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '5px 26px 5px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);


const useStyles = makeStyles(theme => ({
    root: {
      height: '100%'
    },
    content: {
      padding: '0 !important',
      marginTop: '7px',
      paddingBottom: '20px !important'
    },
    titleStyle: {
        fontWeight: 600,
        lineHeight: '30px',
        fontSize: '16px',
        color: '#0e0e0e'
    },
    avatarStyle: {
        border: '1px solid #c5ccc5',
        // fontSize: '30px',
        borderRadius: '5px',
        color: '#4a4c49'
    },
    margin: {
      margin: theme.spacing(0),
    },

  }));


const FarmDetails = () => {
  const classes = useStyles();
  
const [farmDetail, setFarmDetail] = useState([ {
  year: moment().year()-3,
  totalFarmCount: 50,
  totalPartitionFarm: 50,
  totalAllocatedFarm: 50
},
{
  year: moment().year()-2,
  totalFarmCount: 200,
  totalPartitionFarm: 300,
  totalAllocatedFarm: 200
},

{
  year: moment().year()-1,
  totalFarmCount: 500,
  totalPartitionFarm: 620,
  TotalAllocated: 750
},
{
  year: moment().year(),
  totalFarmCount: 0,
  totalPartitionFarm: 0,
  totalAllocatedFarm: 0,
  
},
]);


useEffect(async()=>{
  let res = await api.get(`Dashboard/GetAllFarmsYearlyDetails`)
  let data = res.data;

   setFarmDetail(data);
},[])


  return (
    <Card className={clsx(classes.root)} className="shadow">
      <CardHeader title="Farm Details" avatar={<DescriptionIcon />} classes={{title: classes.titleStyle, avatar: classes.avatarStyle}} />
        <Divider />
          <CardContent className={classes.content} >
          <ResponsiveContainer width="100%" aspect={2}>
              <BarChart
                  width={500}
                  height={300}
                  data={farmDetail}
                  margin={{
                    top: 5,
                    right: 40,
                    left: 25,
                    bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" name="Year"/>
                  <YAxis />
                    <Tooltip />
                      <Legend /> 
                        <Bar dataKey="totalFarmCount" fill="#008000" name='Total Farm'/>
                          <Bar dataKey="totalPartitionFarm" fill="#c1a439" name='Partitioned Farm' />
              <Bar dataKey= "totalAllocatedFarm" fill="#01579b" name='Allocated Farm'/>
            </BarChart>
            </ResponsiveContainer>
            </CardContent>
        </Card>
    
  )
}

export default FarmDetails