/** @format */

import { combineReducers } from "redux";

import { reducer as toastrReducer } from "react-redux-toastr";
import sessionReducer from "./sessionReducer";
import loginReducer from "./loginReducer";
import farmerReducer from "./farmerReducer";
import adminReducer from "./adminReducer";
import farmsReducer from "./farmsReducer";
import setupReducer from "./setupReducer";
import genericReducer from "./genericReducer";
import rolesReducer from "./rolesReducer";
import setupMgtReducer from "./setupMgtReducer";
import farmPartitionReducer from "./partitionFarmReducer";
import createAccountReducer from "./createAccountReducer";
import partnerReducer from "./partnerReducer";
import coopAdminReducer from "./coopAdminReducer";
import superAdminReducer from "./superAdminReducer";
import paymentReducer from "./paymentReducer";
import paymentCategoryReducer from "./paymentcategoryReducer";

const rootReducer = combineReducers({
  toastr: toastrReducer,
  session: sessionReducer,
  loginReducer,
  farmerReducer,
  adminReducer,
  farmsState: farmsReducer,
  setupReducer,
  genericReducer,
  rolesReducer,
  setupMgtReducer,
  farmPartitionReducer,
  createAccountReducer,
  partnerReducer,
  coopAdminReducer,
  superAdminReducer,
  paymentReducer,
  paymentCategoryReducer,
  // superAdmin,
});

export default rootReducer;
