/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

/* eslint-disable react-hooks/exhaustive-deps */
/**
 * /* eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  CircularProgress,
  Container,
  Typography,
  FormControl,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import api from "../../../../../middlewares/axiosConfig";
import CalendarTodayIcon from "@material-ui/icons/CalendarTodayOutlined";
import { Grid, ButtonGroup } from "@material-ui/core";
import { PARTNERS_CONSTANTS } from "../../../../../redux/actions/actionTypes";
import { getAllCooperativeSociety } from "../../../../../redux/actions";
import * as actions from "../../../../../redux/actions";
import InputBase from "@material-ui/core/InputBase";
import { Label } from "../../../../../components/Admin";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  dates: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  startDateButton: {
    marginRight: theme.spacing(1),
  },
  endDateButton: {
    marginLeft: theme.spacing(1),
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const { className, ...rest } = props;
  const animatedComponents = makeAnimated();
  const [cooperativeSocietyName, setCooperativeSocietyName] = useState([]);
  const [cooperativeSocietyId, setCooperativeSocietyId] = useState();
  const FarmerData = useSelector((state) => state.loginReducer.farmerData);
  const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [selectEdge, setSelectEdge] = useState(null);
  const [calendarDate, setCalendarDate] = useState(moment());
  const partnerState = useSelector((state) => state.loginReducer.farmerData);
  const dispatch = useDispatch();
  // const [partnerDetailId, setPartnerDetailId] = useState(1);

  // const handleChange = (e) => {
  //   setCooperativeSocietyId(e.target.value);
  // };

  const handleCalendarChange = (date) => {
    setCalendarDate(date);
  };

  const handleCalendarClose = () => {
    setCalendarDate(moment());
    setSelectEdge(null);
  };

  useEffect(() => {
    if (partnerState.corporateSocietyName != null) {
      if (partnerState.corporateSocietyName.length > 0) {
        setCooperativeSocietyId(partnerState.corporateSocietyName[0].value);
      }
    }
  }, [partnerState.corporateSocietyName]);

  useEffect(() => {
    console.log(cooperativeSocietyId);
  }, [cooperativeSocietyId]);

  const handleCalendarAccept = (date) => {
    setCalendarDate(moment());

    if (selectEdge === "start") {
      setStartDate(date);

      if (moment(date).isAfter(endDate)) {
        setEndDate(date);
      }
    } else {
      setEndDate(date);

      if (moment(date).isBefore(startDate)) {
        setStartDate(date);
      }
    }

    setSelectEdge(null);
  };

  const open = Boolean(selectEdge);

  const date = new Date();
  const hour = date.getHours();
  const temporal = {
    calenda: {
      marginTop: "-3.5rem",
      padding: "3rem",
    },
    select: {
      height: "auto",
      width: "30%",
    },
  };

  function partnerName(e) {
    setCooperativeSocietyId(e.value);
    dispatch(
      actions.loginSuccess({
        currentlySelectedCooperative: e.value,
      })
    );
  }

  return (
    //{greeting the user using his/her system time with the user first name}
    <Container className={classes.root}>
      <div {...rest} className={clsx(className)}>
        <Typography component="h1" gutterBottom variant="h3">
          {hour >= 12
            ? hour >= 16
              ? "Good Evening"
              : "Good Afternoon"
            : "Good Morning"}{" "}
          {FarmerData.partnerName}
        </Typography>
      </div>

      <Grid
        className={classes.dates}
        item
        sm={4}
        // sm={2}
        style={temporal.calenda}
      >
        <ButtonGroup variant="contained">
          <Button style={{ cursor: "none" }}>
            <CalendarTodayIcon className={classes.calendarTodayIcon} />
            {endDate.format("DD MM YYYY")}
          </Button>
        </ButtonGroup>
      </Grid>
      <DatePicker
        maxDate={moment()}
        onAccept={handleCalendarAccept}
        onChange={handleCalendarChange}
        onClose={handleCalendarClose}
        open={open}
        style={{ display: "none" }} // Temporal fix to hide the input element
        value={calendarDate}
        variant="dialog"
      />

      <section style={temporal.select}>
        <ReactSelect
          className="basic-single"
          classNamePrefix="select"
          isSearchable={true}
          name="partnerInput"
          options={partnerState.corporateSocietyName}
          components={animatedComponents}
          // value={partnerState.currentlySelectedCooperative}
          value={
            partnerState.corporateSocietyName?.filter(
              (a) => a.value == cooperativeSocietyId
            )[0]
          }
          onChange={(e) => partnerName(e)}
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        />

        <FormHelperText>Select a Co-operati</FormHelperText>
      </section>
    </Container>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

Header.defaultProps = {};

export default Header;
