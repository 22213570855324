import { SETUP_CONSTANTS } from '../actions/actionTypes';

const initialState = {
    detailedTowns: [],
    detailedCropTypes: [],
    detailedProduces: [],
    detailedFarmTypes: [],
    detailedInventories: []

};

const setupReducer = (state = {...initialState}, action) => {
    switch (action.type) {
      case SETUP_CONSTANTS.SET_DETAILED_TOWNS:
        return {...state, detailedTowns: action.payload}

      case SETUP_CONSTANTS.SET_DETAILED_CROPTYPES:
        return {...state, detailedCropTypes: action.payload}
  
      case SETUP_CONSTANTS.SET_DETAILED_PRODUCES:
        return {...state, detailedProduces: action.payload}
  
      
      case SETUP_CONSTANTS.SET_DETAILED_FARMTYPES:
        return {...state, detailedFarmTypes: action.payload}

        case SETUP_CONSTANTS.SET_DETAILED_INVENTORIES:
          return {...state, detailedInventories: action.payload}
      
      
      default:
        return state;
    }
  }

export default setupReducer;