/** @format */

import { PAYMENT_CONSTANTS } from "./actionTypes";

export const paymentAction = () => {
  return {
    type: PAYMENT_CONSTANTS.GET_PAYMENTS,
  };
};

export const paymentActionsStarts = (id) => {
  return {
    type: PAYMENT_CONSTANTS.GET_PAYMENTS_START,
    payload: { id },
  };
};

export const paymentActionsSuccess = (paymentData) => {
  return {
    type: PAYMENT_CONSTANTS.GET_PAYMENTS_SUCCESS,
    payment: paymentData,
  };
};

export const paymentActionsFail = (error) => {
  return {
    type: PAYMENT_CONSTANTS.GET_PAYMENTS_FAIL,
    error: error,
  };
};

export const getFarmersByCooperativeId = (coopId) => {
  return {
    type: PAYMENT_CONSTANTS.GET_ALL_FARMERS,
    payload: { coopId },
  };
};

export const getFarmersByCooperativeIdSuccess = (data) => {
  return {
    type: PAYMENT_CONSTANTS.GET_ALL_FARMERS_SUCCESS,
    payload: data,
  };
};

export const getFarmersByCooperativeFail = (error) => {
  return {
    type: PAYMENT_CONSTANTS.GET_ALL_FARMERS_FAIL,
    payload: error,
  };
};
