/** @format */

import React from "react";
import { Link } from "react-router-dom";

import footerBrand from "../../../assets/images/footer_logo2.png";
import footerImage1 from "../../../assets/images/footer_underline.png";
import footerImg1 from "../../../assets/images/agric/footerImg1.jpg";
import Cookies from "js-cookie";

function Footer(props) {
  let currentLoggedInUser =
    Cookies.getJSON("farmerData") ||
    Cookies.getJSON("adminData") ||
    Cookies.getJSON("superAdminData");

  return (
    <>
      <div className="clv_footer_wrapper clv_section">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-lg-3">
              <div className="footer_block">
                <h1 style={{ color: "#fff" }}>
                  Hope <span style={{ color: "green" }}>Concept</span>
                </h1>
                {/* <div className="footer_logo"><Link to="/"><img src={footerBrand} alt="image" /></Link></div> */}
                {/* <p>eAgric is your home</p>
							<p>eAgric feed the nations</p> */}
              </div>
            </div>
            <div className="col-md-3 col-lg-3">
              <div className="footer_block">
                <div className="footer_heading">
                  <h4>recent post</h4>
                  <img src={footerImage1} alt="image" />
                  <div className="footer_slider_arrows">
                    <span className="footer_arrow footer_left">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6px"
                        height="10px"
                      >
                        <path
                          fillRule="evenodd"
                          fill="rgb(182, 182, 182)"
                          d="M0.215,5.448 L4.736,9.733 C5.023,10.007 5.489,10.007 5.777,9.733 C6.064,9.462 6.064,9.020 5.777,8.747 L1.777,4.954 L5.777,1.161 C6.064,0.888 6.064,0.447 5.777,0.174 C5.489,-0.098 5.023,-0.098 4.735,0.174 L0.215,4.461 C0.071,4.598 -0.000,4.776 -0.000,4.954 C-0.000,5.133 0.072,5.312 0.215,5.448 Z"
                        />
                      </svg>
                    </span>
                    <span className="footer_arrow footer_right">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="7px"
                        height="10px"
                      >
                        <path
                          fillRule="evenodd"
                          fill="rgb(182, 182, 182)"
                          d="M6.409,5.448 L1.885,9.733 C1.598,10.007 1.131,10.007 0.844,9.733 C0.556,9.462 0.556,9.020 0.844,8.747 L4.846,4.954 L0.844,1.161 C0.556,0.888 0.556,0.447 0.844,0.174 C1.131,-0.098 1.598,-0.098 1.886,0.174 L6.410,4.461 C6.553,4.598 6.625,4.776 6.625,4.954 C6.625,5.133 6.553,5.312 6.409,5.448 Z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className="footer_post_slider">
                  <div className="swiper-container">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="footer_post_slide">
                          <div className="footer_post_image">
                            {/* <img src={footerImg1} alt="image" /> */}
                          </div>
                          <div className="footer_post_content">
                            <span>jan 06, 2020</span>
                            <p>
                              <Link to="/">
                                How neem cells produce how useful chemicals
                                paste.
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="footer_post_slide">
                          <div className="footer_post_image"></div>
                          <div className="footer_post_content">
                            <span>jan 06, 2019</span>
                            <p>
                              <Link to="/">
                                How Maize production has come to improve our
                                nation economics.
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="footer_post_slide">
                          <div className="footer_post_image"></div>
                          <div className="footer_post_content">
                            <span>jan 06, 2021</span>
                            <p>
                              <Link to="/">
                                How Maize production has come to improve our
                                nation economics.
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="footer_post_slide">
                          <div className="footer_post_image">
                            {/* <img src={footerImg1} alt="image" /> */}
                          </div>
                          <div className="footer_post_content">
                            <span>jan 06, 2019</span>
                            <p>
                              <Link to="/">
                                How Maize production has come to improve our
                                nation economics.
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3">
              <div className="footer_block">
                <div className="footer_heading">
                  <h4>services</h4>
                  <img src={footerImage1} alt="footer 1" />
                </div>
                <ul className="useful_links">
                  <li>
                    <Link to="/agric-investment">
                      <span>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </span>
                      Agric Investment
                    </Link>
                  </li>
                  <li>
                    <Link to="/credit-assistance">
                      <span>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </span>
                      Credit Assistance
                    </Link>
                  </li>
                  <li>
                    <Link to="/farm-allocation">
                      <span>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </span>
                      Farmland Allocation
                    </Link>
                  </li>
                  <li>
                    <Link to="/farm-management">
                      <span>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </span>
                      Farm Management
                    </Link>
                  </li>
                  <li>
                    <Link to="/marketing">
                      <span>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </span>
                      Marketing
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-lg-3">
              <div className="footer_block">
                <div className="footer_heading">
                  <h4>contact</h4>
                  <img src={footerImage1} alt="footer 1" />
                </div>
                <p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16px"
                      height="16px"
                    >
                      <defs>
                        <filter id="Filter_0">
                          <feFlood
                            floodColor="rgb(31, 161, 46)"
                            floodOpacity="1"
                            result="floodOut"
                          />
                          <feComposite
                            operator="atop"
                            in="floodOut"
                            in2="SourceGraphic"
                            result="compOut"
                          />
                          <feBlend
                            mode="normal"
                            in="compOut"
                            in2="SourceGraphic"
                          />
                        </filter>
                      </defs>
                      <g filter="url(#Filter_0)">
                        <path
                          fillRule="evenodd"
                          fill="rgb(81, 176, 30)"
                          d="M14.873,0.856 C14.815,0.856 14.700,0.856 14.643,0.913 L0.850,6.660 C0.620,6.776 0.505,6.948 0.505,7.176 C0.505,7.465 0.677,7.695 0.965,7.752 L6.942,9.189 C7.057,9.189 7.114,9.305 7.172,9.419 L8.608,15.396 C8.666,15.626 8.896,15.855 9.183,15.855 C9.413,15.855 9.643,15.683 9.700,15.511 L15.447,1.718 C15.447,1.660 15.505,1.603 15.505,1.488 C15.447,1.085 15.217,0.856 14.873,0.856 ZM9.355,8.902 L9.068,7.695 C9.011,7.465 8.838,7.350 8.666,7.292 L7.459,7.005 C7.172,6.948 7.172,6.545 7.401,6.487 L11.022,4.993 C11.252,4.878 11.482,5.109 11.424,5.395 L9.930,9.017 C9.758,9.189 9.413,9.131 9.355,8.902 Z"
                        />
                      </g>
                    </svg>
                  </span>
                  Head Office: 12, Michael Whenu Avenue, Ojokoro, Ijaiye, Lagos,
                  Nigeria
                </p>
                <p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16px"
                      height="15px"
                    >
                      <defs>
                        <filter id="Filter_0">
                          <feFlood
                            floodColor="rgb(31, 161, 46)"
                            floodOpacity="1"
                            result="floodOut"
                          />
                          <feComposite
                            operator="atop"
                            in="floodOut"
                            in2="SourceGraphic"
                            result="compOut"
                          />
                          <feBlend
                            mode="normal"
                            in="compOut"
                            in2="SourceGraphic"
                          />
                        </filter>
                      </defs>
                      <g filter="url(#Filter_0)">
                        <path
                          fillRule="evenodd"
                          fill="rgb(81, 176, 30)"
                          d="M13.866,7.235 C13.607,5.721 12.892,4.344 11.802,3.254 C10.653,2.108 9.197,1.381 7.592,1.156 L7.755,-0.002 C9.613,0.257 11.296,1.096 12.626,2.427 C13.888,3.692 14.716,5.284 15.019,7.039 L13.866,7.235 ZM10.537,4.459 C11.296,5.222 11.796,6.181 11.977,7.238 L10.824,7.436 C10.684,6.617 10.300,5.874 9.713,5.287 C9.091,4.666 8.304,4.276 7.439,4.155 L7.601,2.996 C8.719,3.151 9.734,3.657 10.537,4.459 ZM4.909,8.182 C5.709,9.162 6.611,10.033 7.689,10.711 C7.920,10.854 8.176,10.960 8.417,11.092 C8.538,11.160 8.623,11.139 8.723,11.035 C9.088,10.661 9.460,10.293 9.831,9.924 C10.318,9.440 10.931,9.440 11.421,9.924 C12.017,10.516 12.614,11.110 13.207,11.707 C13.704,12.207 13.701,12.818 13.201,13.324 C12.864,13.665 12.505,13.989 12.186,14.345 C11.721,14.866 11.140,15.035 10.472,14.997 C9.500,14.944 8.607,14.623 7.745,14.205 C5.831,13.275 4.194,11.985 2.823,10.355 C1.808,9.150 0.971,7.834 0.422,6.355 C0.153,5.639 -0.038,4.906 0.022,4.129 C0.059,3.651 0.237,3.242 0.590,2.907 C0.971,2.546 1.330,2.168 1.705,1.800 C2.192,1.319 2.804,1.319 3.295,1.797 C3.598,2.093 3.894,2.396 4.194,2.696 C4.485,2.988 4.775,3.277 5.065,3.570 C5.578,4.085 5.578,4.684 5.069,5.197 C4.703,5.565 4.341,5.933 3.969,6.293 C3.873,6.390 3.863,6.468 3.913,6.586 C4.160,7.173 4.513,7.694 4.909,8.182 Z"
                        />
                      </g>
                    </svg>
                  </span>
                  +2348154338473
                </p>
                <p>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16px"
                      height="16px"
                    >
                      <defs>
                        <filter id="Filter_0">
                          <feFlood
                            floodColor="rgb(31, 161, 46)"
                            floodOpacity="1"
                            result="floodOut"
                          />
                          <feComposite
                            operator="atop"
                            in="floodOut"
                            in2="SourceGraphic"
                            result="compOut"
                          />
                          <feBlend
                            mode="normal"
                            in="compOut"
                            in2="SourceGraphic"
                          />
                        </filter>
                      </defs>
                      <g filter="url(#Filter_0)">
                        <path
                          fillRule="evenodd"
                          fill="rgb(81, 176, 30)"
                          d="M16.000,5.535 C16.000,4.982 15.680,4.507 15.280,4.191 L8.000,-0.002 L0.720,4.191 C0.320,4.507 0.000,4.982 0.000,5.535 L0.000,13.447 C0.000,14.317 0.720,15.028 1.600,15.028 L14.400,15.028 C15.280,15.028 16.000,14.317 16.000,13.447 L16.000,5.535 ZM8.000,9.491 L1.360,5.376 L8.000,1.579 L14.640,5.376 L8.000,9.491 Z"
                        />
                      </g>
                    </svg>
                  </span>
                  hopeconcept@org
                </p>
                <ul className="agri_social_links">
                  <li>
                    <a
                      href="http://www.facebook.com/hopeconceptng"
                      target="_blank"
                    >
                      <span>
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.twitter.com/hopeconceptng"
                      target="_blank"
                    >
                      <span>
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.instagram.com/hopeconceptng"
                      target="_blank"
                    >
                      <span>
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://www.youtube.com/hopeconceptcoorperative"
                      target="_blank"
                    >
                      <span>
                        <i
                          className="fa fa-youtube-play"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clv_copyright_wrapper">
        {/* <p>copyright &copy; 2019 <a href="javascript:;">cultivation.</a> all right reserved.</p> */}
        <p>
          copyright &copy; {new Date().getFullYear()}{" "}
          <a href="">Hope Concept.</a> all right reserved.
        </p>
        {currentLoggedInUser ? null : (
          <p style={{ cursor: "pointer" }} onClick={props.openAdminLogin}>
            Admin login
          </p>
        )}
      </div>
    </>
  );
}

export default Footer;
