/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

/* eslint-disable react-hooks/exhaustive-deps */
/**
 * /* eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/**
 * /* eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

/** @format */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { green } from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  container: {
    marginTop: "3rem",
  },
  header: {
    fontSize: "1.7rem",
    marginBottom: "1rem",
    fontWeight: 500,
    textTransform: "uppercase",
  },
  headerDiv: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  headerButton: {
    outline: "none",
    "&:focus": {
      outline: "none",
    },
    background: "#2E7D32",
    color: "#fff",
    "&:hover": {
      background: "#fff",
      color: "#2E7D32",
      outline: "#2E7D32 solid 1px",
    },
  },
  Dialog: {
    "& *:focus": {
      outline: "none",
    },
  },
  gridContainer: {
    "& > div": {
      padding: ".5rem",
    },
  },
  DialogTitle: {
    "& h2": {
      fontSize: "1.5rem",
    },
  },
  successButton: {
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  none: {
    display: "none",
  },
  block: {
    display: "block",
  },
  image: {
    width: "100%",
  },
}));

function AddUser(props) {
  const classes = useStyles();

  return (
    <>
      <DialogTitle id="customized-dialog-title" className={classes.DialogTitle}>
        {`Success payment`}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Paper
          elevation={1}
          color="primary"
          sx={{ width: "100%", height: "100%", padding: 4 }}
        >
          <div className="d-flex flex-column align-items-center justify-content-center text-center">
            <CheckCircleTwoToneIcon sx={{ fontSize: 80 }} color="success" />
            {/* <Typography variant="h4" gutterBottom gutterTop>
              Payment Successful
            </Typography> */}
            <Typography variant="h4" gutterBottom gutterTop>
              Check Your email to get your login details and Identification
              Number.
            </Typography>
          </div>
        </Paper>
      </DialogContent>
    </>
  );
}

export default AddUser;
