/** @format */

import axios from "axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import { setAdminLogout } from "../redux/actions/adminActions";
import * as actions from "../redux/actions";

export const clientUrl =
  process.env.NODE_ENV !== "production"
    ? "https://localhost:44390/api/"
    : "https://hopeconceptcooperative.azurewebsites.net/api/";

const axiosInstance = axios.create({
  baseURL: clientUrl,
});

const token =
  Cookies.getJSON("farmerData") ||
  Cookies.getJSON("adminData") ||
  Cookies.getJSON("partnerData") ||
  Cookies.getJSON("superAdminData") ||
  Cookies.getJSON("serviceProviderData");

if (token) {
  let decodedToken = jwt_decode(token.token);
  // console.log(decodedToken);
  if (Date.now() >= decodedToken.exp * 1000) {
    // let expirationTime = new Date(decodedToken.exp * 1000);
    // let expirationDate = expirationTime.toString();

    Cookies.remove("farmerData");
    Cookies.remove("adminData");
    Cookies.remove("partnerData");
    Cookies.remove("superAdminData");
    Cookies.remove("serviceProviderData");
    // dispatch(setAdminLogout());
    // dispatch(actions.logout());
    window.location.href = "/";
  }
}

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${
  typeof token == "undefined" || token == null ? "" : token.token
}`;
axiosInstance.defaults.headers.common["Access-Control-Allow-Origin"] = `*`;
axiosInstance.defaults.headers.common[
  "Access-Control-Allow-Methods"
] = `GET, POST, OPTIONS`;
axiosInstance.defaults.headers.common["Cache-Control"] = `no-store`;

axiosInstance.interceptors.request.use(
  async (request) => {
    let isConnected = navigator.onLine;

    if (isConnected) {
      return request;
    } else {
      Swal.fire(
        "Not Online",
        "Please check your network connectivity",
        "error"
      );
    }

    // Edit request config
    // return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Edit response config
    return response;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export default axiosInstance;
