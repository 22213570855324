/** @format */

import * as actionTypes from "./actionTypes";

export const farmerLogin = (farmerData) => {
  return {
    type: actionTypes.LOGIN_CONSTANTS.LOGIN_FARMER,
    email: farmerData.emailORPhoneNumber,
    password: farmerData.password,
  };
};

export const partnerLogin = (farmerData) => {
  return {
    type: actionTypes.LOGIN_CONSTANTS.LOGIN_PARTNER,
    email: farmerData.emailORPhoneNumber,
    password: farmerData.password,
  };
};

export const serviceProviderLogin = (farmerData) => {
  return {
    type: actionTypes.LOGIN_CONSTANTS.LOGIN_SERVICE_PROVIDER,
    email: farmerData.emailORPhoneNumber,
    password: farmerData.password,
  };
};

export const superAdminLogin = (farmerData) => {
  return {
    type: actionTypes.LOGIN_CONSTANTS.SUPER_ADMIN_LOGIN,
    email: farmerData.email,
    password: farmerData.password,
  };
};

export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_CONSTANTS.LOGIN_START,
  };
};

export const loginSuccess = (farmerData) => {
  return {
    type: actionTypes.LOGIN_CONSTANTS.LOGIN_SUCCESS,
    farmerData: { ...farmerData },
  };
};

export const setLoginRedirectPath = (path) => {
  return {
    type: actionTypes.LOGIN_CONSTANTS.SET_LOGIN_REDIRECT_PATH,
    path: path,
  };
};

export const loginFail = (error) => {
  return {
    type: actionTypes.LOGIN_CONSTANTS.LOGIN_FAIL,
    error: error,
  };
};

export const loggedinCheckState = () => {
  return {
    type: actionTypes.LOGIN_CONSTANTS.LOGGEDIN_CHECK_STATE,
  };
};

export const checkTokenTimeout = (expirationTime) => {
  return {
    type: actionTypes.LOGIN_CONSTANTS.TOKEN_CHECK_TIMEOUT,
    expirationTime: expirationTime,
  };
};

export const logout = () => {
  return {
    type: actionTypes.LOGIN_CONSTANTS.INITIATE_LOGOUT,
  };
};

export const logoutSuccess = () => {
  return {
    type: actionTypes.LOGIN_CONSTANTS.LOGOUT,
  };
};
