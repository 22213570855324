/** @format */

import { SUPER_ADMIN_COOPERATIVE_CONSTANTS } from "./actionTypes";
import * as actionTypes from "./actionTypes";

export const getCooperatives = () => {
  return {
    type: actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.GET_COOPERATIVES,
  };
};

export const getCooperativesStart = () => {
  return {
    type: actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.GET_COOPERATIVES_START,
  };
};

export const getCooperativesSuccess = (cooperativesData) => {
  return {
    type: actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS
      .GET_COOPERATIVES_SUCCESS,
    payload: cooperativesData,
  };
};

export const getCooperativesFail = (error) => {
  return {
    type: actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.GET_COOPERATIVES_FAIL,
    error: error,
  };
};

export const addCooperative = (data) => {
  return {
    type: actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.ADD_COOPERATIVE,
    cooperative: data,
  };
};

export const addCooperativesStart = () => {
  return {
    type: actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.ADD_COOPERATIVE_START,
  };
};

export const addCooperativesSuccess = () => {
  return {
    type: actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.ADD_COOPERATIVE_SUCCESS,
  };
};

export const addCooperativesFail = (error) => {
  return {
    type: actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.ADD_COOPERATIVE_FAIL,
    error: error,
  };
};

export const editCooperatives = (data) => {
  return {
    type: actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.EDIT_COOPERATIVE,
    cooperativesData: data,
  };
};
export const editReset = (data) => {
  return {
    type: actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.EDIT_RESET,
  };
};

export const editCooperativeStart = (data) => {
  return {
    type: actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.EDIT_COOPERATIVE_START,
    cooperativesData: data,
  };
};

export const editCooperativeSuccess = () => {
  return {
    type: actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS
      .EDIT_COOPERATIVE_SUCCESS,
  };
};

export const editCooperativeFail = (error) => {
  return {
    type: actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.EDIT_COOPERATIVE_FAIL,
    error: error,
  };
};

export const deleteCooperative = (data) => {
  return {
    type: actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.DELETE_COOPERATIVE,
    cooperativeSocietyId: data,
  };
};

export const setCooperatives = (cooperatives) => (dispatch) => {
  dispatch({
    type: SUPER_ADMIN_COOPERATIVE_CONSTANTS.SET_COOPERATIVES,
    payload: cooperatives,
  });
};

export const setUnfilteredCooperatives = (cooperatives) => (dispatch) => {
  dispatch({
    type: SUPER_ADMIN_COOPERATIVE_CONSTANTS.SET_UNFILTERED_COOPERATIVES,
    payload: cooperatives,
  });
};
