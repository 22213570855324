import { put } from 'redux-saga/effects';
import api from '../../middlewares/axiosConfig';
import {toastr} from 'react-redux-toastr';


import * as actions from '../actions';

export function* getPartitionedFarmsSaga() {
    try{
        yield put(actions.getPartitionedFarmsStart());
        const response = yield api.get('FarmPartition/GetAllFarmPartition');
        let farms = response.data;
        yield put(actions.getPartitionedFarmsSuccess(farms));
    }catch(error){
        toastr.error("Error", "An error occured while trying to get farms", {
            onCloseButtonClick: true
        });
        yield put(actions.getPartitionedFarmsFail(error));
    }
}

export function* getUnPartitionedFarmsSaga() {
    try{
        yield put(actions.getUnPartitionedFarmsStart());
        const response = yield api.get('FarmPartition/UnPartitionedFarms');
        let farms = response.data;
        yield put(actions.getUnPartitionedFarmsSuccess(farms));
    }catch(error){
        toastr.error("Error", "An error occured while trying to get farms", {
            onCloseButtonClick: true
        });
        yield put(actions.getUnPartitionedFarmsFail(error));
    }
}


export function* createFarmPartitionSaga(data) {
    try{
        yield put(actions.createFarmPartitionStarts());
        let response = yield api.post(`FarmPartition/CreateFarmPartition`, data.farmData);
        toastr.success( `Success`, response.data.message, {
            closeButton: true,
        });
        yield put(actions.createFarmPartitionSuccess());
        yield put(actions.getPartitionedFarms());
    }catch(error){
        toastr.error("Error", "An error occured while creating partition", {
            onCloseButtonClick: true
        });
        yield put(actions.createFarmPartitionFails(error));
    }
}

export function* editPartitionedFarmSaga(data) {
    try{
        yield put(actions.getPartitionedFarmStarts());
        let response = yield api.get(`FarmPartition/GetFarmPartitionByFarmId/${data.farmId}`);
        yield put(actions.getPartitionedFarmSuccess(response.data));
    }catch(error){
        toastr.error("Error", "An error occured while getting farm", { 
            onCloseButtonClick: true
        });
        yield put(actions.getPartitionedFarmFails(error));
    }
}

export function* editPartitionedFarmStartsSaga(data) {
    try{
        let response = yield api.patch(`FarmPartition/UpdateFarmPartition/`, data.farmData);
        yield put(actions.editPartitionedFarmSuccess());
        if(response.data.isSuccess){
            toastr.success( `Success`, response.data.message, {
                closeButton: true,
            });
        }else{
            toastr.warning( `Error`, response.data.message, {
                closeButton: true,
            });
        }
        yield put(actions.getPartitionedFarms()); 
    }catch(error){
        toastr.error("Error", "An error occured while editing farm", {
            onCloseButtonClick: true
        });
        yield put(actions.editPartitionedFarmFails(error));
    }
}

export function* deletePartitionedFarmSaga(farmData) {
    try{
        let response = yield api.delete(`FarmPartition/DeleteFarmPartition/${farmData.farmId}`);
        if(response.data.isSuccess === false){
            toastr.warning( `Error`, response.data.message, {
                closeButton: true,
            });
        }else{
            toastr.success( `Success`, response.data.message, {
                closeButton: true,
            });
        }
        yield put(actions.getPartitionedFarms());
    }catch(error){
        toastr.error("Error", "An error occured deleting farm partition", {
            onCloseButtonClick: true
        });
    }
}