import { FARMER_CONSTANTS } from './actionTypes';
import api from '../../middlewares/axiosConfig';
import * as actionTypes from './actionTypes';

export const getFarmers = () => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.GET_FARMERS
    };
}

export const getFarmersStart = () => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.GET_FARMERS_START
    };
}

export const getFarmersSuccess = (farmersData) => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.GET_FARMERS_SUCCESS,
        farmersData: farmersData,
    };
}

export const getFarmersFail = (error) => {
  return{
    type: actionTypes.FARMERS_CONSTANTS.GET_FARMERS_FAIL,
    error: error
  };
}

export const createFarmer = (data) => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.CREATE_FARMER,
        farmerData: data
    };
}

export const createFarmerStarts = () => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.CREATE_FARMER_STARTS
    };
}

export const createFarmerSuccess = () => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.CREATE_FARMER_SUCCESS
    };
}

export const createFarmerFails = (error) => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.CREATE_FARMER_FAILS,
        error: error
    };
}

export const editFarmer = (data) => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.EDIT_FARMER,
        farmerId: data
    };
}

export const getFarmerStarts = () => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.GET_FARMER_STARTS
    };
}

export const getFarmerSuccess = (data) => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.GET_FARMER_SUCCESS,
        farmerData: data
    };
}

export const getFarmerFails = (error) => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.GET_FARMER_FAILS,
        error: error
    };
}

export const editFarmerStarts = (data) => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.EDIT_FARMER_STARTS,
        farmerData: data
    };
}

export const editFarmerSuccess = () => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.EDIT_FARMER_SUCCESS,
    };
}

export const editFarmerFails = (error) => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.EDIT_FARMER_FAILS,
        error: error
    };
}

export const deleteFarmer = (data) => {
    return{
        type: actionTypes.FARMERS_CONSTANTS.DELETE_FARMER,
        farmerId: data
    }
}



export const getFarmersPassportCaptured = () => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.GET_FARMERS_PASSPORT_CAPTURED
    };
}

export const getFarmersPassportCapturedStart = () => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.GET_FARMERS_PASSPORT_CAPTURED_START
    };
}

export const getFarmersPassportCapturedSuccess = (farmersData) => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.GET_FARMERS_PASSPORT_CAPTURED_SUCCESS,
        farmersData: farmersData,
    };
}

export const getFarmersPassportCapturedFail = (error) => {
  return{
    type: actionTypes.FARMERS_CONSTANTS.GET_FARMERS_PASSPORT_CAPTURED_FAIL,
    error: error
  };
}

export const getFarmersBiometricsCaptured = () => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.GET_FARMERS_BIOMETRIC_CAPTURED
    };
}

export const getFarmersBiometricsCapturedStart = () => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.GET_FARMERS_BIOMETRIC_CAPTURED_STARTS
    };
}

export const getFarmersBiometricsCapturedSuccess = (farmersData) => {
    return {
        type: actionTypes.FARMERS_CONSTANTS.GET_FARMERS_BIOMETRIC_CAPTURED_SUCCESS,
        farmersData: farmersData,
    };
}

export const getFarmersBiometricsCapturedFail = (error) => {
  return{
    type: actionTypes.FARMERS_CONSTANTS.GET_FARMERS_BIOMETRIC_CAPTURED_FAILS,
    error: error
  };
}



export const setFarmers = farmers => dispatch => {
  dispatch({
    type: FARMER_CONSTANTS.SET_FARMERS,
    payload: farmers
  });
}

export const setUnfilteredFarmers = farmers => dispatch => {
  dispatch({
    type: FARMER_CONSTANTS.SET_UNFILTERED_FARMERS,
    payload: farmers
  });
}

// export const getFarmers = () => async (dispatch) => {
//   let farmers = await api.get("Farmer").then(res => res.data);
  
//   dispatch(setUnfilteredFarmers(farmers));
// }
