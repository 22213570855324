/** @format */

import React, { useEffect, useState } from "react";
import api from "../../../../../middlewares/axiosConfig";
import { toastr } from "react-redux-toastr";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Card, Typography, Grid, colors } from "@material-ui/core";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import LandscapeIcon from "@material-ui/icons/Landscape";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import GroupsIcon from "@mui/icons-material/Groups";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  item: {
    padding: theme.spacing(3),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      "&:not(:last-of-type)": {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&:not(:last-of-type)": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  valueContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    marginLeft: theme.spacing(1),
  },
}));

const Overview = (props) => {
  const { className, ...rest } = props;
  const [individualFarmer, setIndividualFarmer] = useState(0);
  const [totalFarmLand, setTotalFarmLand] = useState(0);
  const [verifiedFarmer, setVerifiedFarmer] = useState(0);
  const [totalCooperative, setTotalCooperative] = useState(0);
  const classes = useStyles();

  //{Total cooperative  is displayed on load}
  useEffect(async () => {
    try {
      const { data } = await api.get(`PortalAdmin/GetCooperativeSocietyCount`);
      setTotalCooperative(data);
    } catch (error) {
      toastr.error("Error", "Sorry Could Not Load Get All Farmland Allocation");
    }
  }, []);

  //{Total farmer accross all cooperatives is diplayed on load}
  useEffect(async () => {
    try {
      const { data } = await api.get(`PortalAdmin/GetFarmersCount`);
      setIndividualFarmer(data);
    } catch (error) {
      toastr.error("Error", "Sorry Could Not Load Get All Total Farmland");
    }
  }, []);

  // To display all verified Farmers accross all cooperatives
  useEffect(async () => {
    try {
      const { data } = await api.get(`Dashboard/SuperAdminVerifiedFarmers`);
      setVerifiedFarmer(data);
    } catch (error) {
      toastr.error("Error", "Sorry Could Not Load Get All Verified Farmers");
    }
  }, []);

  // To display total number of number of Farm land across all cooperatives
  useEffect(async () => {
    try {
      const { data } = await api.get(`Dashboard/GetTotalFarmland`);
      setTotalFarmLand(data);
    } catch (error) {
      toastr.error("Error", "Sorry Could Not Load Get All Farmland");
    }
  }, []);

  const data = {
    individualFarmer: "1,051",
    verifiedFarmer: "2",
    totalCooperative: "3",
    allocatedFarms: "0",
    totalFarmLand: "9",
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justify="space-between">
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Link to={"super_admin/cooperative"}>
            <Typography component="h2" gutterBottom variant="overline">
              Co-Operative Society
            </Typography>

            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {totalCooperative}
                <GroupsIcon
                  style={{
                    color: green[500],
                    fontSize: 30,
                    marginLeft: 10,
                    marginBottom: 10,
                  }}
                />
              </Typography>
            </div>
          </Link>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Link to={"super_admin/registeredFarmer"}>
            <Typography component="h2" gutterBottom variant="overline">
              Registered Farmers
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {individualFarmer}
                <HowToRegIcon
                  style={{
                    color: green[500],
                    fontSize: 30,
                    marginleft: 10,
                    marginBottom: 10,
                  }}
                />
              </Typography>
            </div>
          </Link>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Link to={"super_admin/verifiedFarmer"}>
            <Typography component="h2" gutterBottom variant="overline">
              {" "}
              Total Verified Farmer
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {verifiedFarmer}
                <VerifiedUserIcon
                  style={{
                    color: green[500],
                    fontSize: 30,
                    marginLeft: 10,
                    marginBottom: 10,
                  }}
                />
              </Typography>
            </div>
          </Link>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Link to={"super_admin/register-farm"}>
            <Typography component="h2" gutterBottom variant="overline">
              {" "}
              Total Farmlands
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {totalFarmLand}
                <LandscapeIcon
                  style={{
                    color: green[500],
                    fontSize: 30,
                    marginLeft: 10,
                    marginBottom: 10,
                  }}
                />
              </Typography>
            </div>
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

Overview.propTypes = {
  className: PropTypes.string,
};

export default Overview;
