/**
 * /* eslint-disable no-unused-vars
 *
 * @format
 */

import React, { useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  Input,
  colors,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ClickAwayListener,
  Typography,
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/LockOutlined";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";

import axios from "../../../../utils/axios";
import useRouter from "../../../../utils/useRouter";
import {
  PricingModal,
  NotificationsPopover,
} from "../../../../components/Admin";
import { logout } from "../../../../redux/actions";
import { setAdminLogout } from "../../../../redux/actions/adminActions";
import * as actions from "../../../../redux/actions";
import Popover from "@material-ui/core/Popover";
import Avatar from "@material-ui/core/Avatar";
// import { Link } from 'react-router';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  search: {
    backgroundColor: "rgba(255,255,255, 0.1)",
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center",
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: "#fff",
  },
  searchInput: {
    flexGrow: 1,
    color: "#fff",
    "& input::placeholder": {
      opacity: 1,
      color: "#d3d3d3",
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
  },
  trialIcon: {
    marginRight: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
    color: "#fff",
    width: "8rem",
    height: "2rem",
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  title: {
    fontWeight: 500,
    fontSize: "1.5rem",
    color: "#fff",
    marginLeft: ".5rem",
  },
  appBar: {
    background: colors.green[800],
  },
  aLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
  },
  formControl: {
    margin: theme.spacing(0.2),
    minWidth: 10,
  },
  selectEmpty: {
    marginTop: theme.spacing(0.2),
  },

  wordT: {
    whiteSpace: "nowrap",
    paddingTop: "20px",
    marginLeft: "-5px",
  },

  typography: {
    padding: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  recolor: {
    color: "#263238",
    "&:hover": {
      color: "green",
      textDecoration: "none",
    },
  },
}));

const TopBar = (props) => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { history } = useRouter();
  const searchRef = useRef(null);
  const dispatch = useDispatch();
  const notificationsRef = useRef(null);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [openSearchPopover, setOpenSearchPopover] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);

  useEffect(() => {
    let mounted = true;

    return () => {
      mounted = false;
    };
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleLogout = () => {
    dispatch(actions.logout());
    window.location.href = "/";
    // history.push('/');
    // history.push('/');
  };

  const handlePricingOpen = () => {
    setPricingModalOpen(true);
  };

  const handlePricingClose = () => {
    setPricingModalOpen(false);
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchPopover) {
        setOpenSearchPopover(true);
      }
    } else {
      setOpenSearchPopover(false);
    }
  };

  const handleSearchPopverClose = () => {
    setOpenSearchPopover(false);
  };

  const popularSearches = [
    "Devias React Dashboard",
    "Devias",
    "Admin Pannel",
    "Project",
    "Pages",
  ];

  const toLandingPage = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    let homeLogo = document.querySelector("#homeLogo");
    let homeLogo2 = document.querySelector("#homeLogo2");
    homeLogo && homeLogo.addEventListener("click", toLandingPage);
    homeLogo2 && homeLogo2.addEventListener("click", toLandingPage);

    return () => {
      homeLogo && homeLogo.removeEventListener("click", toLandingPage);
      homeLogo2 && homeLogo2.removeEventListener("click", toLandingPage);
    };
  }, []);

  return (
    <AppBar {...rest} className={clsx(classes.root, className, classes.appBar)}>
      <Toolbar>
        {/* <RouterLink to="/" className={classes.aLink} style={{textDecoration: 'none'}}> */}
        <img
          alt="Logo"
          src="/images/logos/eagricIcon.png"
          width="30"
          id="homeLogo"
          style={{ cursor: "pointer" }}
        />
        <Typography
          variant="h6"
          className={classes.title}
          id="homeLogo2"
          style={{ cursor: "pointer" }}
        >
          {" "}
          eAgric-360{" "}
        </Typography>
        {/* </RouterLink> */}
        <div className={classes.flexGrow} />

        <div>
          <IconButton
            aria-describedby={id}
            variant="contained"
            className={classes.cols}
            onClick={handleClick}
          >
            <Avatar src="/broken-image.jpg" className={classes.resize} />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography onClick={handleLogout} className={classes.typography}>
              Sign Out
            </Typography>
            <Typography className={classes.typography}>
              <Link to="/farmer/change-Password" className={classes.recolor}>
                {" "}
                Change Password{" "}
              </Link>
            </Typography>
            <Typography className={classes.typography}>
              <Link to="/farmer/my-Profile" className={classes.recolor}>
                User Profile
              </Link>
            </Typography>
          </Popover>
        </div>

        {/* </Hidden> */}
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
