/** @format */

// import React, {useState, Fragment } from 'react';
import { Typography } from "@material-ui/core";
import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Button, Grid, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Popover from "@mui/material/Popover";
import Cookies from "js-cookie";
import { createBrowserHistory } from "history";

import navBrand from "../../../assets/images/agri_product.png";
import CreateAccount from "./createAccount";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./header.css";
import Tooltip from "@material-ui/core/Tooltip";
import RegisterFarmersForm from "./Register";
import { createTheme, ThemeProvider, useTheme } from "@material-ui/core/styles";
import CreorRegFarmer from "./cre-regAccount/RegAccOption";

const Classes = makeStyles((theme) => ({
  navImage: {
    width: "50px",
  },
  root: {
    "& .MuiPaper-root": {
      width: "100%",
    },
    // "& .MuiGrid-item": {
    //   padding: "4px",
    // },
  },
  [theme.breakpoints.down("sm")]: {
    backgroundColor: "red",
  },
  navTitle: {
    fontSize: "1.2rem",
    fontWeight: 600,
    marginTop: ".5rem",
    marginBottom: 0,
    color: "#1FA12E",
  },
  navType: {
    fontSize: "1rem",
    letterSpacing: "3px",
    textTransform: "uppercase",
  },
  hamburgerMenu: {
    fontSize: "25px",
  },
}));

const Header = () => {
  const classes = Classes();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());
  const [isCreateAccountVisible, setIsCreateAccountVisible] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [loggedInAccountType, setLoggedInAccountType] = useState(null);
  let history = createBrowserHistory({ forceRefresh: true });
  const [open2, setOpen2] = useState(false);
  const [opens, setOpens] = useState(false);

  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  const handleReg = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  function OptionDialog() {
    setOpens(true);
  }
  function OptionDialogCl() {
    setOpens(false);
  }

  useEffect(() => {
    let menuToggle = document.querySelector(".menu_toggle");

    setIsCreateAccountVisible(
      getComputedStyle(menuToggle).display === "none" ? false : true
    );
  }, [windowWidth]);

  useEffect(() => {
    let createButttonMobile;
    let createButttonMobile2;
    if (isCreateAccountVisible) {
      createButttonMobile = document.querySelector(
        "#createAccountMobileButton"
      );
      createButttonMobile2 = document.querySelector(
        "#createAccountMobileButton2"
      );
      if (createButttonMobile) {
        createButttonMobile.addEventListener("click", OptionDialog);
      }
      if (createButttonMobile2) {
        createButttonMobile2.addEventListener("click", OptionDialog);
      }
    }

    return () => {
      if (createButttonMobile) {
        createButttonMobile.removeEventListener("click", OptionDialog);
      }
      if (createButttonMobile2) {
        createButttonMobile2.removeEventListener("click", OptionDialog);
      }
    };
  }, [isCreateAccountVisible]);

  useEffect(() => {
    let farmerData = Cookies.getJSON("farmerData");
    let adminData = Cookies.getJSON("adminData");
    let superAdminData = Cookies.getJSON("superAdminData");

    if (farmerData || adminData || superAdminData) {
      setIsUserLoggedIn(true);
      if (farmerData) {
        setLoggedInAccountType("/farmer");
      } else if (adminData) {
        setLoggedInAccountType("/admin");
      } else {
        setLoggedInAccountType("/super_admin");
      }

      // farmerData
      //   ? setLoggedInAccountType("/farmer")
      //   : setLoggedInAccountType("/admin");
    }
  }, []);

  const sett = {
    recolor: {
      color: "#263238",
      "&:hover": {
        color: "green",
        textDecoration: "none",
      },
    },
  };

  const toMyDashboard = () => {
    history.push(`${loggedInAccountType}`);
  };

  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWindowWidth(getWindowWidth()), 50);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);
    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  function getWindowWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  const popovergaller = {
    dropbtn: {
      backgroundColor: "#04AA6D",
      color: "white",
      padding: "16px",
      fontSize: "16px",
      border: "none",
      cursor: "pointer",
    },
  };

  return (
    <Fragment>
      <div className="clv_header">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-2" style={{ paddingLeft: 0 }}>
              <div className="clv_left_header">
                <div className="clv_logo" style={{ textAlign: "center" }}>
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <img
                      src={navBrand}
                      alt="Cultivation"
                      className={classes.navImage}
                    />
                    <Typography variant="h6" className={classes.navTitle}>
                      HOPE CONCEPT
                    </Typography>
                    <Typography variant="caption" className={classes.navType}>
                      Cooperative
                    </Typography>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-10 col-md-10">
              <div className="d-lg-none d-flex flex-column">
                <div className="clv_right_header">
                  <div className="clv_address">
                    <div className="row">
                      {/* <div className="col-md-4">
									    	<div className="address_block">
										    	<span className="addr_icon">
											    	<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="16px">
											        	<defs>
											            	<filter id="Filter_0">
												            	<feFlood floodColor="rgb(31, 161, 46)" floodOpacity="1" result="floodOut" />
												            	<feComposite operator="atop" in="floodOut" in2="SourceGraphic" result="compOut" />
												            	<feBlend mode="normal" in="compOut" in2="SourceGraphic" />
											            	</filter>
											        	</defs>
											        	<g filter="url(#Filter_0)">
											            	<path fillRule="evenodd"  fill="rgb(83, 175, 30)" 
                                                            d="M5.530,-0.004 C2.497,-0.004 0.029,2.434 0.029,5.431 C0.029,9.148 4.951,14.609 5.161,14.839 C5.358,15.055 5.702,15.054 5.898,14.839 C6.108,14.609 11.029,9.148 11.029,5.431 C11.029,2.434 8.562,-0.004 5.530,-0.004 ZM5.530,8.165 C4.004,8.165 2.762,6.937 2.762,5.431 C2.762,3.923 4.004,2.696 5.530,2.696 C7.055,2.696 8.297,3.923 8.297,5.431 C8.297,6.937 7.055,8.165 5.530,8.165 Z"/>
											        	</g>
											    	</svg>
										    	</span>
										    
                                            	<div className="header_location">
											    	<h5>Our location</h5>
											    	<p>512, Beand square, Ogun State</p>
										    	</div>
									    	</div>
								    	</div> */}

                      <div className="col-md-4">
                        {/* <div className="address_block">
												<span className="addr_icon">
													<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="15px">
														<defs>
															<filter id="Filter_0">
																<feFlood floodColor="rgb(31, 161, 46)" floodOpacity="1" result="floodOut" />
																<feComposite operator="atop" in="floodOut" in2="SourceGraphic" result="compOut" />
																<feBlend mode="normal" in="compOut" in2="SourceGraphic" />
															</filter>
														</defs>
															<g filter="url(#Filter_0)">
																<path fillRule="evenodd"  fill="rgb(0, 0, 0)"
																	d="M13.866,7.234 C13.607,5.720 12.892,4.343 11.802,3.254 C10.653,2.108 9.197,1.379 7.592,1.155 L7.754,-0.003 C9.613,0.254 11.296,1.095 12.626,2.426 C13.888,3.689 14.716,5.282 15.019,7.037 L13.866,7.234 ZM10.537,4.459 C11.296,5.220 11.796,6.178 11.977,7.238 L10.824,7.434 C10.684,6.617 10.300,5.872 9.713,5.286 C9.091,4.665 8.304,4.274 7.439,4.151 L7.601,2.995 C8.719,3.150 9.734,3.657 10.537,4.459 ZM4.909,8.180 C5.709,9.161 6.611,10.032 7.689,10.710 C7.920,10.853 8.176,10.959 8.416,11.090 C8.538,11.159 8.623,11.137 8.722,11.033 C9.088,10.660 9.459,10.290 9.831,9.922 C10.318,9.439 10.931,9.439 11.421,9.922 C12.017,10.516 12.614,11.109 13.207,11.706 C13.704,12.206 13.701,12.816 13.201,13.323 C12.864,13.664 12.505,13.987 12.186,14.344 C11.721,14.865 11.140,15.034 10.471,14.996 C9.500,14.942 8.607,14.622 7.745,14.203 C5.831,13.272 4.194,11.983 2.823,10.354 C1.808,9.148 0.971,7.834 0.421,6.353 C0.153,5.638 -0.038,4.905 0.022,4.127 C0.059,3.649 0.237,3.241 0.590,2.907 C0.971,2.544 1.330,2.168 1.705,1.798 C2.192,1.317 2.804,1.317 3.295,1.795 C3.598,2.093 3.894,2.394 4.194,2.694 C4.485,2.988 4.775,3.274 5.065,3.569 C5.578,4.083 5.578,4.683 5.069,5.194 C4.703,5.564 4.341,5.931 3.969,6.290 C3.872,6.388 3.863,6.466 3.913,6.585 C4.160,7.172 4.513,7.694 4.909,8.180 Z"/>
															</g>
													</svg>
												</span>
												<div className="header_location">
													<h5>Phone Number</h5>
													<p>( +234 ) 80-944-09-567</p>
												</div>
											</div> */}
                      </div>

                      <div className="col-md-4">
                        {/* <div className="address_block br_none">
												<span className="addr_icon">
													<svg xmlns="http://www.w3.org/2000/svg" width="20px" height="15px">
														<defs>
															<filter id="Filter_0">
																<feFlood floodColor="rgb(31, 161, 46)" floodOpacity="1" result="floodOut" />
																<feComposite operator="atop" in="floodOut" in2="SourceGraphic" result="compOut" />
																<feBlend mode="normal" in="compOut" in2="SourceGraphic" />
															</filter>
														</defs>
														<g filter="url(#Filter_0)">
															<path fillRule="evenodd"  fill="rgb(0, 0, 0)"
																d="M14.468,8.297 C15.479,7.588 16.707,6.742 18.151,5.760 C18.554,5.483 18.907,5.174 19.211,4.833 L19.211,13.062 L14.468,8.297 ZM17.398,4.619 C15.683,5.816 14.388,6.719 13.509,7.333 C13.026,7.670 12.667,7.920 12.436,8.082 C12.402,8.106 12.349,8.146 12.278,8.196 C12.201,8.251 12.105,8.321 11.986,8.407 C11.756,8.574 11.565,8.709 11.413,8.811 C11.261,8.915 11.077,9.031 10.862,9.159 C10.646,9.286 10.443,9.382 10.252,9.445 C10.061,9.510 9.885,9.541 9.722,9.541 L9.711,9.541 L9.701,9.541 C9.538,9.541 9.362,9.510 9.171,9.445 C8.980,9.382 8.777,9.286 8.561,9.159 C8.345,9.031 8.161,8.915 8.010,8.811 C7.858,8.709 7.667,8.574 7.437,8.407 C7.318,8.321 7.221,8.251 7.145,8.196 C7.074,8.146 7.020,8.106 6.986,8.082 C6.690,7.872 6.332,7.622 5.916,7.329 C5.428,6.989 4.861,6.591 4.208,6.138 C3.000,5.297 2.275,4.791 2.035,4.619 C1.597,4.322 1.183,3.912 0.794,3.389 C0.406,2.868 0.211,2.382 0.211,1.937 C0.211,1.382 0.358,0.920 0.651,0.551 C0.945,0.181 1.363,-0.004 1.908,-0.004 L17.515,-0.004 C17.974,-0.004 18.372,0.164 18.708,0.498 C19.043,0.832 19.211,1.233 19.211,1.701 C19.211,2.262 19.038,2.798 18.692,3.310 C18.346,3.821 17.914,4.258 17.398,4.619 ZM4.956,8.295 L0.211,13.062 L0.211,4.833 C0.522,5.181 0.879,5.491 1.282,5.760 C2.783,6.784 4.006,7.629 4.956,8.295 ZM6.551,9.435 C6.954,9.732 7.281,9.965 7.532,10.132 C7.783,10.299 8.117,10.469 8.534,10.644 C8.951,10.817 9.340,10.903 9.700,10.903 L9.711,10.903 L9.721,10.903 C10.082,10.903 10.471,10.817 10.888,10.644 C11.305,10.469 11.639,10.299 11.890,10.132 C12.141,9.965 12.468,9.732 12.871,9.435 C12.989,9.348 13.114,9.258 13.243,9.165 L18.626,14.573 C18.312,14.853 17.942,14.995 17.515,14.995 L1.908,14.995 C1.481,14.995 1.110,14.853 0.796,14.573 L6.180,9.165 C6.314,9.262 6.439,9.352 6.551,9.435 Z"/>
														</g>
													</svg>
												</span>
												<div className="header_location">
													<h5>email address</h5>
													<p>hopeconcept@example.com</p>
												</div> 
											</div> */}
                      </div>

                      <div className="col-md-4">
                        <div className="address_block">
                          <span className="addr_icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16px"
                              height="15px"
                            >
                              <defs>
                                <filter id="Filter_0">
                                  <feFlood
                                    floodColor="rgb(31, 161, 46)"
                                    floodOpacity="1"
                                    result="floodOut"
                                  />
                                  <feComposite
                                    operator="atop"
                                    in="floodOut"
                                    in2="SourceGraphic"
                                    result="compOut"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in="compOut"
                                    in2="SourceGraphic"
                                  />
                                </filter>
                              </defs>
                              <g filter="url(#Filter_0)">
                                <path
                                  fillRule="evenodd"
                                  fill="rgb(0, 0, 0)"
                                  d="M13.866,7.234 C13.607,5.720 12.892,4.343 11.802,3.254 C10.653,2.108 9.197,1.379 7.592,1.155 L7.754,-0.003 C9.613,0.254 11.296,1.095 12.626,2.426 C13.888,3.689 14.716,5.282 15.019,7.037 L13.866,7.234 ZM10.537,4.459 C11.296,5.220 11.796,6.178 11.977,7.238 L10.824,7.434 C10.684,6.617 10.300,5.872 9.713,5.286 C9.091,4.665 8.304,4.274 7.439,4.151 L7.601,2.995 C8.719,3.150 9.734,3.657 10.537,4.459 ZM4.909,8.180 C5.709,9.161 6.611,10.032 7.689,10.710 C7.920,10.853 8.176,10.959 8.416,11.090 C8.538,11.159 8.623,11.137 8.722,11.033 C9.088,10.660 9.459,10.290 9.831,9.922 C10.318,9.439 10.931,9.439 11.421,9.922 C12.017,10.516 12.614,11.109 13.207,11.706 C13.704,12.206 13.701,12.816 13.201,13.323 C12.864,13.664 12.505,13.987 12.186,14.344 C11.721,14.865 11.140,15.034 10.471,14.996 C9.500,14.942 8.607,14.622 7.745,14.203 C5.831,13.272 4.194,11.983 2.823,10.354 C1.808,9.148 0.971,7.834 0.421,6.353 C0.153,5.638 -0.038,4.905 0.022,4.127 C0.059,3.649 0.237,3.241 0.590,2.907 C0.971,2.544 1.330,2.168 1.705,1.798 C2.192,1.317 2.804,1.317 3.295,1.795 C3.598,2.093 3.894,2.394 4.194,2.694 C4.485,2.988 4.775,3.274 5.065,3.569 C5.578,4.083 5.578,4.683 5.069,5.194 C4.703,5.564 4.341,5.931 3.969,6.290 C3.872,6.388 3.863,6.466 3.913,6.585 C4.160,7.172 4.513,7.694 4.909,8.180 Z"
                                />
                              </g>
                            </svg>
                          </span>
                          <div className="header_location">
                            {/* <h5>Phone Number</h5> */}
                            <h5 className="mt-3">( +234 ) 80-944-09-567</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="clv_menu">
                    <div className="clv_menu_nav">
                      <ul>
                        <li>
                          <Link to="/">home</Link>
                        </li>
                        <li>
                          <Link to="/about">about us</Link>
                        </li>
                        <li>
                          <Link to="/">services</Link>
                        </li>
                        <li>
                          <Link to="/gallery">gallery</Link>
                        </li>
                        <li>
                          <Link to="/contact-us">contact us</Link>
                        </li>
                      </ul>
                    </div>

                    <div className="cart_nav">
                      <ul>
                        {/* <li>
												<Link className="search_toggle" to="/"><span className={classes.hamburgerMenu} ><i className="fa fa-search" aria-hidden="true"></i></span></Link>
											</li> */}
                        <li className="menu_toggle">
                          <span className={classes.hamburgerMenu}>
                            <i className="fa fa-bars" aria-hidden="true"></i>
                          </span>
                        </li>
                      </ul>
                    </div>

                    {/* <a href="javascript:;" className="appointment_btn">appointment</a> */}
                    {isUserLoggedIn ? (
                      <button
                        type="button"
                        className="appointment_btn px-2 btn btn-outline-light"
                        onClick={toMyDashboard}
                      >
                        {" "}
                        My Dashboard
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="appointment_btn px-2 btn btn-outline-light"
                        // onClick={handleOpen}
                        onClick={OptionDialog}
                      >
                        {" "}
                        Create Account
                      </button>
                    )}
                    {/* <CreateAccount open={open} handleClose={handleClose} /> */}
                  </div>
                </div>
                {/* <div className='d-flex justify-content-end'> */}
                {isCreateAccountVisible ? (
                  isUserLoggedIn ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-success ml-auto mb-3 d-none d-md-block d-lg-none"
                        onClick={toMyDashboard}
                      >
                        {" "}
                        My Dashboard
                      </button>
                      <button
                        type="button"
                        className="btn btn-success mx-auto mb-3 mt-1 d-md-none"
                        onClick={toMyDashboard}
                      >
                        {" "}
                        My Dashboard
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-success ml-auto mb-3 d-none d-md-block d-lg-none"
                        id="createAccountMobileButton"
                      >
                        {" "}
                        Create Account
                      </button>
                      <button
                        type="button"
                        className="btn btn-success mx-auto mb-3 d-md-none"
                        id="createAccountMobileButton2"
                      >
                        {" "}
                        Create Account
                      </button>
                    </>
                  )
                ) : null}
                {/* </div> */}
              </div>

              {/* <div className='d-none d-md-flex justify-content-end d-lg-none'>

							<div className="clv_right_header">
						    	<div className="clv_address">
							    	<div className="row">
								    	{/* <div className="col-md-4">
									    	<div className="address_block">
										    	<span className="addr_icon">
											    	<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="16px">
											        	<defs>
											            	<filter id="Filter_0">
												            	<feFlood floodColor="rgb(31, 161, 46)" floodOpacity="1" result="floodOut" />
												            	<feComposite operator="atop" in="floodOut" in2="SourceGraphic" result="compOut" />
												            	<feBlend mode="normal" in="compOut" in2="SourceGraphic" />
											            	</filter>
											        	</defs>
											        	<g filter="url(#Filter_0)">
											            	<path fillRule="evenodd"  fill="rgb(83, 175, 30)" 
                                                            d="M5.530,-0.004 C2.497,-0.004 0.029,2.434 0.029,5.431 C0.029,9.148 4.951,14.609 5.161,14.839 C5.358,15.055 5.702,15.054 5.898,14.839 C6.108,14.609 11.029,9.148 11.029,5.431 C11.029,2.434 8.562,-0.004 5.530,-0.004 ZM5.530,8.165 C4.004,8.165 2.762,6.937 2.762,5.431 C2.762,3.923 4.004,2.696 5.530,2.696 C7.055,2.696 8.297,3.923 8.297,5.431 C8.297,6.937 7.055,8.165 5.530,8.165 Z"/>
											        	</g>
											    	</svg>
										    	</span>
										    
                                            	<div className="header_location">
											    	<h5>Our location</h5>
											    	<p>512, Beand square, Ogun State</p>
										    	</div>
									    	</div>
								    	</div> 
								    
										<div className="col-md-4">
											{/* <div className="address_block">
												<span className="addr_icon">
													<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="15px">
														<defs>
															<filter id="Filter_0">
																<feFlood floodColor="rgb(31, 161, 46)" floodOpacity="1" result="floodOut" />
																<feComposite operator="atop" in="floodOut" in2="SourceGraphic" result="compOut" />
																<feBlend mode="normal" in="compOut" in2="SourceGraphic" />
															</filter>
														</defs>
															<g filter="url(#Filter_0)">
																<path fillRule="evenodd"  fill="rgb(0, 0, 0)"
																	d="M13.866,7.234 C13.607,5.720 12.892,4.343 11.802,3.254 C10.653,2.108 9.197,1.379 7.592,1.155 L7.754,-0.003 C9.613,0.254 11.296,1.095 12.626,2.426 C13.888,3.689 14.716,5.282 15.019,7.037 L13.866,7.234 ZM10.537,4.459 C11.296,5.220 11.796,6.178 11.977,7.238 L10.824,7.434 C10.684,6.617 10.300,5.872 9.713,5.286 C9.091,4.665 8.304,4.274 7.439,4.151 L7.601,2.995 C8.719,3.150 9.734,3.657 10.537,4.459 ZM4.909,8.180 C5.709,9.161 6.611,10.032 7.689,10.710 C7.920,10.853 8.176,10.959 8.416,11.090 C8.538,11.159 8.623,11.137 8.722,11.033 C9.088,10.660 9.459,10.290 9.831,9.922 C10.318,9.439 10.931,9.439 11.421,9.922 C12.017,10.516 12.614,11.109 13.207,11.706 C13.704,12.206 13.701,12.816 13.201,13.323 C12.864,13.664 12.505,13.987 12.186,14.344 C11.721,14.865 11.140,15.034 10.471,14.996 C9.500,14.942 8.607,14.622 7.745,14.203 C5.831,13.272 4.194,11.983 2.823,10.354 C1.808,9.148 0.971,7.834 0.421,6.353 C0.153,5.638 -0.038,4.905 0.022,4.127 C0.059,3.649 0.237,3.241 0.590,2.907 C0.971,2.544 1.330,2.168 1.705,1.798 C2.192,1.317 2.804,1.317 3.295,1.795 C3.598,2.093 3.894,2.394 4.194,2.694 C4.485,2.988 4.775,3.274 5.065,3.569 C5.578,4.083 5.578,4.683 5.069,5.194 C4.703,5.564 4.341,5.931 3.969,6.290 C3.872,6.388 3.863,6.466 3.913,6.585 C4.160,7.172 4.513,7.694 4.909,8.180 Z"/>
															</g>
													</svg>
												</span>
												<div className="header_location">
													<h5>Phone Number</h5>
													<p>( +234 ) 80-944-09-567</p>
												</div>
											</div> 
										</div> 
								
										<div className="col-md-4">
											{/* <div className="address_block br_none">
												<span className="addr_icon">
													<svg xmlns="http://www.w3.org/2000/svg" width="20px" height="15px">
														<defs>
															<filter id="Filter_0">
																<feFlood floodColor="rgb(31, 161, 46)" floodOpacity="1" result="floodOut" />
																<feComposite operator="atop" in="floodOut" in2="SourceGraphic" result="compOut" />
																<feBlend mode="normal" in="compOut" in2="SourceGraphic" />
															</filter>
														</defs>
														<g filter="url(#Filter_0)">
															<path fillRule="evenodd"  fill="rgb(0, 0, 0)"
																d="M14.468,8.297 C15.479,7.588 16.707,6.742 18.151,5.760 C18.554,5.483 18.907,5.174 19.211,4.833 L19.211,13.062 L14.468,8.297 ZM17.398,4.619 C15.683,5.816 14.388,6.719 13.509,7.333 C13.026,7.670 12.667,7.920 12.436,8.082 C12.402,8.106 12.349,8.146 12.278,8.196 C12.201,8.251 12.105,8.321 11.986,8.407 C11.756,8.574 11.565,8.709 11.413,8.811 C11.261,8.915 11.077,9.031 10.862,9.159 C10.646,9.286 10.443,9.382 10.252,9.445 C10.061,9.510 9.885,9.541 9.722,9.541 L9.711,9.541 L9.701,9.541 C9.538,9.541 9.362,9.510 9.171,9.445 C8.980,9.382 8.777,9.286 8.561,9.159 C8.345,9.031 8.161,8.915 8.010,8.811 C7.858,8.709 7.667,8.574 7.437,8.407 C7.318,8.321 7.221,8.251 7.145,8.196 C7.074,8.146 7.020,8.106 6.986,8.082 C6.690,7.872 6.332,7.622 5.916,7.329 C5.428,6.989 4.861,6.591 4.208,6.138 C3.000,5.297 2.275,4.791 2.035,4.619 C1.597,4.322 1.183,3.912 0.794,3.389 C0.406,2.868 0.211,2.382 0.211,1.937 C0.211,1.382 0.358,0.920 0.651,0.551 C0.945,0.181 1.363,-0.004 1.908,-0.004 L17.515,-0.004 C17.974,-0.004 18.372,0.164 18.708,0.498 C19.043,0.832 19.211,1.233 19.211,1.701 C19.211,2.262 19.038,2.798 18.692,3.310 C18.346,3.821 17.914,4.258 17.398,4.619 ZM4.956,8.295 L0.211,13.062 L0.211,4.833 C0.522,5.181 0.879,5.491 1.282,5.760 C2.783,6.784 4.006,7.629 4.956,8.295 ZM6.551,9.435 C6.954,9.732 7.281,9.965 7.532,10.132 C7.783,10.299 8.117,10.469 8.534,10.644 C8.951,10.817 9.340,10.903 9.700,10.903 L9.711,10.903 L9.721,10.903 C10.082,10.903 10.471,10.817 10.888,10.644 C11.305,10.469 11.639,10.299 11.890,10.132 C12.141,9.965 12.468,9.732 12.871,9.435 C12.989,9.348 13.114,9.258 13.243,9.165 L18.626,14.573 C18.312,14.853 17.942,14.995 17.515,14.995 L1.908,14.995 C1.481,14.995 1.110,14.853 0.796,14.573 L6.180,9.165 C6.314,9.262 6.439,9.352 6.551,9.435 Z"/>
														</g>
													</svg>
												</span>
												<div className="header_location">
													<h5>email address</h5>
													<p>hopeconcept@example.com</p>
												</div> 
											</div> 
										</div> 

										<div className="col-md-4">
											<div className="address_block">
												<span className="addr_icon">
													<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="15px">
														<defs>
															<filter id="Filter_0">
																<feFlood floodColor="rgb(31, 161, 46)" floodOpacity="1" result="floodOut" />
																<feComposite operator="atop" in="floodOut" in2="SourceGraphic" result="compOut" />
																<feBlend mode="normal" in="compOut" in2="SourceGraphic" />
															</filter>
														</defs>
															<g filter="url(#Filter_0)">
																<path fillRule="evenodd"  fill="rgb(0, 0, 0)"
																	d="M13.866,7.234 C13.607,5.720 12.892,4.343 11.802,3.254 C10.653,2.108 9.197,1.379 7.592,1.155 L7.754,-0.003 C9.613,0.254 11.296,1.095 12.626,2.426 C13.888,3.689 14.716,5.282 15.019,7.037 L13.866,7.234 ZM10.537,4.459 C11.296,5.220 11.796,6.178 11.977,7.238 L10.824,7.434 C10.684,6.617 10.300,5.872 9.713,5.286 C9.091,4.665 8.304,4.274 7.439,4.151 L7.601,2.995 C8.719,3.150 9.734,3.657 10.537,4.459 ZM4.909,8.180 C5.709,9.161 6.611,10.032 7.689,10.710 C7.920,10.853 8.176,10.959 8.416,11.090 C8.538,11.159 8.623,11.137 8.722,11.033 C9.088,10.660 9.459,10.290 9.831,9.922 C10.318,9.439 10.931,9.439 11.421,9.922 C12.017,10.516 12.614,11.109 13.207,11.706 C13.704,12.206 13.701,12.816 13.201,13.323 C12.864,13.664 12.505,13.987 12.186,14.344 C11.721,14.865 11.140,15.034 10.471,14.996 C9.500,14.942 8.607,14.622 7.745,14.203 C5.831,13.272 4.194,11.983 2.823,10.354 C1.808,9.148 0.971,7.834 0.421,6.353 C0.153,5.638 -0.038,4.905 0.022,4.127 C0.059,3.649 0.237,3.241 0.590,2.907 C0.971,2.544 1.330,2.168 1.705,1.798 C2.192,1.317 2.804,1.317 3.295,1.795 C3.598,2.093 3.894,2.394 4.194,2.694 C4.485,2.988 4.775,3.274 5.065,3.569 C5.578,4.083 5.578,4.683 5.069,5.194 C4.703,5.564 4.341,5.931 3.969,6.290 C3.872,6.388 3.863,6.466 3.913,6.585 C4.160,7.172 4.513,7.694 4.909,8.180 Z"/>
															</g>
													</svg>
												</span>
												<div className="header_location">
													{/* <h5>Phone Number</h5> 
													<h5 className='mt-3'>( +234 ) 80-944-09-567</h5>
												</div>
											</div>
										</div>

							    	</div>
						    	</div>

                            	<div className="clv_menu">
									<div className="clv_menu_nav">
										<ul>
											<li><Link to="/">home</Link></li>
											<li><Link to="/about">about us</Link></li>
											<li><Link to="/">services</Link></li>
											<li><Link to="/">gallery</Link></li>
											<li><Link to="/contact-us">contact us</Link></li>
										</ul>
										
									</div>
									<div className="cart_nav">
										<ul>
											{/* <li>
												<Link className="search_toggle" to="/"><span className={classes.hamburgerMenu} ><i className="fa fa-search" aria-hidden="true"></i></span></Link>
											</li> 
											<li className="menu_toggle">
												<span className={classes.hamburgerMenu} ><i className="fa fa-bars" aria-hidden="true"></i></span>
											</li>
										</ul>
									</div>
								
							    	{/* <a href="javascript:;" className="appointment_btn">appointment</a> 
									{isUserLoggedIn ?
										<button type="button" className="appointment_btn px-2 btn btn-outline-light" onClick={toMyDashboard}>  My Dashboard</button>
									:	
										<button type="button" className="appointment_btn px-2 btn btn-outline-light" onClick={handleOpen}>  Create Account</button>
									}
									<CreateAccount open={open} handleClose={handleClose} />	
						    	</div>
					    	</div>
						
							<div className='d-flex align-items-center'>
								{ isCreateAccountVisible ? 
									isUserLoggedIn ? 
										<button type="button" className="btn btn-success ml-5" onClick={toMyDashboard}>  My Dashboard</button> 
									:
										<button type="button" className="btn btn-success ml-5" id='createAccountMobileButton'>  Create Account</button> 						
									: 	null 
								}
								
							</div>
							
						</div>						 */}

              <div className="d-none d-lg-block">
                <div className="clv_right_header">
                  <div className="clv_address">
                    <div className="row">
                      <div className="col-md-4"></div>
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <div className="address_block">
                          <span className="addr_icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16px"
                              height="15px"
                            >
                              <defs>
                                <filter id="Filter_0">
                                  <feFlood
                                    floodColor="rgb(31, 161, 46)"
                                    floodOpacity="1"
                                    result="floodOut"
                                  />
                                  <feComposite
                                    operator="atop"
                                    in="floodOut"
                                    in2="SourceGraphic"
                                    result="compOut"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in="compOut"
                                    in2="SourceGraphic"
                                  />
                                </filter>
                              </defs>
                              <g filter="url(#Filter_0)">
                                <path
                                  fillRule="evenodd"
                                  fill="rgb(0, 0, 0)"
                                  d="M13.866,7.234 C13.607,5.720 12.892,4.343 11.802,3.254 C10.653,2.108 9.197,1.379 7.592,1.155 L7.754,-0.003 C9.613,0.254 11.296,1.095 12.626,2.426 C13.888,3.689 14.716,5.282 15.019,7.037 L13.866,7.234 ZM10.537,4.459 C11.296,5.220 11.796,6.178 11.977,7.238 L10.824,7.434 C10.684,6.617 10.300,5.872 9.713,5.286 C9.091,4.665 8.304,4.274 7.439,4.151 L7.601,2.995 C8.719,3.150 9.734,3.657 10.537,4.459 ZM4.909,8.180 C5.709,9.161 6.611,10.032 7.689,10.710 C7.920,10.853 8.176,10.959 8.416,11.090 C8.538,11.159 8.623,11.137 8.722,11.033 C9.088,10.660 9.459,10.290 9.831,9.922 C10.318,9.439 10.931,9.439 11.421,9.922 C12.017,10.516 12.614,11.109 13.207,11.706 C13.704,12.206 13.701,12.816 13.201,13.323 C12.864,13.664 12.505,13.987 12.186,14.344 C11.721,14.865 11.140,15.034 10.471,14.996 C9.500,14.942 8.607,14.622 7.745,14.203 C5.831,13.272 4.194,11.983 2.823,10.354 C1.808,9.148 0.971,7.834 0.421,6.353 C0.153,5.638 -0.038,4.905 0.022,4.127 C0.059,3.649 0.237,3.241 0.590,2.907 C0.971,2.544 1.330,2.168 1.705,1.798 C2.192,1.317 2.804,1.317 3.295,1.795 C3.598,2.093 3.894,2.394 4.194,2.694 C4.485,2.988 4.775,3.274 5.065,3.569 C5.578,4.083 5.578,4.683 5.069,5.194 C4.703,5.564 4.341,5.931 3.969,6.290 C3.872,6.388 3.863,6.466 3.913,6.585 C4.160,7.172 4.513,7.694 4.909,8.180 Z"
                                />
                              </g>
                            </svg>
                          </span>
                          <div className="header_location">
                            {/* <h5>Phone Number</h5> */}
                            <h5 className="mt-3">+2348154338473</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="clv_menu">
                    <div className="clv_menu_nav">
                      <ul>
                        <li>
                          <Link to="/"> home </Link>
                          {/* <a href="/">home</a> */}
                        </li>
                        <li>
                          <Link to="/about"> about us </Link>
                          {/* <a href="/about">about us</a> */}
                        </li>
                        <li>
                          <Link to="/services"> services </Link>
                          {/* <a href="/services">services</a> */}
                        </li>
                        <li>
                          <div className="dropdown">
                            <Typography className="dropbtn">
                              Gallery <ArrowDropDownIcon />
                            </Typography>
                            <div className="dropdown-content">
                              <Typography>
                                <Link to="/photo-gallery"> Photo Gallery </Link>
                              </Typography>
                              <Typography>
                                <Link to="/video-gallery">Video Gallery</Link>
                              </Typography>
                            </div>
                          </div>
                        </li>
                        <li>
                          <Link to="/contact-us"> contact-us</Link>
                          {/* <a href="/contact-us">contact us</a> */}
                        </li>
                      </ul>
                    </div>

                    <div className="cart_nav">
                      <ul>
                        {/* <li>
												<Link className="search_toggle" to="/"><span className={classes.hamburgerMenu} ><i className="fa fa-search" aria-hidden="true"></i></span></Link>
											</li> */}
                        <li className="menu_toggle">
                          <span className={classes.hamburgerMenu}>
                            <i className="fa fa-bars" aria-hidden="true"></i>
                          </span>
                        </li>
                      </ul>
                    </div>

                    {/* <a href="javascript:;" className="appointment_btn">appointment</a> */}
                    {isUserLoggedIn ? (
                      <button
                        type="button"
                        className="appointment_btn px-2 btn btn-outline-light"
                        onClick={toMyDashboard}
                      >
                        {" "}
                        My Dashboard
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="appointment_btn px-2 btn btn-outline-light"
                        onClick={OptionDialog}
                      >
                        Create Account
                      </button>
                    )}
                    {/* <CreateAccount open={open} handleClose={handleClose} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--Popup--> */}
      <div className="search_box">
        <div className="search_block">
          <h3>Explore more with us</h3>
          <div className="search_field">
            <input type="search" placeholder="Search Here" />
            <Link to="/">search</Link>
          </div>
        </div>
        <span className="search_close">
          {/* <?xml version="1.0" encoding="iso-8859-1"?> */}
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 47.971 47.971"
            style={{ enableBackground: "new 0 0 47.971 47.971" }}
            width="30px"
            height="30px"
          >
            <g>
              <path
                style={{ fill: "#1fa12e" }}
                d="M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88
						c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242
						C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879
						s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z"
              />
            </g>
          </svg>
        </span>
      </div>

      {/* <Dialog aria-labelledby="customized-dialog-title"
        open={open} className={classes.Dialog} fullWidth={true} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" className={classes.DialogTitle} >
          
          <IconButton aria-label="close" className={`${classes.closeButton} ml-auto`} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CreateAccount />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus disabled={handleClose} variant="contained"
            onClick={handleOpen} 
            className={classes.successButton }>
            
          </Button>
        </DialogActions>
      </Dialog> */}
      {/* <Dialog
        aria-labelledby="customized-dialog-title"
        open={open2}
        className={classes.Dialog}
        fullWidth={true}
        maxWidth="md"
      >
        <RegisterFarmersForm handleClose={handleClose2} />
      
      </Dialog> */}
      <Dialog
        open={opens}
        onClose={OptionDialogCl}
        className={classes.Dialog}
        fullWidth={true}
        maxWidth="sm"
      >
        <CreorRegFarmer onClose={OptionDialogCl} />
      </Dialog>
    </Fragment>
  );
};

export default Header;
