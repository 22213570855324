/** @format */

import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Card, CardHeader, CardContent, Divider } from "@material-ui/core";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, withStyles } from "@material-ui/styles";
import DescriptionIcon from "@material-ui/icons/Description";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import api from "../../../../../middlewares/axiosConfig";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    padding: "0 !important",
    marginTop: "7px",
    paddingBottom: "20px !important",
  },
  titleStyle: {
    fontWeight: 600,
    lineHeight: "30px",
    fontSize: "16px",
    color: "#0e0e0e",
  },
  avatarStyle: {
    border: "1px solid #c5ccc5",
    // fontSize: '30px',
    borderRadius: "5px",
    color: "#4a4c49",
  },
  margin: {
    margin: theme.spacing(0),
  },
}));

const FarmDetails = () => {
  const classes = useStyles();
  const [selectCoopratives, setSelectCooperatives] = useState([]);
  const [selectCooprativesId, setSelectCooperativesId] = useState(1);
  const [farmDetail, setFarmDetail] = useState([
    {
      cooprativeNames: "HCC",
      totalFarmCount: 200,
      totalPartitionFarm: 300,
      totalAllocatedFarm: 200,
    },
  ]);

  useEffect(async () => {
    let response = await api.get(`PortalAdmin/GetAllCooperativeSociety`);
    console.log(response.data);
    setSelectCooperatives(response.data);
  }, []);

  const farmDetails = async () => {
    let res = await api.get(
      `Dashboard/SuperAdminGetAllFarmsYearlyDetails?cooperativeSocietyId=${selectCooprativesId}`
    );
    let data = res.data.map((name) => {
      return {
        cooprativeNames: name.cooprativeNames,
        allocatedFarm: name.allocatedFarm,
        partitionFarm: name.partitionFarm,
        totalFarm: name.totalFarm,
      };
    });
    console.log(data, "farm detail");
    setFarmDetail(data);
  };

  useEffect(() => {
    farmDetails();
  }, [selectCooprativesId]);

  const handleChange = (event) => {
    setSelectCooperativesId(event.target.value);
    console.log(selectCooprativesId);
  };

  const selectCooperativ = (
    <FormControl className={classes.margin}>
      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={selectCooprativesId}
        label="Select Cooperatives"
        onChange={handleChange}
        input={<BootstrapInput />}
      >
        {/* <MenuItem value={0}>Select Cooperatives</MenuItem> */}
        {selectCoopratives.map((item, index) => (
          <MenuItem key={index} value={item.cooperativeSocietyId}>
            {item.cooperativeSocietyName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <Card className={clsx(classes.root)}>
      <CardHeader
        title="Farm Details"
        avatar={<DescriptionIcon />}
        classes={{ title: classes.titleStyle, avatar: classes.avatarStyle }}
        action={selectCooperativ}
      />
      <Divider />
      <CardContent className={classes.content}>
        <ResponsiveContainer width="100%" aspect={2}>
          <BarChart
            width={500}
            height={300}
            data={farmDetail}
            margin={{
              top: 5,
              right: 40,
              left: 25,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis dataKey="cooprativeNames" name="cooprativeNames" />
            <YAxis />
            <Tooltip />
            <Legend />
            {/* {farmDetail?.map((item, index) => (
              <Bar
                dataKey={item.cooprativeNames}
                fill="#c1a439"
                name={item.cooprativeNames}
              />
            ))} */}
            <Bar dataKey="totalFarm" fill="#008000" name="TotalFarm" />
            <Bar
              dataKey="partitionFarm"
              fill="#c1a439"
              name="TotalPartitionFarm"
            />
            <Bar
              dataKey="allocatedFarm"
              fill="#01579b"
              name="TotalAllocatedFarm"
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default FarmDetails;
