import { GENERIC_CONSTANTS } from './actionTypes';
import api from '../../middlewares/axiosConfig';
import * as actionTypes from './actionTypes';


export const getStates = () => {
  return {
    type: actionTypes.GENERIC_CONSTANTS.GET_STATES
  };
}

export const getStatesStarts = () => {
  return {
    type: actionTypes.GENERIC_CONSTANTS.GET_STATES_STARTS
  };
}

export const getStatesSuccess = (states) => {
  return {
    type: actionTypes.GENERIC_CONSTANTS.GET_STATES_SUCCESS,
    states: states
  };
}

export const getStatesFails = (error) => {
  return {
    type: actionTypes.GENERIC_CONSTANTS.GET_STATES_FAILS,
    error: error
  };
}

export const setRoles = data => dispatch => {

  dispatch({
    type: GENERIC_CONSTANTS.SET_ROLES,
    payload: data
  });
}

export const getRoles = data => dispatch => {
  // api.get("Generic/Roles")
  dispatch({
    type: GENERIC_CONSTANTS.SET_ROLES,
    payload: data
  });
}
