/** @format */

import { takeEvery, takeLatest, all, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import {
  logoutSaga,
  checkTokenTimeoutSaga,
  loginFarmerSaga,
  loginPartnerSaga,
  superAdminLogin,
  loginServiceProviderSaga,
  loggedinCheckStateSaga,
} from "./loginSaga";
import {
  getFarmsSaga,
  createFarmSaga,
  editFarmSaga,
  editFarmStartsSaga,
  deleteFarmSaga,
} from "./farmsSaga";
import {
  getFarmersSaga,
  createFarmerSaga,
  editFarmerSaga,
  editFarmerStartsSaga,
  deleteFarmerSaga,
  getFarmersPassportCapturedSaga,
  getFarmersBiometricCapturedSaga,
} from "./farmerSaga";
import {
  addCooperatives,
  editCooperatives,
  getCooperatives,
} from "./coopAdminSaga";
import {
  getRolesSaga,
  createRoleSaga,
  editRoleSaga,
  editRoleSavedSaga,
  deleteRoleSaga,
} from "./roleSaga";
import { getStatesSaga } from "./genericsSaga";
import {
  getTownsSaga,
  createTownSaga,
  editTownSaga,
  editTownStartsSaga,
  deleteTownSaga,
} from "./setupMgtSaga";
import {
  getPartitionedFarmsSaga,
  getUnPartitionedFarmsSaga,
  createFarmPartitionSaga,
  editPartitionedFarmSaga,
  editPartitionedFarmStartsSaga,
  deletePartitionedFarmSaga,
} from "./farmsPartitionSaga";
import { createAccountSaga } from "./createAccountSaga";
import { getAllCooperativeSocietySaga } from "./partnerSaga";
import { getPaymentStart, getFarmerPaymentStart } from "./paymentSaga";
import { PaymentCategory } from "./paymentCategorySaga";

function* watchLogin() {
  yield all([
    takeEvery(actionTypes.LOGIN_CONSTANTS.INITIATE_LOGOUT, logoutSaga),
    takeEvery(
      actionTypes.LOGIN_CONSTANTS.TOKEN_CHECK_TIMEOUT,
      checkTokenTimeoutSaga
    ),
    takeEvery(actionTypes.LOGIN_CONSTANTS.LOGIN_FARMER, loginFarmerSaga),
    takeEvery(actionTypes.LOGIN_CONSTANTS.SUPER_ADMIN_LOGIN, superAdminLogin),
    takeEvery(actionTypes.LOGIN_CONSTANTS.LOGIN_PARTNER, loginPartnerSaga),
    takeEvery(
      actionTypes.LOGIN_CONSTANTS.LOGIN_SERVICE_PROVIDER,
      loginServiceProviderSaga
    ),
    takeEvery(
      actionTypes.LOGIN_CONSTANTS.LOGGEDIN_CHECK_STATE,
      loggedinCheckStateSaga
    ),
  ]);
}

function* getCoop() {
  yield all([
    takeLatest(
      actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.GET_COOPERATIVES,
      getCooperatives
    ),
    takeLatest(
      actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.ADD_COOPERATIVE,
      addCooperatives
    ),
    takeLatest(
      actionTypes.SUPER_ADMIN_COOPERATIVE_CONSTANTS.EDIT_COOPERATIVE,
      editCooperatives
    ),
  ]);
}

function* watchRoles() {
  yield all([
    takeEvery(actionTypes.ROLES_CONSTANTS.GET_ROLES, getRolesSaga),
    takeEvery(actionTypes.ROLES_CONSTANTS.CREATE_ROLE, createRoleSaga),
    takeEvery(actionTypes.ROLES_CONSTANTS.EDIT_ROLE, editRoleSaga),
    takeEvery(actionTypes.ROLES_CONSTANTS.EDIT_ROLE_SAVED, editRoleSavedSaga),
    takeEvery(actionTypes.ROLES_CONSTANTS.DELETE_ROLE, deleteRoleSaga),
  ]);
}

function* watchGenerics() {
  yield all([
    takeEvery(actionTypes.GENERIC_CONSTANTS.GET_STATES, getStatesSaga),
  ]);
}

function* watchSetupMgt() {
  yield all([
    takeEvery(actionTypes.SETUP_CONSTANTS.GET_TOWNS, getTownsSaga),
    takeEvery(actionTypes.SETUP_CONSTANTS.CREATE_TOWN, createTownSaga),
    takeEvery(actionTypes.SETUP_CONSTANTS.EDIT_TOWN, editTownSaga),
    takeEvery(actionTypes.SETUP_CONSTANTS.EDIT_TOWN_STARTS, editTownStartsSaga),
    takeEvery(actionTypes.SETUP_CONSTANTS.DELETE_TOWN, deleteTownSaga),
  ]);
}

function* watchFarms() {
  yield all([
    takeEvery(actionTypes.FARMS_CONSTANTS.GET_FARMS, getFarmsSaga),
    // takeEvery(actionTypes.FARMS_CONSTANTS.CREATE_FARM, createFarmSaga),
    // takeEvery(actionTypes.FARMS_CONSTANTS.EDIT_FARM, editFarmSaga),
    // takeEvery(actionTypes.FARMS_CONSTANTS.EDIT_FARM_STARTS, editFarmStartsSaga),
    takeEvery(actionTypes.FARMS_CONSTANTS.DELETE_FARM, deleteFarmSaga),
  ]);
}

function* watchPayment() {
  yield all([
    takeEvery(
      actionTypes.PAYMENT_CONSTANTS.GET_ALL_FARMERS,
      getFarmerPaymentStart
    ),
    takeEvery(
      actionTypes.PAYMENT_CONSTANTS.GET_ALL_PAYMENTCAT,
      PaymentCategory
    ),
  ]);
}

function* watchPaymentCategory() {
  yield all([
    takeEvery(
      actionTypes.PAYMENT_CONSTANTS.GET_ALL_PAYMENTCAT,
      PaymentCategory
    ),
  ]);
}

function* watchFarmsPartition() {
  yield all([
    takeEvery(
      actionTypes.MANAGEFARMLAND_CONSTANTS.GET_PARTITIONED_FARMS,
      getPartitionedFarmsSaga
    ),
    takeEvery(
      actionTypes.MANAGEFARMLAND_CONSTANTS.GET_UNPARTITIONED_FARMS,
      getUnPartitionedFarmsSaga
    ),
    takeEvery(
      actionTypes.MANAGEFARMLAND_CONSTANTS.CREATE_FARM_PARTITION,
      createFarmPartitionSaga
    ),
    takeEvery(
      actionTypes.MANAGEFARMLAND_CONSTANTS.EDIT_PARTITIONED_FARM,
      editPartitionedFarmSaga
    ),
    takeEvery(
      actionTypes.MANAGEFARMLAND_CONSTANTS.EDIT_PARTITIONED_FARM_STARTS,
      editPartitionedFarmStartsSaga
    ),
    takeEvery(
      actionTypes.MANAGEFARMLAND_CONSTANTS.DELETE_PARTITIONED_FARM,
      deletePartitionedFarmSaga
    ),
  ]);
}

function* watchFarmers() {
  yield all([
    takeEvery(actionTypes.FARMERS_CONSTANTS.GET_FARMERS, getFarmersSaga),
    takeEvery(actionTypes.FARMERS_CONSTANTS.CREATE_FARMER, createFarmerSaga),
    takeEvery(actionTypes.FARMERS_CONSTANTS.EDIT_FARMER, editFarmerSaga),
    takeEvery(
      actionTypes.FARMERS_CONSTANTS.EDIT_FARMER_STARTS,
      editFarmerStartsSaga
    ),
    takeEvery(actionTypes.FARMERS_CONSTANTS.DELETE_FARMER, deleteFarmerSaga),

    takeEvery(
      actionTypes.FARMERS_CONSTANTS.GET_FARMERS_PASSPORT_CAPTURED,
      getFarmersPassportCapturedSaga
    ),
    takeEvery(
      actionTypes.FARMERS_CONSTANTS.GET_FARMERS_BIOMETRIC_CAPTURED,
      getFarmersBiometricCapturedSaga
    ),
  ]);
}

function* watchCreateAccount() {
  yield all([
    takeEvery(
      actionTypes.CREATE_ACCOUNT_CONSTANTS.CREATE_ACCOUNT,
      createAccountSaga
    ),
  ]);
}

function* watchPartner() {
  yield all([
    takeEvery(
      actionTypes.PARTNERS_CONSTANTS.GET_ALL_COOPERATIVE_SOCIETY,
      getAllCooperativeSocietySaga
    ),
  ]);
}

export default function* watchAllSagas() {
  yield all([
    fork(watchLogin),
    fork(watchRoles),
    fork(watchGenerics),
    fork(watchSetupMgt),
    fork(watchFarms),
    fork(watchFarmsPartition),
    fork(watchFarmers),
    fork(watchCreateAccount),
    fork(watchPartner),
    fork(getCoop),
    fork(watchPayment),
    fork(watchPaymentCategory),
  ]);
}
