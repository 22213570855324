/** @format */

import React, { Fragment, useEffect, useState } from "react";
import api from "../../../../../middlewares/axiosConfig";
import { actions, toastr } from "react-redux-toastr";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useSelector } from "react-redux";
// import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid, colors } from "@material-ui/core";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import LandscapeIcon from "@material-ui/icons/Landscape";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Label } from "../../../../../components/Admin";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  item: {
    padding: theme.spacing(3),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      "&:not(:last-of-type)": {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&:not(:last-of-type)": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  valueContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    marginLeft: theme.spacing(1),
  },
}));

const Overview = (props) => {
  const { className, ...rest } = props;
  const [getAllResgisterFarmer, setGetAllResgisterFarmer] = useState(0);
  const [totalVerifiedFarmer, setTotalVerifiedFarmer] = useState(0);
  const [farmerLand, setFarmerLand] = useState(0);
  const [verifiedFarms, setVerifiedFarms] = useState(0);
  const partnerState = useSelector((state) => state.loginReducer.farmerData);
  const classes = useStyles();
  const [partnerId, setPartnerId] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(async () => {
    try {
      if (partnerState.currentlySelectedCooperative != null) {
        api
          .get(
            `Partners/GetPartnerAllRegisteredFarmers/${partnerState.currentlySelectedCooperative}`
          )
          .then((res) => setGetAllResgisterFarmer(res.data));

        api
          .get(
            `Partners/GetPartnerVerifiedFarmers/${partnerState.currentlySelectedCooperative}`
          )
          .then((res) => setTotalVerifiedFarmer(res.data));

        api
          .get(
            `Partners/GetPartnerTotalVerifiedFarms/${partnerState.currentlySelectedCooperative}`
          )
          .then((res) => setVerifiedFarms(res.data));

        api
          .get(
            `Partners/GetPartnerTotalAllocatedFarms/${partnerState.currentlySelectedCooperative}`
          )
          .then((res) => setFarmerLand(res.data));
      }
    } catch (error) {
      toastr.error("Error", "Sorry Could Not Load Get All Register Farmer");
    }
  }, [partnerState.currentlySelectedCooperative]);

  const data = {
    farmer: "1,051",
    verifiedFarmer: "2",
    farms: "3",
    allocatedFarms: "0",
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justify="space-between">
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Link to={"/admin/biodata-capture"}>
            <Typography component="h2" gutterBottom variant="overline">
              {" "}
              Registered Farmers{" "}
            </Typography>

            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {getAllResgisterFarmer}
                <HowToRegIcon
                  style={{
                    color: green[500],
                    fontSize: 30,
                    marginLeft: 10,
                    marginBottom: 10,
                  }}
                />
              </Typography>
            </div>
          </Link>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Link to={"/admin/biometrics-capture"}>
            <Typography component="h2" gutterBottom variant="overline">
              {" "}
              Total verified Farmers
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {totalVerifiedFarmer}
                <VerifiedUserIcon
                  style={{
                    color: green[500],
                    fontSize: 25,
                    marginLeft: 10,
                    marginBottom: 10,
                  }}
                />
              </Typography>
            </div>
          </Link>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Link to={"/admin/farm-details"}>
            <Typography component="h2" gutterBottom variant="overline">
              {" "}
              Total Farmlands{" "}
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {verifiedFarms}
                <LandscapeIcon
                  style={{ color: green[500], fontSize: 30, marginLeft: 10 }}
                />
              </Typography>
            </div>
          </Link>
        </Grid>
        <Grid className={classes.item} item md={3} sm={6} xs={12}>
          <Link to={"/admin/allocate-farm"}>
            <Typography component="h2" gutterBottom variant="overline">
              {" "}
              Allocated Farms{" "}
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3">
                {farmerLand}
                <CropOriginalIcon
                  style={{
                    color: green[500],
                    fontSize: 30,
                    marginLeft: 10,
                    marginBottom: 7,
                  }}
                />
              </Typography>
            </div>
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

Overview.propTypes = {
  className: PropTypes.string,
};

export default Overview;
