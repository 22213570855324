/** @format */

import React, { Suspense, useState, useLayoutEffect, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import Cookies from "js-cookie";
import { toastr } from "react-redux-toastr";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// import api from "middlewares/axiosConfig";
import api from "../../middlewares/axiosConfig";

import { NavBar, TopBar, ChatBar } from "./components";
import * as actions from "../../redux/actions";
import jwt_decode from "jwt-decode";
import siteLogo from "../../assets/images/preloader.gif";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  topBar: {
    zIndex: 10,
    position: "relative",
  },
  container: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: "0 0 auto",
  },
  content: {
    overflowY: "auto",
    flex: "1 1 auto",
    height: "calc(100vh - 64px)",
  },
}));

const Dashboard = (props) => {
  const { route } = props;

  const history = useHistory();
  const [paintBrowser, setPaintBrowser] = useState(false);
  const [cooperativeSocietyName, setCooperativeSocietyName] = useState([]);
  const [cooperativeSocietyId, setCooperativeSocietyId] = useState(1);
  const partnerState = useSelector((state) => state.loginReducer.farmerData);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  useEffect(() => {
    document.body.style.overflowY = "hidden";
    const partnerData = Cookies.getJSON("partnerData");
    dispatch(actions.loginSuccess(partnerData));
    return () => {
      document.body.style.overflowY = "null";
    };
  }, []);

  useEffect(() => {
    let partnerData = Cookies.getJSON("partnerData");
    // console.log("[PartnerData]",partnerData)
    document.body.style.overflowY = "hidden";

    function checkUser() {
      Cookies.remove("adminData");
      Cookies.remove("farmerData");
      Cookies.remove("serviceProviderData");

      if (!partnerState.token) {
        let partnerData = Cookies.getJSON("partnerData");

        if (partnerData) {
          setPaintBrowser(true);
          dispatch(actions.loginSuccess(partnerData));
          return;
        }

        window.location.href = "/";
        toastr.error("UNAUTHORIZED", "Please login to continue", {
          closeButton: true,
        });
      }
    }

    checkUser();

    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  useEffect(async () => {
    if (
      partnerState.partnerDetailId != null &&
      partnerState.partnerId == null
    ) {
      let partner = await api.get(
        `Partners/GetAllPartnerCooperativebyPartnerId/${partnerState.partnerDetailId}`
      );
      let names = partner.data;
      let options = names.map((name) => {
        return {
          value: name.cooperativeSocietyId,
          label: name.cooperativeSocietyName,
        };
      });
      // setCooperativeSocietyName(options);
      dispatch(
        actions.loginSuccess({
          ...partnerState,
          partnerCooperatives: names,
          currentlySelectedCooperative: names[0].cooperativeSocietyId,
          partnerId: names[0].partnerId,
          corporateSocietyName: options,
        })
      );
    }
  }, [partnerState.partnerDetailId, partnerState.partnerId]);

  useEffect(() => {
    const token = Cookies.getJSON("partnerData");
    if (token) {
      let decodedToken = jwt_decode(token.token);
      // console.log(decodedToken);
      if (Date.now() >= decodedToken.exp * 1000) {
        // let expirationTime = new Date(decodedToken.exp * 1000);
        // let expirationDate = expirationTime.toString();

        // console.log(expirationDate);
        // console.log(expirationTime);

        Cookies.remove("partnerData");
        Cookies.remove("farmerData");
        Cookies.remove("adminData");
        Cookies.remove("serviceProviderData");
        // Cookies.remove('adminData');
        // dispatch(setAdminLogout());
        dispatch(actions.logout());
        window.location.href = "/";
      }
    }
  }, []);

  const loader = (
    <div className="preloader_wrapper">
      <div className="preloader_inner">
        <img src={siteLogo} alt="image" />
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {paintBrowser
              ? renderRoutes(route.routes, { isLocation: "partner" })
              : loader}
            {/* {renderRoutes(route.routes, { isLocation: "partner"}) } */}
          </Suspense>
        </main>
      </div>
      <ChatBar />
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
