/** @format */

export const LOGIN_CONSTANTS = {
  LOGGEDIN_CHECK_STATE: "LOGGEDIN_CHECK_STATE",
  LOGIN_ADMIN: "LOGIN_ADMIN",
  LOGIN_FARMER: "LOGIN_FARMER",
  LOGIN_PARTNER: "LOGIN_PARTNER",
  LOGIN_SERVICE_PROVIDER: "LOGIN_SERVICE_PROVIDER",
  SUPER_ADMIN_LOGIN: "SUPER_ADMIN_LOGIN",
  LOGIN_START: "LOGIN_START",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  TOKEN_CHECK_TIMEOUT: "TOKEN_CHECK_TIMEOUT",
  INITIATE_LOGOUT: "INITIATE_LOGOUT",
  LOGOUT: "LOGOUT",
  SET_LOGIN_REDIRECT_PATH: "SET_LOGIN_REDIRECT_PATH",
};

export const CREATE_ACCOUNT_CONSTANTS = {
  CREATE_ACCOUNT: "CREATE_ACCOUNT",
  CREATE_ACCOUNT_STARTS: "CREATE_ACCOUNT_STARTS",
  CREATE_ACCOUNT_SUCCESS: "CREATE_ACCOUNT_SUCCESS",
  CREATE_ACCOUNT_FAILS: "CREATE_ACCOUNT_FAILS",
  CREATE_ACCOUNT_FAILS2: "CREATE_ACCOUNT_FAILS2",
};

export const FARMS_CONSTANTS = {
  GET_FARMS: "GET_FARMS",
  GET_FARMS_START: "GET_FARMS_START",
  GET_FARMS_SUCCESS: "GET_FARMS_SUCCESS",
  GET_FARMS_FAIL: "GET_FARMS_FAIL",
  DELETE_FARM: "DELETE_FARM",
};

export const ROLES_CONSTANTS = {
  GET_ROLES: "GET_ROLES",
  GET_ROLES_STARTS: "GET_ROLES_STARTS",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_ROLES_FAILS: "GET_ROLES_FAILS",
  CREATE_ROLE: "CREATE_ROLE",
  CREATE_ROLE_STARTS: "CREATE_ROLE_STARTS",
  CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
  CREATE_ROLE_FAILS: "CREATE_ROLE_FAILS",
  EDIT_ROLE: "EDIT_ROLE",
  EDIT_ROLE_STARTS: "EDIT_ROLE_STARTS",
  EDIT_ROLE_SUCCESS: "EDIT_ROLE_SUCCESS",
  EDIT_ROLE_FAILS: "EDIT_ROLE_FAILS",
  EDIT_ROLE_SAVED: "EDIT_ROLE_SAVED",
  EDIT_ROLE_SAVED_SUCCESS: "EDIT_ROLE_SAVED_SUCCESS",
  DELETE_ROLE: "DELETE_ROLE",

  SET_ROLES: "SET_ROLES",
};

export const FARMER_CONSTANTS = {
  SET_FARMERS: "SET_FARMERS",
  SET_UNFILTERED_FARMERS: "SET_UNFILTERED_FARMERS",
};

export const PRODUCE_CONSTANTS = {
  SET_PRODUCE: "SET_PRODUCE",
  SET_UNFILTERED_PRODUCE: "SET_UNFILTERED_PRODUCE",
};

export const ADMIN_CONSTANTS = {
  SET_AUTH: "SET_AUTH",
  SET_DETAILED_USERS: "SET_DETAILED_USERS",
  SET_DETAILED_ROLES: "SET_DETAILED_ROLES",
  DELETE_AUTH: "DELETE_AUTH",
};

export const SUPER_ADMIN = {
  SET_AUTH: "SET_AUTH",
  SET_DETAILED_USERS: "SET_DETAILED_USERS",
  SET_DETAILED_ROLES: "SET_DETAILED_ROLES",
  DELETE_AUTH: "DELETE_AUTH",
};

export const SETUP_CONSTANTS = {
  GET_TOWNS: "GET_TOWNS",
  GET_TOWNS_START: "GET_TOWNS_START",
  GET_TOWNS_SUCCESS: "GET_TOWNS_SUCCESS",
  GET_TOWNS_FAIL: "GET_TOWNS_FAIL",
  CREATE_TOWN: "CREATE_TOWN",
  CREATE_TOWN_STARTS: "CREATE_TOWN_STARTS",
  CREATE_TOWN_SUCCESS: "CREATE_TOWN_SUCCESS",
  CREATE_TOWN_FAILS: "CREATE_TOWN_FAILS",
  EDIT_TOWN: "EDIT_TOWN",
  GET_TOWN_STARTS: "GET_TOWN_STARTS",
  GET_TOWN_SUCCESS: "GET_TOWN_SUCCESS",
  GET_TOWN_FAILS: "GET_TOWN_FAILS",
  EDIT_TOWN_STARTS: "EDIT_TOWN_STARTS",
  EDIT_TOWN_SUCCESS: "EDIT_TOWN_SUCCESS",
  EDIT_TOWN_FAILS: "EDIT_TOWN_FAILS",
  DELETE_TOWN: "DELETE_TOWN",
  SET_DETAILED_TOWNS: "SET_DETAILED_TOWNS",
  SET_DETAILED_CROPTYPES: "SET_DETAILED_CROPTYPES",
  SET_DETAILED_PRODUCES: "SET_DETAILED_PRODUCES",
};

export const GENERIC_CONSTANTS = {
  GET_STATES: "GET_STATES",
  GET_STATES_STARTS: "GET_STATES_STARTS",
  GET_STATES_SUCCESS: "GET_STATES_SUCCESS",
  GET_STATES_FAILS: "GET_STATES_FAILS",

  SET_ROLES: "SET_ROLES",
};

export const MANAGEFARMLAND_CONSTANTS = {
  GET_PARTITIONED_FARMS: "GET_PARTITIONED_FARMS",
  GET_PARTITIONED_FARMS_START: "GET_PARTITIONED_FARMS_START",
  GET_PARTITIONED_FARMS_SUCCESS: "GET_PARTITIONED_FARMS_SUCCESS",
  GET_PARTITIONED_FARMS_FAIL: "GET_PARTITIONED_FARMS_FAIL",

  GET_UNPARTITIONED_FARMS: "GET_UNPARTITIONED_FARMS",
  GET_UNPARTITIONED_FARMS_START: "GET_UNPARTITIONED_FARMS_START",
  GET_UNPARTITIONED_FARMS_SUCCESS: "GET_UNPARTITIONED_FARMS_SUCCESS",
  GET_UNPARTITIONED_FARMS_FAIL: "GET_UNPARTITIONED_FARMS_FAIL",

  CREATE_FARM_PARTITION: "CREATE_FARM_PARTITION",
  CREATE_FARM_PARTITION_STARTS: "CREATE_FARM_PARTITION_STARTS",
  CREATE_FARM_PARTITION_SUCCESS: "CREATE_FARM_PARTITION_SUCCESS",
  CREATE_FARM_PARTITION_FAILS: "CREATE_FARM_PARTITION_FAILS",
  EDIT_PARTITIONED_FARM: "EDIT_PARTITIONED_FARM",
  GET_PARTITIONED_FARM_STARTS: "GET_PARTITIONED_FARM_STARTS",
  GET_PARTITIONED_FARM_SUCCESS: "GET_PARTITIONED_FARM_SUCCESS",
  GET_PARTITIONED_FARM_FAILS: "GET_PARTITIONED_FARM_FAILS",
  EDIT_PARTITIONED_FARM_STARTS: "EDIT_PARTITIONED_FARM_STARTS",
  EDIT_PARTITIONED_FARM_SUCCESS: "EDIT_PARTITIONED_FARM_SUCCESS",
  EDIT_PARTITIONED_FARM_FAILS: "EDIT_PARTITIONED_FARM_FAILS",
  DELETE_PARTITIONED_FARM: "DELETE_PARTITIONED_FARM",
};

export const FARMERS_CONSTANTS = {
  GET_FARMERS: "GET_FARMERS",
  GET_FARMERS_START: "GET_FARMERS_START",
  GET_FARMERS_SUCCESS: "GET_FARMERS_SUCCESS",
  GET_FARMERS_FAIL: "GET_FARMERS_FAIL",
  CREATE_FARMER: "CREATE_FARMER",
  CREATE_FARMER_STARTS: "CREATE_FARMER_STARTS",
  CREATE_FARMER_SUCCESS: "CREATE_FARMER_SUCCESS",
  CREATE_FARMER_FAILS: "CREATE_FARMER_FAILS",
  EDIT_FARMER: "EDIT_FARMER",
  GET_FARMER_STARTS: "GET_FARMER_STARTS",
  GET_FARMER_SUCCESS: "GET_FARMER_SUCCESS",
  GET_FARMER_FAILS: "GET_FARMER_FAILS",
  EDIT_FARMER_STARTS: "EDIT_FARMER_STARTS",
  EDIT_FARMER_SUCCESS: "EDIT_FARMER_SUCCESS",
  EDIT_FARMER_FAILS: "EDIT_FARMER_FAILS",
  DELETE_FARMER: "DELETE_FARMER",

  GET_FARMERS_PASSPORT_CAPTURED: "GET_FARMERS_PASSPORT_CAPTURED",
  GET_FARMERS_PASSPORT_CAPTURED_START: "GET_FARMERS_PASSPORT_CAPTURED_START",
  GET_FARMERS_PASSPORT_CAPTURED_SUCCESS:
    "GET_FARMERS_PASSPORT_CAPTURED_SUCCESS",
  GET_FARMERS_PASSPORT_CAPTURED_FAIL: "GET_FARMERS_PASSPORT_CAPTURED_FAIL",

  GET_FARMERS_BIOMETRIC_CAPTURED: "GET_FARMERS_BIOMETRIC_CAPTURED",
  GET_FARMERS_BIOMETRIC_CAPTURED_STARTS:
    "GET_FARMERS_BIOMETRIC_CAPTURED_STARTS",
  GET_FARMERS_BIOMETRIC_CAPTURED_SUCCESS:
    "GET_FARMERS_BIOMETRIC_CAPTURED_SUCCESS",
  GET_FARMERS_BIOMETRIC_CAPTURED_FAILS: "GET_FARMERS_BIOMETRIC_CAPTURED_FAILS",
};

export const PARTNERS_CONSTANTS = {
  GET_ALL_COOPERATIVE_SOCIETY: "GET_ALL_COOPERATIVE_SOCIETY",
  GET_ALL_COOPERATIVE_SOCIETY_START: "GET_ALL_COOPERATIVE_SOCIETY_START",
  GET_ALL_COOPERATIVE_SOCIETY_SUCCESS: "GET_ALL_COOPERATIVE_SOCIETY_SUCCESS",
  GET_ALL_COOPERATIVE_SOCIETY_FAILS: "GET_ALL_COOPERATIVE_SOCIETY_FAILS",
};

export const SUPER_ADMIN_COOPERATIVE_CONSTANTS = {
  GET_COOPERATIVES: "GET_COOPERATIVES",
  GET_COOPERATIVES_START: "GET_COOPERATIVES_START",
  GET_COOPERATIVES_SUCCESS: "GET_COOPERATIVES_SUCCESS",
  GET_COOPERATIVES_FAIL: "GET_TCOOPERATIVES_FAIL",

  ADD_COOPERATIVE: "ADD_COOPERATIVE",
  ADD_COOPERATIVE_START: "ADD_COOPERATIVE_START",
  ADD_COOPERATIVE_SUCCESS: "ADD_COOPERATIVE_SUCCESS",
  ADD_COOPERATIVE_FAIL: "ADD_COOPERATIVE_FAIL",

  EDIT_COOPERATIVE: "EDIT_COOPERATIVE",
  EDIT_COOPERATIVE_START: "EDIT_COOPERATIVE_START",
  EDIT_COOPERATIVE_SUCCESS: "EDIT_COOPERATIVE_SUCCESS",
  EDIT_COOPERATIVE_FAIL: "EDIT_COOPERATIVE_FAIL",
  EDIT_RESET: "EDIT_RESET",
};

export const PAYMENT_CONSTANTS = {
  GET_PAYMENTS: "GET_PAYMENTS",
  GET_PAYMENTS_START: "GET_PAYMENTS_START",
  GET_PAYMENTS_SUCCESS: "GET_PAYMENTS_SUCCESS",
  GET_PAYMENTS_FAIL: "GET_PAYMENTS_FAIL",

  GET_ALL_FARMERS: "GET_ALL_FARMERS",
  GET_ALL_FARMERS_SUCCESS: "GET_ALL_FARMERS_SUCCESS",
  GET_ALL_FARMERS_FAIL: "GET_ALL_FARMERS_FAIL",

  GET_ALL_PAYMENTCAT: "GET_ALL_PAYMENTCAT",
  GET_ALL_PAYMENTCAT_SUCCESS: "GET_ALL_PAYMENTCAT_SUCCESS",
  GET_ALL_PAYMENTCAT_FAIL: "GET_ALL_PAYMENTCAT_FAIL",

  GET_ALL_TRANSACTIONSTAT: "GET_ALL_FARMERS",
  GET_ALL_TRANSACTIONSTAT_SUCCESS: "GET_ALL_FARMERS_SUCCESS",
  GET_ALL_TRANSACTIONSTAT_FAIL: "GET_ALL_FARMERS_FAIL",
};
