import * as actionTypes from './actionTypes';

export const getPartitionedFarms = () => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.GET_PARTITIONED_FARMS
    };
}

export const getPartitionedFarmsStart = () => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.GET_PARTITIONED_FARMS_START
    };
}

export const getPartitionedFarmsSuccess = (farmsData) => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.GET_PARTITIONED_FARMS_SUCCESS,
        farmsData: farmsData,
    };
}

export const getPartitionedFarmsFail = (error) => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.GET_PARTITIONED_FARMS_FAIL,
        error: error
    };
}

export const getUnPartitionedFarms = () => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.GET_UNPARTITIONED_FARMS
    };
}

export const getUnPartitionedFarmsStart = () => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.GET_UNPARTITIONED_FARMS_START
    };
}

export const getUnPartitionedFarmsSuccess = (farmsData) => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.GET_UNPARTITIONED_FARMS_SUCCESS,
        farmsData: farmsData,
    };
}

export const getUnPartitionedFarmsFail = (error) => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.GET_UNPARTITIONED_FARMS_FAIL,
        error: error
    };
}

export const createFarmPartition = (data) => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.CREATE_FARM_PARTITION,
        farmData: data
    };
}

export const createFarmPartitionStarts = () => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.CREATE_FARM_PARTITION_STARTS
    };
}

export const createFarmPartitionSuccess = () => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.CREATE_FARM_PARTITION_SUCCESS
    };
}

export const createFarmPartitionFails = (error) => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.CREATE_FARM_PARTITION_FAILS,
        error: error
    };
}

export const editPartitionedFarm = (data) => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.EDIT_PARTITIONED_FARM,
        farmId: data
    };
}

export const getPartitionedFarmStarts = () => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.GET_PARTITIONED_FARM_STARTS
    };
}

export const getPartitionedFarmSuccess = (data) => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.GET_PARTITIONED_FARM_SUCCESS,
        farmData: data
    };
}

export const getPartitionedFarmFails = (error) => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.GET_PARTITIONED_FARM_FAILS,
        error: error
    };
}

export const editPartitionedFarmStarts = (data) => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.EDIT_PARTITIONED_FARM_STARTS,
        farmData: data
    };
  }

export const editPartitionedFarmSuccess = () => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.EDIT_PARTITIONED_FARM_SUCCESS,
    };
}

export const editPartitionedFarmFails = (error) => {
    return {
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.EDIT_PARTITIONED_FARM_FAILS,
        error: error
    };
}

export const deletePartitionedFarm = (data) => {
    return{
        type: actionTypes.MANAGEFARMLAND_CONSTANTS.DELETE_PARTITIONED_FARM,
        farmId: data
    }
}