import * as actionTypes from './actionTypes';


export const getFarms = () => { 
    return {
        type: actionTypes.FARMS_CONSTANTS.GET_FARMS
    };
}

export const getFarmsStart = () => {
    return {
        type: actionTypes.FARMS_CONSTANTS.GET_FARMS_START
    };
}

export const getFarmsSuccess = (farmsData) => {
    return {
        type: actionTypes.FARMS_CONSTANTS.GET_FARMS_SUCCESS,
        farms: farmsData
    };
}

export const getFarmsFail = (error) => {
    return {
        type: actionTypes.FARMS_CONSTANTS.GET_FARMS_FAIL,
        error: error
    };
}

export const deleteFarm = (data) => {
    return{
        type: actionTypes.FARMS_CONSTANTS.DELETE_FARM,
        farmId: data
    }
}